import React, { useState, useEffect } from 'react'
import profileImagePlaceholder from '../../assets/img/profile.svg'
import NotificationsDropdown from './NotificationsDropdown';
import UserDropdown from './UserDropdown';
import { getBusinessMembers } from '../../api/business';
import { Bell, BellFill } from 'react-bootstrap-icons';

function Header({ pageTitle, userInfo, setUserInfo }) {

    const [isNotificationsDropdownOpen, setIsNotificationsDropdownOpen] = useState(false);
    const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
    const [businessMembers, setBusinessMembers] = useState([]);

    const toggleNotificationsDropdown = () => {
        setIsNotificationsDropdownOpen(!isNotificationsDropdownOpen);
    };

    const toggleUserDropdown = () => {
        setIsUserDropdownOpen(!isUserDropdownOpen);
    };

    useEffect(() => {
        const fetchBusinessMembers = async () => {

            try {
                const fetchedBusinessMembers = await getBusinessMembers();
                setBusinessMembers(fetchedBusinessMembers);
            } catch (error) {
                console.error('Error fetching :', error);
            }

        };

        fetchBusinessMembers();
    }, []);

    return (
        <nav className='header justify-content-between shadow-sm'>
            <div className='d-flex align-items-center'>
                <span className='medium fw-500'>{pageTitle}</span>
            </div>

            <div className='d-flex align-items-center'>
                <div className='px-5'>
                    {businessMembers?.map((member) => {
                        return (
                            <img
                                src={member?.user?.avatar ?? profileImagePlaceholder}
                                key={member?.id}
                                className="rounded-circle mx-1"
                                alt=""
                                style={{
                                    maxHeight: 26,
                                    aspectRatio: 1,
                                    objectFit: "cover",
                                    height: "100%",
                                }}
                            />
                        );
                    })}
                </div>

                <div className='px-2 mx-1 d-flex flex-column align-items-center justify-content-center'>
                    <div className="dropdown custom-dropdown">
                        <li onClick={toggleNotificationsDropdown} className="dropdown-link d-flex align-items-center" aria-haspopup="true" aria-expanded={isNotificationsDropdownOpen}>
                            <BellFill className="h5 color-text m-0 pointer" />
                            {userInfo?.notifications?.filter(notification => notification.is_read === 0).length > 0 && (
                                <span className='badge badge-circle badge-primary bg-danger badge-notification'>
                                    {userInfo?.notifications?.filter(notification => notification.is_read == 0).length}
                                </span>
                            )}

                        </li>

                        {isNotificationsDropdownOpen && <NotificationsDropdown userInfo={userInfo} setUserInfo={setUserInfo} />}
                    </div>
                </div>
                <div className='px-2 mx-1 d-flex flex-column align-items-center justify-content-center'>
                    <div className="dropdown custom-dropdown">
                        <a href="#" onClick={toggleUserDropdown} className="dropdown-link d-flex align-items-center" aria-haspopup="true" aria-expanded={isUserDropdownOpen}>
                            <img src={userInfo?.avatar ?? profileImagePlaceholder} className='rounded-circle pointer' alt="" style={{ height: 23, width: 23, objectFit: 'cover' }} />
                        </a>

                        {isUserDropdownOpen && <UserDropdown userInfo={userInfo} setUserInfo={setUserInfo} />}
                    </div>
                </div>
            </div>

        </nav>


    )
}

export default Header