import React, { useState, useRef } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { saveSubtask } from '../../api/tasks';
import { format } from 'date-fns';
import Button from '../../../common/components/Button';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { X } from 'react-bootstrap-icons';

function AddSubtaskModal({ tasks, setTasks, selectedTask, setSelectedTask }) {

    const [subject, setSubject] = useState('');
    const [description, setDescription] = useState('');
    const [waitingOn, setWaitingOn] = useState('');
    const [dueDateTime, setDueDateTime] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const closeDropdown = () => {
        setShowDropdown(false);
    };

    const handleDateChange = (date) => {
        setDueDateTime(date);
    };

    const handleSaveSubtask = async () => {

        if (!subject || !dueDateTime) {
            alert('Please fill in all required fields, including the due date.');
            return;
        }

        setError('');
        setLoading(true);

        try {
            let formattedDueDateTime;
            if (dueDateTime) {
                formattedDueDateTime = format(dueDateTime, 'yyyy-MM-dd HH:mm');
            } else {
                formattedDueDateTime = null;
            }

            const subtask = {
                subject: subject,
                description: description,
                due_date: formattedDueDateTime,
                waiting_on: waitingOn
            }

            const updatedTask = await saveSubtask(subtask, selectedTask.id);
            setSelectedTask(updatedTask);
            const updatedTasks = tasks.map(task => {
                if (task.id === updatedTask.id) {
                    return updatedTask;
                }
                return task;
            });
            setTasks(updatedTasks);
            setSubject('');
            setDescription('');
            setWaitingOn('');
            setDueDateTime(null);
            closeDropdown();
        } catch (error) {
            setError(error);
        }

        setLoading(false);

    };


    return (
        <Dropdown show={showDropdown} drop="end" className='w-100'>
            <Dropdown.Toggle variant="basic" className='bg-gray border shadow-sm medium hover mt-2 w-100' onClick={toggleDropdown}>
                Add subtask
            </Dropdown.Toggle>

            <Dropdown.Menu className="shadow-lg border-0 p-3" style={{width: 420}}>

                <div className='d-flex align-items-center justify-content-between pb-4'>
                    <b>Create Subtasks</b>
                    <span className='text-muted pointer' onClick={closeDropdown}><X /> </span>
                </div>


                {error && <span className='text-danger small'>{error}</span>} { }

                <label className='pb-2'>Subject</label>
                <input type="text" className='form-control medium bg-gray-light py-2' placeholder='Task subject' value={subject} autoComplete="new-password" onChange={(e) => setSubject(e.target.value)} />

                <label className='mt-4 mb-2'>Description</label>
                <textarea type="text" className='form-control bg-gray-light py-2 medium' placeholder='Task description' value={description} autoComplete="new-password" onChange={(e) => setDescription(e.target.value)} style={{ height: 130 }} />

                <label className='mt-4 mb-2'>Waiting on</label>
                <input type="text" className='form-control medium bg-gray-light py-2' placeholder='Waiting on' value={waitingOn} autoComplete="new-password" onChange={(e) => setWaitingOn(e.target.value)} />

                <div className='d-flex mt-4 mb-2 flex-row align-items-center'>
                    <label className='pe-2'>Due date</label>
                    <DatePicker
                        selected={dueDateTime}
                        onChange={handleDateChange}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat="yyyy-MM-dd HH:mm"
                        placeholderText='MM-dd-yyyy HH:mm'
                        className='form-control bg-gray-light py-2 medium'
                        onKeyDown={(e) => {
                            e.preventDefault();
                        }}
                    />
                </div>

                <div className='pt-4 d-flex justify-content-end'>
                    <Button variant="primary" onClick={handleSaveSubtask} loading={loading} size="sm" >
                        Save
                    </Button>
                </div>

            </Dropdown.Menu>
        </Dropdown>
    );
}

export default AddSubtaskModal;
