import React from 'react';
import { Modal } from 'react-bootstrap';
import { deleteProductModule } from '../api/products';
import { toast } from 'react-toastify';

function DeleteProductModuleModal({ productModules, setProductModules, showDeleteProductModuleModal, setShowDeleteProductModuleModal, selectedProductModule }) {

    const handleCloseModal = () => {
        setShowDeleteProductModuleModal(false);
    }

    const handleDeleteProductModule = async (event) => {
        event.preventDefault();
        try {
            await deleteProductModule(selectedProductModule.id);
            const updatedProductModules = productModules.filter(productModule => productModule.id !== selectedProductModule.id);
            setProductModules(updatedProductModules);
            toast.success("Successfully removed a product module!")
        } catch (error) {
            toast.error(error.message);
        }
        handleCloseModal()
    };

    return (
        <Modal show={showDeleteProductModuleModal}
            onHide={handleCloseModal}
            backdrop="static"
            keyboard={true}
            size='md'
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='bold h5'>Delete product module</Modal.Title>
            </Modal.Header>

            <Modal.Body className='pt-4'>


                <p className='color-text opacity-80 medium'>
                    Are you sure you want to delete this product module? After you delete this product module, this action cannot be undone.
                </p>
                <div className='modal-footer border-0 pb-2'>
                    <button className='btn btn-danger rounded' onClick={(event) => { handleDeleteProductModule(event) }}>Delete</button>
                </div>
            </Modal.Body>

        </Modal>

    );
}

export default DeleteProductModuleModal;
