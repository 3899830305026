import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { saveExpense } from '../api/expenses';
import { toast } from 'react-toastify';
import Button from '../../common/components/Button';

function AddExpenseModal({ group_id, showAddExpenseModal, setShowAddExpenseModal, expenses, setExpenses }) {

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async () => {

        setLoading(true);
        setError('');

        const expense = {
            "name": name,
            "description": description,
            "value": value,
            "expense_group_id": group_id
        };

        try {
            const newExpense = await saveExpense(expense);
            setExpenses([newExpense, ...expenses]);
            setShowAddExpenseModal(false);

            setName('');
            setDescription('');
            setValue(null);
            setLoading(false);

            toast.success("Successfully added a new expense!");

        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleCloseModal = () => {
        setShowAddExpenseModal(false);
    };

    return (
        <Modal
            show={showAddExpenseModal} onHide={handleCloseModal}
            backdrop="static" keyboard={false} size='lg'
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='bold h5'>Add expense</Modal.Title>
            </Modal.Header>
            <Modal.Body className='py-4'>

                {error && <span className='text-danger small'>{error}</span>} { }

                <label className='pb-2'>Name</label>
                <input type="text" className='form-control medium bg-gray-light py-2' placeholder='Name' value={name} onChange={(e) => setName(e.target.value)} />

                <label className='mt-4 mb-2'>Value</label>
                <input type="number" className='form-control medium bg-gray-light py-2' placeholder='Value ($)' value={value} onChange={(e) => setValue(e.target.value)} />

                <label className='mt-4 mb-2'>Description</label>
                <textarea type="text" className='form-control bg-gray-light py-2 medium' rows={5} placeholder='Description' value={description} autoComplete="new-password" onChange={(e) => setDescription(e.target.value)} style={{ height: 130 }} />

                <div className='pt-4 d-flex justify-content-end'>
                    <Button variant="primary" size="sm" onClick={handleSubmit} loading={loading} >
                        Save
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AddExpenseModal