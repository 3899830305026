import WorkerGroups from './pages/WorkerGroups';
import WorkerGroup from './pages/WorkerGroup';

const workerRoutes = [
  {
    path: 'workers/groups',
    element: <WorkerGroups />,
  },
  {
    path: 'workers/groups/:group_id',
    element: <WorkerGroup />,
  },
];

export default workerRoutes;