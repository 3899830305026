import EventTypes from './pages/EventTypes';
import NewEventType from './pages/NewEventType';
const eventTypeRoutes = [
  {
    path: 'event_types',
    element: <EventTypes />,
  },
  {
    path: 'event_types/new',
    element: <NewEventType />,
  },
];

export default eventTypeRoutes;