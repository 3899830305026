import React, { useState, useEffect } from 'react';
import { getExpenseGroups } from '../api/expenses';
import AddExpenseGroupModal from '../components/AddExpenseGroupModal';
import ExpenseGroupCard from '../components/expenseGroupCard/ExpenseGroupCard';
import EditExpenseGroupModal from '../components/EditExpenseGroupModal';
import DeleteExpenseGroupModal from '../components/DeleteExpenseGroupModal';
import { Plus } from 'react-bootstrap-icons';
import { ReactComponent as MoneyImg } from '../../common/assets/img/vectors/money.svg'


function ExpenseGroups() {

    const [expenseGroups, setExpenseGroups] = useState([]);
    const [showAddExpenseGroupModal, setShowAddExpenseGroupModal] = useState(false);
    const [selectedExpenseGroup, setSelectedExpenseGroup] = useState([]);
    const [showEditExpenseGroupModal, setShowEditExpenseGroupModal] = useState(false);
    const [showDeleteExpenseGroupModal, setShowDeleteExpenseGroupModal] = useState(false);

    useEffect(() => {
        const fetchExpenseGroups = async () => {
            try {
                const fetchedExpenseGroups = await getExpenseGroups();
                setExpenseGroups(fetchedExpenseGroups);
            } catch (error) {
                console.error('Error fetching :', error);
            }
        };

        fetchExpenseGroups();
    }, []);

    const openAddExpenseGroupModal = () => {
        setShowAddExpenseGroupModal(true);
    };

    const openEditExpenseGroupModal = (expenseGroup) => {
        setShowEditExpenseGroupModal(true);
        setSelectedExpenseGroup(expenseGroup);
    }

    const openDeleteExpenseGroupModal = (expenseGroup) => {
        setShowDeleteExpenseGroupModal(true);
        setSelectedExpenseGroup(expenseGroup);
    }

    return (

        <div>

            <button onClick={() => { openAddExpenseGroupModal() }} className='btn btn-dark right-bottom-button rounded px-3 py-2 shadow-lg'>
                <Plus className='h4 m-0' />
            </button>

            {expenseGroups.length === 0 ? (
                <div className='d-flex flex-column align-items-center justify-content-center' style={{ height: '100vh' }}>
                    <MoneyImg />
                    <p className='text-center text-muted mt-3'>You didn't create any expense groups yet!</p>
                </div>
            ) : (
                <div className='container-fluid' style={{ paddingTop: 77 }}>
                    <div className='row px-3'>

                        {expenseGroups.map(expenseGroup => (
                            <div key={expenseGroup.id} className='col-md-4 mb-3'>
                                <ExpenseGroupCard expenseGroup={expenseGroup} openEditExpenseGroupModal={openEditExpenseGroupModal} openDeleteExpenseGroupModal={openDeleteExpenseGroupModal} />
                            </div>
                        ))}

                    </div>
                </div>
            )}

            <AddExpenseGroupModal
                expenseGroups={expenseGroups}
                setExpenseGroups={setExpenseGroups}
                showAddExpenseGroupModal={showAddExpenseGroupModal}
                setShowAddExpenseGroupModal={setShowAddExpenseGroupModal}
            />

            <EditExpenseGroupModal
                expenseGroups={expenseGroups}
                setExpenseGroups={setExpenseGroups}
                selectedExpenseGroup={selectedExpenseGroup}
                setSelectedExpenseGroup={setSelectedExpenseGroup}
                showEditExpenseGroupModal={showEditExpenseGroupModal}
                setShowEditExpenseGroupModal={setShowEditExpenseGroupModal}
            />

            <DeleteExpenseGroupModal
                expenseGroups={expenseGroups}
                setExpenseGroups={setExpenseGroups}
                showDeleteExpenseGroupModal={showDeleteExpenseGroupModal}
                setShowDeleteExpenseGroupModal={setShowDeleteExpenseGroupModal}
                selectedExpenseGroup={selectedExpenseGroup}
            />

        </div >
    )
}

export default ExpenseGroups;
