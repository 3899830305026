import React from 'react';
import styles from './Attachments.module.css';
import { format } from 'date-fns';
import { deleteAttachment } from '../../../api/attachments';
import { ReactComponent as FolderSVG } from '../../../../common/assets/img/vectors/folder-lg.svg'

function Attachments({ selectedTask, setSelectedTask, tasks, setTasks }) {

    const handleDeleteAttachment = async (attachment_id) => {

        const updatedSelectedTask = {
            ...selectedTask,
            attachments: selectedTask.attachments.filter(attachment => attachment.id !== attachment_id),
        };

        setSelectedTask(updatedSelectedTask);

        setTasks(prevTasks =>
            prevTasks.map(task =>
                task.id === selectedTask.id
                    ? { ...task, attachments: task.attachments.filter(attachment => attachment.id !== attachment_id) }
                    : task
            )
        );

        await deleteAttachment(attachment_id);
    };

    return (
        <div>
            {selectedTask?.attachments?.map(attachment => (
                <div
                    key={attachment.id}
                    className={styles.attachmentItem}
                    onClick={() => window.open(attachment.url, '_blank')}
                >
                    <div className='row'>
                        <div className='col-md-7'>
                            <div className='h-100'>
                                {attachment.file_type === 'image' ? (
                                    <img src={attachment.url} className={styles.image} alt={attachment.name} loading='lazy' />
                                ) : attachment.file_type === 'video' ? (
                                    <video
                                        controls
                                        className={styles.video}
                                        src={attachment.url}
                                        alt={attachment.name}
                                    />
                                ) : attachment.file_type === 'other' ? (
                                    <div style={{ height: 140 }} className='w-100 bg-gray flex-column d-flex justify-content-center align-items-center'>
                                        <FolderSVG />
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="col-md-5">
                            <p className="fw-500 mb-1">{attachment.name}</p>
                            <span>Added {format(new Date(attachment.created_at), 'MMMM dd yyyy')}</span>
                            <p
                                className='pointer'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteAttachment(attachment.id);
                                }}
                            >
                                <u>Delete</u>
                            </p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Attachments;
