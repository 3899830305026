import { voiceApiUrl } from './config';

const convertSpeechToText = async (audioBlob) => {

    const formData = new FormData();
    formData.append('file', audioBlob, 'recording.webm');

    try {
        const response = await fetch(`${voiceApiUrl}/transcribe`, {
            method: 'POST',
            body: formData,
        });
        
        const result = await response.json();

        return result;
    } catch (error) {
        console.error('Error uploading file:', error);
    }

}

export { convertSpeechToText }