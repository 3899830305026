import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import AppLayout from './modules/common/layouts/AppLayout';
import AdminLayout from './modules/common/layouts/AdminLayout';
import LandingLayout from './modules/common/layouts/LandingLayout';
import { AuthProvider, useAuth } from './modules/common/contexts/AuthContext';
import { Outlet } from 'react-router-dom';
import authRoutes from './modules/authentication';
import adminPanelRoutes from './modules/admin_panel';
import taskRoutes from './modules/tasks';
import homeRoutes from './modules/home';
import accountRoutes from './modules/account';
import expenseRoutes from './modules/expenses';
import teamRoutes from './modules/team';
import peopleRoutes from './modules/people';
import focusRoutes from './modules/focus';
import itemRoutes from './modules/items';
import businessRoutes from './modules/business';
import clientRoutes from './modules/clients';
import workerRoutes from './modules/workers';
import notificationRoutes from './modules/notifications';
import productRoutes from './modules/products';
import revenueRoutes from './modules/revenues';
import calendarRoutes from './modules/calendar';
import availabilityRoutes from './modules/availability';
import eventTypeRoutes from './modules/event_types';
import scheduleEventRoutes from './modules/schedule_event';

const PrivateRoutes = () => {
  const { authenticated, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  return authenticated ? <Outlet /> : <Navigate to="/login" />;
};

const routes = [
  {
    path: '/',
    element: <Navigate to="/home" />,
  },
  {
    element: <PrivateRoutes />,
    children: [
      {
        path: '/',
        element: <AppLayout />,
        children: [
          ...homeRoutes,
          ...taskRoutes,
          ...accountRoutes,
          ...expenseRoutes,
          ...revenueRoutes,
          ...teamRoutes,
          ...peopleRoutes,
          ...itemRoutes,
          ...clientRoutes,
          ...workerRoutes,
          ...productRoutes,
          ...notificationRoutes,
          ...calendarRoutes,
          ...availabilityRoutes,
          ...eventTypeRoutes,
        ],
      },
    ],
  },
  ...focusRoutes,
  {
    path: '/',
    element: <LandingLayout />,
    children: [
      ...businessRoutes
    ],
  },
  {
    path: '/admin',
    element: <AdminLayout />,
    children: [
      ...adminPanelRoutes,
    ],
  },
  ...authRoutes,
  ...scheduleEventRoutes,
  {
    path: '*',
    element: <Navigate to="/" />,
  },
];

const renderRoutes = (routes) => {
  return routes.map((route, index) => {
    if (route.children) {
      return (
        <Route key={index} path={route.path} element={route.element}>
          {renderRoutes(route.children)}
        </Route>
      );
    } else {
      return <Route key={index} path={route.path} element={route.element} index={route.index} />;
    }
  });
};


function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>{renderRoutes(routes)}</Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
