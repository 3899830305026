import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { getMyBusinesses, switchBusiness } from '../../api/business';
import { getUserInfo } from '../../api/user';
import icon from '../../assets/img/logo.png'
import { truncateString } from '../../utils/format_utils';
import { Activity, Bank2, Bell, Box2, Boxes, Calendar, CalendarEvent, CameraVideo, ChevronBarDown, ChevronDown, Clock, ClockHistory, Gear, House, List, ListCheck, People, PersonCheck, PersonDown, PersonFillCheck, PersonHeart, PersonHearts, PersonSquare, PersonWorkspace, Wallet, Wallet2 } from 'react-bootstrap-icons';

function Sidebar() {

    const [sidebarActive, setSidebarActive] = useState(true);
    const [currentPage, setCurrentPage] = useState(window.location.pathname);
    const [myBusinesses, setMyBusinesses] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const fetchedUserInfo = await getUserInfo();
                setUserInfo(fetchedUserInfo);
            } catch (error) {
                console.error('Error fetching :', error);
            }

            try {
                const fetchedMyBusinesses = await getMyBusinesses();
                setMyBusinesses(fetchedMyBusinesses);
            } catch (error) {
                console.error('Error fetching :', error);
            }
        };

        fetchUserInfo();

    }, []);

    useEffect(() => {

        function handleResize() {
            if (window.innerWidth < 855) {
                const sidebar = document.getElementById('sidebar');
                if (!sidebar.classList.contains('active')) {
                    sidebar.classList.toggle('active');
                }
                document.documentElement.style.setProperty('--sidebar-width', '0');
            }
        }


        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);


    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const toggleModal = () => {
        const sidebar = document.getElementById('sidebar');
        sidebar.classList.toggle('active');
        if (sidebar.classList.contains('active')) {
            document.documentElement.style.setProperty('--sidebar-width', '0');
        } else {
            document.documentElement.style.setProperty('--sidebar-width', '236px');
        }
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleSwitchBusiness = async (business_id) => {
        try {
            await switchBusiness(business_id);
            window.location.reload();

        } catch (error) {
            console.error('Error fetching :', error);
        }
    }



    return (

        <nav id="sidebar" className={!sidebarActive ? 'active' : ''}>

            <ul className="list-unstyled">

                <div className='pb-3'>
                    <div className="dropdown show">
                        <div className="dropdown-toggle medium p-1 hover-lg d-flex align-items-center" type="button" id="businessesDropdown" onClick={toggleDropdown}
                            aria-haspopup="true" aria-expanded={isDropdownOpen ? "true" : "false"}>
                            <img src={icon} style={{ height: 20 }} className='rounded px-1 bg-white' alt="" />
                            <div className='d-flex flex-column justify-content-center ps-2'>
                                <span className='fw-500'>{userInfo?.business?.name}</span>
                                <p className='small text-muted m-0'>Business</p>
                            </div>
                            <ChevronDown className='ms-auto text-muted' />
                        </div>
                        <div className={"dropdown-menu border-0 shadow pt-2 pb-3" + (isDropdownOpen ? " show" : "")}
                            aria-labelledby="businessesDropdown"
                            style={{ width: 230 }}
                        >
                            {myBusinesses.map(myBusiness => (
                                <span className="dropdown-item medium py-2 pointer" key={myBusiness.id} onClick={() => { handleSwitchBusiness(myBusiness.id) }}>
                                    {truncateString(myBusiness?.name, 25)}
                                </span>
                            ))}
                            <hr />
                            <Link to="/my-businesses" className="dropdown-item medium txt-primary pointer">
                                View all
                            </Link>
                        </div>
                    </div>
                </div>

                <div className='sidebar-link-group'>
                    <div className='pb-2 pt-3'>
                        <span className='small bold text-secondary ps-2'>BUSINESS</span>
                    </div>

                    <li className={`nav-item px-2 rounded ${currentPage === '/home' ? 'active' : ''}`}>
                        <Link to="/home" className='nav-link' onClick={() => handlePageChange('/home')}>
                            <House />
                            <span className='ps-2 medium'>Home</span>
                        </Link>
                    </li>

                    <li className={`nav-item px-2 rounded ${currentPage === '/tasks' ? 'active' : ''}`}>
                        <Link to="/tasks" className='nav-link' onClick={() => handlePageChange('/tasks')}>
                            <ListCheck />
                            <span className='ps-2 medium'>Tasks</span>
                        </Link>
                    </li>

                    <li className={`nav-item px-2 rounded ${currentPage === '/people' ? 'active' : ''}`}>
                        <Link to="/people" className='nav-link' onClick={() => handlePageChange('/people')}>
                            <People />
                            <span className='ps-2 medium'>People</span>
                        </Link>
                    </li>

                    <li className={`nav-item px-2 rounded ${currentPage === '/items/groups' ? 'active' : ''}`}>
                        <Link to="/items/groups" className='nav-link' onClick={() => handlePageChange('/items/groups')}>
                            <Boxes />
                            <span className='ps-2 medium'>Inventory</span>
                        </Link>
                    </li>

                    <li className={`nav-item px-2 rounded ${currentPage === '/expenses/groups' ? 'active' : ''}`}>
                        <Link to="/expenses/groups" className='nav-link' onClick={() => handlePageChange('/expenses/groups')}>
                            <Wallet2 />
                            <span className='ps-2 medium'>Expenses</span>
                        </Link>
                    </li>

                    <li className={`nav-item px-2 rounded ${currentPage === '/revenues/groups' ? 'active' : ''}`}>
                        <Link to="/revenues/groups" className='nav-link' onClick={() => handlePageChange('/revenues/groups')}>
                            <Bank2 />
                            <span className='ps-2 medium'>Revenues</span>
                        </Link>
                    </li>

                    <li className={`nav-item px-2 rounded ${currentPage === '/products' ? 'active' : ''}`}>
                        <Link to="/products" className='nav-link' onClick={() => handlePageChange('/products')}>
                            <Box2 />
                            <span className='ps-2 medium'>Products</span>
                        </Link>
                    </li>

                </div>

                <div className='sidebar-link-group'>
                    <div className='pb-2'>
                        <span className='small bold text-secondary ps-2'>TEAM</span>
                    </div>

                    <li className={`nav-item px-2 rounded ${currentPage === '/team' ? 'active' : ''}`}>
                        <Link to="/team" className='nav-link' onClick={() => handlePageChange('/team')}>
                            <People />
                            <span className='ps-2 medium'>Team</span>
                        </Link>
                    </li>

                    <li className={`nav-item px-2 rounded ${currentPage === '/clients/groups' ? 'active' : ''}`}>
                        <Link to="/clients/groups" className='nav-link' onClick={() => handlePageChange('/clients/groups')}>
                            <PersonDown />
                            <span className='ps-2 medium'>Clients</span>
                        </Link>
                    </li>

                    <li className={`nav-item px-2 rounded ${currentPage === '/workers/groups' ? 'active' : ''}`}>
                        <Link to="/workers/groups" className='nav-link' onClick={() => handlePageChange('/workers/groups')}>
                            <PersonWorkspace />
                            <span className='ps-2 medium'>Workers</span>
                        </Link>
                    </li>

                    <li className={`nav-item px-2 rounded ${currentPage === '/meetings' ? 'active' : ''}`}>
                        <a href="https://meet.bigbusinessengine.com/" className='nav-link' onClick={() => handlePageChange('/meetings')}>
                            <CameraVideo />
                            <span className='ps-2 medium'>Meetings</span>
                        </a>
                    </li>

                </div>

                <div className='sidebar-link-group'>
                    <div className='pb-2'>
                        <span className='small bold text-secondary ps-2'>PERSONAL</span>
                    </div>

                    <li className='nav-item px-2 rounded'>
                        <Link to="/calendar" className='nav-link'>
                            <Calendar />
                            <span className='ps-2 medium'>My calendar</span>
                        </Link>
                    </li>

                    <li className='nav-item px-2 rounded'>
                        <Link to="/availability" className='nav-link'>
                            <Clock />
                            <span className='ps-2 medium'>Availability</span>
                        </Link>
                    </li>

                    <li className='nav-item px-2 rounded'>
                        <Link to="/event_types" className='nav-link'>
                            <CalendarEvent />
                            <span className='ps-2 medium'>Event Types</span>
                        </Link>
                    </li>

                    <li className='nav-item px-2 rounded'>
                        <Link to="/notifications" className='nav-link'>
                            <Bell />
                            <span className='ps-2 medium'>Notifications</span>
                        </Link>
                    </li>

                    <li className='nav-item px-2 rounded'>
                        <Link to="/account" className='nav-link'>
                            <ClockHistory />
                            <span className='ps-2 medium'>History</span>
                        </Link>
                    </li>

                    <li className={`nav-item px-2 rounded ${currentPage === '/settings' ? 'active' : ''}`}>
                        <Link to="/settings" className='nav-link' onClick={() => handlePageChange('/settings')}>
                            <Gear />
                            <span className='ps-2 medium'>Settings</span>
                        </Link>
                    </li>
                </div>


            </ul>

            <div className='btn btn-basic bg-white w-fit position-fixed pointer' id='toggleModalButton' onClick={toggleModal}>
                <List />
            </div>

        </nav>

    )
}

export default Sidebar