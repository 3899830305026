import React, { useState } from 'react';
import { Dropdown, Button } from 'react-bootstrap';
import { Plus, X } from 'react-bootstrap-icons';
import { truncateString } from '../../common/utils/format_utils';
import profileImagePlaceholder from '../../common/assets/img/profile.svg';
import { addEventUser } from '../api/events';

function AddEventUserDropdown({ showAddEventUserDropdown, setShowAddEventUserDropdown, businessMembers, selectedEvent, setSelectedEvent, events, setEvents }) {

    const toggleDropdown = () => {
        setShowAddEventUserDropdown(!showAddEventUserDropdown);
    }

    const handleAddEventUser = async (user) => {

        try {
            const response = await addEventUser(selectedEvent.id, user.id);
            setSelectedEvent(response);
            setEvents(prevEvents =>
                prevEvents.map(event =>
                    event.id === selectedEvent.id ? { ...event, ...response } : event
                )
            );
            setShowAddEventUserDropdown(false);
        } catch (error) {
            console.error('Failed to add event user:', error);
        }
    }


    return (
        <Dropdown show={showAddEventUserDropdown} drop="end">
            <Dropdown.Toggle variant="basic" className='bg-gray border shadow-sm medium hover ms-2' onClick={toggleDropdown}>
                <Plus />
            </Dropdown.Toggle>
            <Dropdown.Menu className="shadow-lg border-0 py-3" style={{ width: 250 }}>
                <div className="position-relative">

                    <div>

                        <div className='d-flex justify-content-between align-items-center ps-3 pe-2 mb-3'>
                            <p className='medium fw-500 mb-0'>
                                Add member
                            </p>

                            <button
                                className='btn p-0'
                                variant="link"
                                onClick={() => setShowAddEventUserDropdown(false)}
                                style={{ zIndex: 1000 }}
                            >
                                <X className='text-muted h5 m-0' />
                            </button>

                        </div>

                        {businessMembers.filter(member =>
                            !selectedEvent?.users?.some(eventUser => eventUser?.user?.id === member?.user?.id)
                        ).map(member => (
                            <div
                                className='nav-item nav-link pointer d-flex align-items-center py-2 px-3 rounded'
                                key={member.id}
                                onClick={() => handleAddEventUser(member?.user)}
                            >
                                <img
                                    src={member?.user?.avatar ?? profileImagePlaceholder}
                                    className='rounded-circle'
                                    alt=""
                                    style={{ height: 30, width: 30, objectFit: 'cover' }}
                                />
                                <span className='px-2 small'>{truncateString(member?.user?.name, 30)}</span>
                            </div>
                        ))}


                    </div>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default AddEventUserDropdown;
