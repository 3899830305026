import React, { useState, useEffect } from 'react';
import { getClientGroups } from '../api/clients';
import AddClientGroupModal from '../components/AddClientGroupModal';
import ClientGroupCard from '../components/clientGroupCard/ClientGroupCard';
import EditClientGroupModal from '../components/EditClientGroupModal';
import DeleteClientGroupModal from '../components/DeleteClientGroupModal';
import { Plus } from 'react-bootstrap-icons';

function Clients() {

    const [clientGroups, setClientGroups] = useState([]);
    const [showAddClientGroupModal, setShowAddClientGroupModal] = useState(false);
    const [selectedClientGroup, setSelectedClientGroup] = useState([]);
    const [showEditClientGroupModal, setShowEditClientGroupModal] = useState(false);
    const [showDeleteClientGroupModal, setShowDeleteClientGroupModal] = useState(false);

    useEffect(() => {
        const fetchClientGroups = async () => {
            try {
                const fetchedClientGroups = await getClientGroups();
                setClientGroups(fetchedClientGroups);
            } catch (error) {
                console.error('Error fetching :', error);
            }
        };

        fetchClientGroups();
    }, []);

    const openAddClientGroupModal = () => {
        setShowAddClientGroupModal(true);
    };

    const openEditClientGroupModal = (clientGroup) => {
        setShowEditClientGroupModal(true);
        setSelectedClientGroup(clientGroup);
    }

    const openDeleteClientGroupModal = (clientGroup) => {
        setShowDeleteClientGroupModal(true);
        setSelectedClientGroup(clientGroup);
    }

    return (

        <div>

            <button onClick={() => { openAddClientGroupModal() }} className='btn btn-dark right-bottom-button rounded px-3 py-2 shadow-lg'>
                <Plus className='h4 m-0' />
            </button>

            <div className='container-fluid' style={{ paddingTop: 77 }}>
                <div className='row px-3'>

                    {clientGroups.map(clientGroup => (
                        <div key={clientGroup.id} className='col-md-4 mb-3'>
                            <ClientGroupCard clientGroup={clientGroup} openEditClientGroupModal={openEditClientGroupModal} openDeleteClientGroupModal={openDeleteClientGroupModal} />
                        </div>
                    ))}

                </div>
            </div>


            <AddClientGroupModal
                clientGroups={clientGroups}
                setClientGroups={setClientGroups}
                showAddClientGroupModal={showAddClientGroupModal}
                setShowAddClientGroupModal={setShowAddClientGroupModal}
            />

            <EditClientGroupModal
                clientGroups={clientGroups}
                setClientGroups={setClientGroups}
                selectedClientGroup={selectedClientGroup}
                setSelectedClientGroup={setSelectedClientGroup}
                showEditClientGroupModal={showEditClientGroupModal}
                setShowEditClientGroupModal={setShowEditClientGroupModal}
            />

            <DeleteClientGroupModal
                clientGroups={clientGroups}
                setClientGroups={setClientGroups}
                showDeleteClientGroupModal={showDeleteClientGroupModal}
                setShowDeleteClientGroupModal={setShowDeleteClientGroupModal}
                selectedClientGroup={selectedClientGroup}
            />

        </div >
    )
}

export default Clients;
