import React, { useState, useEffect } from 'react';
import { Modal, Form } from 'react-bootstrap';
import Button from '../../../common/components/Button';
import { Floppy } from 'react-bootstrap-icons';
import { removeEventUser, updateEvent } from '../../api/events';
import { toast } from 'react-toastify';
import { getBusinessMembers } from '../../../common/api/business';
import profileImagePlaceholder from '../../../common/assets/img/profile.svg';
import { truncateString } from '../../../common/utils/format_utils';
import AddEventUserDropdown from '../AddEventUserDropdown';

function EditEventModal({ showModal, setShowModal, events, setEvents, selectedEvent, setSelectedEvent }) {
    const [businessMembers, setBusinessMembers] = useState([]);
    const [eventData, setEventData] = useState({
        title: '',
        description: '',
        start_time: '',
        end_time: ''
    });

    const [showAddEventUserDropdown, setShowAddEventUserDropdown] = useState(false);

    useEffect(() => {
        const fetchBusinessMembers = async () => {
            try {
                const fetchedBusinessMembers = await getBusinessMembers();
                setBusinessMembers(fetchedBusinessMembers);
            } catch (error) {
                console.error('Error fetching business members:', error);
            }
        };

        fetchBusinessMembers();
    }, []);

    useEffect(() => {
        if (selectedEvent) {
            setEventData({
                title: selectedEvent.title || '',
                description: selectedEvent.description || '',
                start_time: selectedEvent.start_time || '',
                end_time: selectedEvent.end_time || ''
            });
        }
    }, [selectedEvent]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEventData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSave = async () => {

        const startTime = new Date(eventData.start_time);
        const endTime = new Date(eventData.end_time);

        if (endTime < startTime) {
            toast.error("End time cannot be before start time.");
            return;
        }

        try {
            await updateEvent(selectedEvent.id, eventData);

            setEvents(prevEvents =>
                prevEvents.map(event =>
                    event.id === selectedEvent.id ? { ...event, ...eventData } : event
                )
            );

            toast.success("Event updated successfully!");
            setShowModal(false);
        } catch (error) {
            console.error('Error updating event:', error);
            toast.error("Failed to update event.");
        }
    };

    const handleRemoveEventUser = async (user) => {
        try {

            const updatedSelectedEvent = {
                ...selectedEvent,
                users: selectedEvent.users.filter(u => u.user.id !== user.user.id)
            };
            setSelectedEvent(updatedSelectedEvent);

            const updatedEvents = events.map(event => {
                if (event.id === selectedEvent.id) {
                    return {
                        ...event,
                        users: updatedSelectedEvent.users
                    };
                }
                return event;
            });
            setEvents(updatedEvents);

            
            await removeEventUser(selectedEvent.id, user.user.id);

        } catch (error) {
            console.error('Error:', error);
        }
    }

    return (
        <>
            <Modal size='lg' alignment='center' show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header className='border-0' closeButton>
                    <input
                        type='text'
                        placeholder='Enter event title'
                        name='title'
                        className='py-2 h5 mb-0 border-0'
                        value={eventData.title}
                        onChange={handleInputChange}
                    />
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className='mb-3'>
                        <Form.Label className='medium'>Description</Form.Label>
                        <Form.Control
                            as='textarea'
                            rows={3}
                            placeholder='Enter event description'
                            name='description'
                            className='medium py-2'
                            value={eventData.description}
                            onChange={handleInputChange}
                        />
                    </Form.Group>

                    <Form.Group className='mb-3'>
                        <Form.Label className='medium'>Start Time</Form.Label>
                        <Form.Control
                            type='datetime-local'
                            name='start_time'
                            className='medium py-2'
                            value={eventData.start_time}
                            onChange={handleInputChange}
                        />
                    </Form.Group>

                    <Form.Group className='mb-3'>
                        <Form.Label className='medium'>End Time</Form.Label>
                        <Form.Control
                            type='datetime-local'
                            name='end_time'
                            className='medium py-2'
                            value={eventData.end_time}
                            onChange={handleInputChange}
                        />
                    </Form.Group>

                    <div className='mt-4'>
                        <p className='pe-2 mb-2 medium fw-500'>Members</p>
                    </div>

                    <div className='d-flex flex-wrap text-center mt-3'>
                        {selectedEvent?.users?.map(user => (
                            <div className='d-flex flex-column align-items-center pe-3' key={user.id}>
                                <img src={user?.user?.avatar ?? profileImagePlaceholder} className='rounded' alt="" style={{ height: 35, width: 35, objectFit: 'cover' }} />
                                <span className='pt-2 small'>{truncateString(user?.user?.name, 10)}</span>
                                <span className='small text-decoration-underline pointer' onClick={() => handleRemoveEventUser(user)}>remove</span>
                            </div>
                        ))}

                        <AddEventUserDropdown
                            showAddEventUserDropdown={showAddEventUserDropdown}
                            setShowAddEventUserDropdown={setShowAddEventUserDropdown}
                            businessMembers={businessMembers}
                            selectedEvent={selectedEvent}
                            setSelectedEvent={setSelectedEvent}
                            events={events}
                            setEvents={setEvents}
                        />

                    </div>
                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <Button variant='primary' onClick={handleSave}>
                        <Floppy className='me-2' /> Save
                    </Button>
                </Modal.Footer>
            </Modal>


        </>
    );
}

export default EditEventModal;
