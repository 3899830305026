import React, { useState, useEffect } from 'react'
import { getMyTasks, createDraftTask } from '../api/tasks'
import TaskModal from '../components/taskModal/TaskModal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Board from '../components/Board'
import TasksTimeline from '../components/Timeline'
import List from '../components/List'
import { getPeople } from '../../people/api/people';
import { CalendarRange, Kanban, ListCheck, Plus, Table } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';

function Tasks() {

    const [tasks, setTasks] = useState([]);
    const [selectedTask, setSelectedTask] = useState([]);
    const [showTasksModal, setShowTasksModal] = useState(false);
    const [showAddTaskCard, setShowAddTaskCard] = useState(false);
    const [people, setPeople] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        const fetchPeople = async () => {

            try {
                const fetchedPeople = await getPeople();
                setPeople(fetchedPeople);
            } catch (error) {
                console.error('Error fetching :', error);
            }

        };

        fetchPeople();

        const fetchTasks = async () => {
            try {
                setLoading(true);
                const fetchedTasks = await getMyTasks();
                setTasks(fetchedTasks);
            } catch (error) {
                console.error('Error fetching :', error);
            }
            finally {
                setLoading(false);
            }
        };

        fetchTasks();
    }, []);

    useEffect(() => {
        const hash = Number((window.location.hash).replace("#", ""));
        if (hash) {
            const task = tasks.find(task => task.id === hash);
            if (task) {
                setSelectedTask(task);
                setShowTasksModal(true);
            }
        }
    }, [tasks]);
    
    const handleCreateDraftTask = async () => {

        try {
            const newTask = await createDraftTask(null);
            setSelectedTask(newTask);
            setShowTasksModal(true);
            setTasks([...tasks, newTask]);
            toast.success('Successfully created a new task!');
        } catch (error) {
            console.log(error);
        }

    };

    return (

        <>
            <Tabs
                defaultActiveKey="list"
                id="uncontrolled-tab-example"
                className="mb-3"
            >
                <Tab eventKey="list" title={
                    <>
                        <ListCheck />
                        <span className='ps-2'>List</span>
                    </>
                }>
                    <List
                        tasks={tasks} setTasks={setTasks}
                        loading={loading}
                        selectedTask={selectedTask} setSelectedTask={setSelectedTask}
                        showTasksModal={showTasksModal} setShowTasksModal={setShowTasksModal}
                        showAddTaskCard={showAddTaskCard} setShowAddTaskCard={setShowAddTaskCard}
                    />
                </Tab>
                <Tab eventKey="board" title={
                    <>
                        <Kanban />
                        <span className='ps-2'>Board</span>
                    </>
                }>
                    <Board
                        tasks={tasks} setTasks={setTasks}
                        selectedTask={selectedTask} setSelectedTask={setSelectedTask}
                        showTasksModal={showTasksModal} setShowTasksModal={setShowTasksModal}
                        showAddTaskCard={showAddTaskCard} setShowAddTaskCard={setShowAddTaskCard}
                    />
                </Tab>
                <Tab eventKey="timeline" title={
                    <>
                        <CalendarRange />
                        <span className='ps-2'>Timeline</span>
                    </>
                }>
                    <TasksTimeline
                        tasks={tasks} setTasks={setTasks}
                        selectedTask={selectedTask} setSelectedTask={setSelectedTask}
                        showTasksModal={showTasksModal} setShowTasksModal={setShowTasksModal}
                        showAddTaskCard={showAddTaskCard} setShowAddTaskCard={setShowAddTaskCard}
                    />
                </Tab>

            </Tabs>


            <TaskModal
                showTasksModal={showTasksModal}
                setShowTasksModal={setShowTasksModal}
                selectedTask={selectedTask}
                setSelectedTask={setSelectedTask}
                tasks={tasks}
                setTasks={setTasks}
                people={people}
            />

            <button onClick={handleCreateDraftTask} className='btn btn-dark right-bottom-button rounded px-3 py-2 shadow-lg'>
                <Plus className='h4 m-0' />
            </button>


        </>
    )
}

export default Tasks