import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getProductModule, getProductModules, getProductModuleTasks } from '../api/products';
import ModuleHeader from '../components/moduleHeader/ModuleHeader';
import { getPeople } from '../../people/api/people';
import List from '../../tasks/components/List';
import { createDraftTask } from '../../tasks/api/tasks';
import TaskModal from '../../tasks/components/taskModal/TaskModal';
import { Plus } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';

function Product() {

  const { product_id } = useParams();
  const { module_id } = useParams();

  const [module, setModule] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState([]);
  const [people, setPeople] = useState([]);
  const [showTasksModal, setShowTasksModal] = useState(false);
  const [showAddTaskCard, setShowAddTaskCard] = useState(false);

  useEffect(() => {
    const fetchModule = async () => {
      try {
        const fetchedModule = await getProductModule(product_id, module_id);
        setModule(fetchedModule);
      } catch (error) {
        console.error('Error fetching :', error);
      }
    };

    const fetchModuleTasks = async () => {
      try {
        const fetchedModuleTasks = await getProductModuleTasks(product_id, module_id);
        setTasks(fetchedModuleTasks);
      } catch (error) {
        console.error('Error fetching :', error);
      }
    };

    const fetchPeople = async () => {

      try {
        const fetchedPeople = await getPeople();
        setPeople(fetchedPeople);
      } catch (error) {
        console.error('Error fetching :', error);
      }

    };

    fetchModule();
    fetchModuleTasks();
    fetchPeople();

  }, []);

  const handleCreateDraftTask = async () => {

    try {
      const newTask = await createDraftTask(module_id);
      setSelectedTask(newTask);
      setShowTasksModal(true);
      setTasks([...tasks, newTask]);
      toast.success('Successfully created a new task!');

    } catch (error) {
      console.log(error);
    }

  };

  return (
    <div>

      <ModuleHeader module={module} />

      <div className='container-fluid pt-3 px-2'>
        <List
          tasks={tasks} setTasks={setTasks}
          selectedTask={selectedTask} setSelectedTask={setSelectedTask}
          showTasksModal={showTasksModal} setShowTasksModal={setShowTasksModal}
          showAddTaskCard={showAddTaskCard} setShowAddTaskCard={setShowAddTaskCard}
        />
      </div>

      <TaskModal
        showTasksModal={showTasksModal}
        setShowTasksModal={setShowTasksModal}
        selectedTask={selectedTask}
        setSelectedTask={setSelectedTask}
        tasks={tasks}
        setTasks={setTasks}
        people={people}
      />

      <button onClick={handleCreateDraftTask} className='btn btn-dark right-bottom-button rounded px-3 py-2 shadow-lg'>
        <Plus className='h4 m-0' />
      </button>

    </div>
  )
}

export default Product