import React from 'react'
import styles from './GroupHeader.module.css'
import profileImagePlaceholder from '../../../common/assets/img/profile.svg'
import placeholderProfileImage1 from '../../../common/assets/img/placeholder-profile-img-1.jpeg'
import placeholderProfileImage2 from '../../../common/assets/img/placeholder-profile-img-2.jpg'

function GroupHeader({ itemGroup }) {
    return (
        <div className={styles.GroupHeader}>

            <div>
                <h3>{itemGroup.name}</h3>
                <p>{itemGroup.description}</p>
            </div>

        </div>
    )
}

export default GroupHeader