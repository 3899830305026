import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import { updateProduct } from '../api/products';
import { toast } from 'react-toastify';

function EditProductModal({ showEditProductModal, setShowEditProductModal, products, setProducts, selectedProduct, setSelectedProduct }) {

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        setName(selectedProduct.name);
        setDescription(selectedProduct.description);
    }, [selectedProduct]);

    const handleSubmit = async () => {
        const productObject = {
            "id": selectedProduct.id,
            "name": name,
            "description": description,
        };

        try {
            
            const updatedProducts = products.map(product =>
                product.id === selectedProduct.id ? productObject : product
            );
            setProducts(updatedProducts);

            setShowEditProductModal(false);

            setName('');
            setDescription('');

            toast.success("Successfully edited a product!");

            await updateProduct(productObject);

        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleCloseModal = () => {
        setShowEditProductModal(false);
    };

    return (
        <Modal
            show={showEditProductModal} onHide={handleCloseModal}
            backdrop="static" keyboard={false} size='lg'
            aria-labelledby="contained-modal-name-vcenter"
            centered
        >
            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='bold h5'>Edit product</Modal.Title>
            </Modal.Header>
            <Modal.Body className='py-4'>
                <label className='pb-2'>Name</label>
                <input type="text" className='form-control medium bg-gray-light py-2' placeholder='Product name' value={name} autoComplete="new-password" onChange={(e) => setName(e.target.value)} />

                <label className='mt-4 mb-2'>Description</label>
                <textarea type="text" className='form-control bg-gray-light py-2 medium' placeholder='Description' value={description} autoComplete="new-password" onChange={(e) => setDescription(e.target.value)} style={{ height: 130 }} />

                <div className='pt-4 d-flex justify-content-end'>
                    <button className='btn btn-primary fw-500 medium' onClick={handleSubmit}>Update</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default EditProductModal