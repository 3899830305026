import ExpenseGroups from './pages/ExpenseGroups';
import ExpenseGroup from './pages/ExpenseGroup';

const expenseRoutes = [
  {
    path: 'expenses/groups',
    element: <ExpenseGroups />,
  },
  {
    path: 'expenses/groups/:group_id',
    element: <ExpenseGroup />,
  },
];

export default expenseRoutes;