import React, { useState, useEffect, useRef } from 'react';
import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';
import { PencilSquare, ThreeDotsVertical, Trash, TrashFill } from 'react-bootstrap-icons';

function ExpenseGroupCard({ expenseGroup, openEditExpenseGroupModal, openDeleteExpenseGroupModal }) {

    const navigate = useNavigate();
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownVisible(false);
        }
    };

    useEffect(() => {
        if (dropdownVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownVisible]);
    

    return (
        <Card className='pointer h-100' onClick={() => { navigate('/expenses/groups/' + expenseGroup.id) }}>
            <div className='d-flex justify-content-between'>
                <h5 className='mb-1'>{expenseGroup.name}</h5>
                <div className='btn px-2 py-1' onClick={(e) => {e.stopPropagation(); toggleDropdown()}} ref={dropdownRef}>
                    <ThreeDotsVertical className='pointer' />
                    {dropdownVisible && (
                        <div className='dropdown-menu border-0 shadow-sm show'>
                            <li className='dropdown-item' onClick={(e) => openEditExpenseGroupModal(expenseGroup)}>
                                <PencilSquare className='me-2' /> Edit
                            </li>
                            <li className='dropdown-item text-danger' onClick={(e) => openDeleteExpenseGroupModal(expenseGroup)}>
                                <TrashFill className='me-2' /> Delete
                            </li>
                        </div>
                    )}
                </div>
            </div>

            <p>{expenseGroup.description}</p>
            <h5 className='mb-0 txt-primary'>${expenseGroup.total_value}</h5>

        </Card>
    );
}

export default ExpenseGroupCard;
