import React, { useState } from 'react'
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons'
import { faEllipsis, faPlus } from '@fortawesome/free-solid-svg-icons'
import profileImagePlaceholder from '../../common/assets/img/profile.svg'
import FocusButton from './FocusButton'
import { CheckCircleFill, Plus, ThreeDots } from 'react-bootstrap-icons'

function Board({ tasks, setTasks, setSelectedTask, selectedTask,
    showTasksModal, setShowTasksModal, showAddTaskCard, setShowAddTaskCard }) {


    const [subject, setSubject] = useState('');
    const [description, setDescription] = useState('');

    const handleShowTaskModal = (task) => {
        setSelectedTask(task);
        setShowTasksModal(true);
    }


    return (

        <div className="row">
            <div className="col-3 p-3">
                <div className='d-flex justify-content-between'>
                    <p className='mb-3 fw-500'>
                        To do
                        <span className='text-muted fw-400 medium px-2'>
                            {tasks.filter((task) => task.status === "todo").length}
                        </span>
                    </p>
                    <div>
                        <button className='px-2 btn'>
                            <Plus className='text-muted' />
                        </button>
                        <button className='px-2 btn'>
                            <ThreeDots className='text-muted' />
                        </button>
                    </div>
                </div>

                <div className={`bg-${tasks.filter(task => task.status === 'todo').length === 0 && tasks.length > 0 ? 'gray py-5' : ''} w-100 rounded`}>
                    {tasks.filter(task => task.status === 'todo').map(task => (
                        <div key={task.id} className="task-card mb-3" onClick={() => handleShowTaskModal(task)}>
                            <div className='px-1'>
                                <CheckCircleFill className='text-muted medium me-2' />
                                <span className='pe-2 medium'>{task.subject}</span>
                            </div>
                            <div className='pt-3 d-flex align-items-center justify-content-between'>
                                <div className='d-flex align-items-center'>
                                    <div className='pe-3'>
                                        <img src={profileImagePlaceholder} className='rounded-circle' alt="" style={{ maxHeight: 25, height: '100%' }} />
                                    </div>
                                    <span className='small text-muted'>
                                        {task?.due_date}
                                    </span>
                                </div>
                                <FocusButton task={task} />
                            </div>
                        </div>
                    ))}
                </div>


            </div>
            <div className="col-3 p-3">
                <div className='d-flex justify-content-between'>
                    <p className='mb-3 fw-500'>
                        Doing
                        <span className='text-muted fw-400 medium px-2'>
                            {tasks.filter((task) => task.status === "in_progress").length}
                        </span>
                    </p>
                    <div>
                        <button className='px-2 btn'>
                            <Plus className='text-muted' />
                        </button>
                        <button className='px-2 btn'>
                            <ThreeDots className='text-muted' />
                        </button>
                    </div>
                </div>
                <div className={`bg-${tasks.filter(task => task.status === 'in_progress').length === 0 ? 'gray py-5' : ''} w-100 rounded`}>
                    {tasks.filter(task => task.status === 'in_progress').map(task => (
                        <div key={task.id} className="task-card mb-3" onClick={() => handleShowTaskModal(task)}>
                            <div className='px-1'>
                                <CheckCircleFill className='text-muted medium me-2' />
                                <span className='pe-2 medium'>{task.subject}</span>
                            </div>
                            <div className='pt-3 d-flex align-items-center justify-content-between'>
                                <div className='d-flex align-items-center'>
                                    <div className='pe-3'>
                                        <img src={profileImagePlaceholder} className='rounded-circle' alt="" style={{ maxHeight: 25, height: '100%' }} />
                                    </div>
                                    <span className='small text-muted'>
                                        {task?.due_date}
                                    </span>
                                </div>
                                <FocusButton task={task} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="col-3 p-3">
                <div className='d-flex justify-content-between'>
                    <p className='mb-3 fw-500'>
                        On Hold
                        <span className='text-muted fw-400 medium px-2'>
                            {tasks.filter((task) => task.status === "on_hold").length}
                        </span>
                    </p>
                    <div>
                        <button className='px-2 btn'>
                            <Plus className='text-muted' />
                        </button>
                        <button className='px-2 btn'>
                            <ThreeDots className='text-muted' />
                        </button>
                    </div>
                </div>
                <div className={`bg-${tasks.filter(task => task.status === 'on_hold').length === 0 ? 'gray py-5' : ''} w-100 rounded`}>
                    {tasks.filter(task => task.status === 'on_hold').map(task => (
                        <div key={task.id} className="task-card mb-3" onClick={() => handleShowTaskModal(task)}>
                            <div className='px-1'>
                                <CheckCircleFill className='text-muted medium me-2' />
                                <span className='pe-2 medium'>{task.subject}</span>
                            </div>
                            <div className='pt-3 d-flex align-items-center justify-content-between'>
                                <div className='d-flex align-items-center'>
                                    <div className='pe-3'>
                                        <img src={profileImagePlaceholder} className='rounded-circle' alt="" style={{ maxHeight: 25, height: '100%' }} />
                                    </div>
                                    <span className='small text-muted'>
                                        {task?.due_date}
                                    </span>
                                </div>
                                <FocusButton task={task} />
                            </div>
                        </div>
                    ))}
                </div>

            </div>
            <div className="col-3 p-3">
                <div className='d-flex justify-content-between'>
                    <p className='mb-3 fw-500'>
                        Done
                        <span className='text-muted fw-400 medium px-2'>
                            {tasks.filter((task) => task.status === "done").length}
                        </span>
                    </p>
                    <div>
                        <button className='px-2 btn'>
                            <Plus className='text-muted' />
                        </button>
                        <button className='px-2 btn'>
                            <ThreeDots className='text-muted' />
                        </button>
                    </div>
                </div>
                <div className={`bg-${tasks.filter(task => task.status === 'done').length === 0 ? 'gray py-5' : ''} w-100 rounded`}>
                    {tasks.filter(task => task.status === 'done').map(task => (
                        <div key={task.id} className="task-card mb-3" onClick={() => handleShowTaskModal(task)}>
                            <div className='px-1'>
                                <CheckCircleFill className='txt-success medium me-2' />
                                <span className='pe-2 medium'>{task.subject}</span>
                            </div>
                            <div className='pt-3 d-flex align-items-center justify-content-between'>
                                <div className='d-flex align-items-center'>
                                    <div className='pe-3'>
                                        <img src={profileImagePlaceholder} className='rounded-circle' alt="" style={{ maxHeight: 25, height: '100%' }} />
                                    </div>
                                    <span className='small text-muted'>
                                        {task?.due_date}
                                    </span>
                                </div>
                                <FocusButton task={task} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>

    )
}

export default Board