import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getClientGroup, getGroupClients } from '../api/clients';
import ClientCard from '../components/clientCard/ClientCard';
import AddClientModal from '../components/AddClientModal';
import GroupHeader from '../components/groupHeader/GroupHeader';
import EditClientModal from '../components/EditClientModal';
import DeleteClientModal from '../components/DeleteClientModal';
import { Plus } from 'react-bootstrap-icons';

function ClientGroup() {

  const { group_id } = useParams();
  const [clientGroup, setClientGroup] = useState([]);
  const [clients, setClients] = useState([]);
  const [showAddClientModal, setShowAddClientModal] = useState(false);
  const [showEditClientModal, setShowEditClientModal] = useState(false);
  const [showDeleteClientModal, setShowDeleteClientModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState([]);

  useEffect(() => {
    const fetchClientGroup = async () => {
      try {
        const fetchedClientGroup = await getClientGroup(group_id);
        setClientGroup(fetchedClientGroup);
      } catch (error) {
        console.error('Error fetching :', error);
      }
    };

    const fetchClients = async () => {
      try {
        const fetchedClients = await getGroupClients(group_id);
        setClients(fetchedClients);
      } catch (error) {
        console.error('Error fetching :', error);
      }
    };

    fetchClientGroup();
    fetchClients();
  }, []);

  const openAddClientModal = () => {
    setShowAddClientModal(true);
  };

  const openEditClientModal = (client) => {
    setShowEditClientModal(true);
    setSelectedClient(client);
  }

  const openDeleteClientModal = (clientGroup) => {
    setShowDeleteClientModal(true);
    setSelectedClient(clientGroup);
  }

  return (
    <div>

      <GroupHeader clientGroup={clientGroup} />

      <button onClick={() => { openAddClientModal() }} className='btn btn-dark right-bottom-button rounded px-3 py-2 shadow-lg'>
        <Plus className='h4 m-0' />
      </button>

      <div className='container-fluid pt-3'>
        <div className='row px-3'>

          {clients.map(client => (
            <div key={client.id} className='col-md-4 mb-3'>
              <ClientCard client={client}
                openEditClientModal={openEditClientModal}
                openDeleteClientModal={openDeleteClientModal}
              />
            </div>
          ))}

        </div>
      </div>

      <AddClientModal
        clients={clients}
        setClients={setClients}
        showAddClientModal={showAddClientModal}
        setShowAddClientModal={setShowAddClientModal}
        group_id={group_id}
      />

      <EditClientModal
        clients={clients}
        setClients={setClients}
        selectedClient={selectedClient}
        setSelectedClient={setSelectedClient}
        showEditClientModal={showEditClientModal}
        setShowEditClientModal={setShowEditClientModal}
      />

      <DeleteClientModal
        clients={clients}
        setClients={setClients}
        showDeleteClientModal={showDeleteClientModal}
        setShowDeleteClientModal={setShowDeleteClientModal}
        selectedClient={selectedClient}
      />

    </div>
  )
}

export default ClientGroup