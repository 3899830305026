import { apiUrl } from "../../common/api/config";

export const getEvents = async (month) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/calendar/events/'+month, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}

export const storeEvent = async (event) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/calendar/events', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(event)

        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}

export const storeDraftEvent = async () => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/calendar/events/draft', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },

        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}


export const updateEvent = async (event_id, event) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/calendar/events/'+event_id, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(event)

        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}


export const addEventUser = async (event_id, user_id) => {

    try {

        const token = localStorage.getItem('token');

        const data = {
            "user_id": user_id
        }

        const response = await fetch(apiUrl + '/calendar/events/'+event_id+'/users', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)

        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}

export const removeEventUser = async (event_id, user_id) => {

    try {

        const token = localStorage.getItem('token');

        const data = {
            "user_id": user_id
        }

        const response = await fetch(apiUrl + '/calendar/events/'+event_id+'/users', {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)

        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}