import React, { useState} from 'react'
import { Modal } from 'react-bootstrap';
import { savePerson } from '../api/people';
import { toast } from 'react-toastify';

function AddPersonModal({ showAddPersonModal, setShowAddPersonModal, people, setPeople, selectedPerson, setSelectedPerson }) {

    const [name, setName] = useState('');
    const [comments, setComments] = useState('');

    const handleSubmit = async () => {
        const person = {
            "name": name,
            "comments": comments,
        };

        try {
            const newPerson = await savePerson(person);
            setPeople([newPerson, ...people]);
            setShowAddPersonModal(false);

            setName('');
            setComments('');
           
            toast.success("Successfully added a new person!");

        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleCloseModal = () => {
        setShowAddPersonModal(false);
    };

    return (
        <Modal
            show={showAddPersonModal} onHide={handleCloseModal}
            backdrop="static" keyboard={false} size='lg'
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='bold h5'>Add person</Modal.Title>
            </Modal.Header>
            <Modal.Body className='py-4'>
                <label className='pb-2'>Name</label>
                <input type="text" className='form-control medium bg-gray-light py-2' placeholder='Persons name' value={name} autoComplete="new-password" onChange={(e) => setName(e.target.value)} />

                <label className='mt-4 mb-2'>Comments</label>
                <textarea type="text" className='form-control bg-gray-light py-2 medium' placeholder='Comments' value={comments} autoComplete="new-password" onChange={(e) => setComments(e.target.value)} style={{ height: 130 }} />


                <div className='pt-4'>
                    <button className='btn btn-primary fw-500 medium' onClick={handleSubmit}>Save</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AddPersonModal