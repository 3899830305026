import React from 'react';
import { Modal } from 'react-bootstrap';
import { deleteExpense } from '../api/expenses';
import { toast } from 'react-toastify';

function DeleteExpenseModal({ expenses, setExpenses, showDeleteExpenseModal, setShowDeleteExpenseModal, selectedExpense }) {

    const handleCloseModal = () => {
        setShowDeleteExpenseModal(false);
    }

    const handleDeleteExpense = async (event) => {
        event.preventDefault();
        try {
            await deleteExpense(selectedExpense.id);
            const updatedExpenses = expenses.filter(expense => expense.id !== selectedExpense.id);
            setExpenses(updatedExpenses);
            toast.success("Successfully removed a expense!")
        } catch (error) {
            toast.error(error.message);
        }
        handleCloseModal()
    };

    return (
        <Modal show={showDeleteExpenseModal}
            onHide={handleCloseModal}
            backdrop="static"
            keyboard={true}
            size='md'
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='bold h5'>Delete expense</Modal.Title>
            </Modal.Header>

            <Modal.Body className='pt-4'>


                <p className='color-text opacity-80 medium'>
                    Are you sure you want to delete this expense? After you delete this expense, this action cannot be undone.
                </p>
                <div className='modal-footer border-0 pb-2'>
                    <button className='btn btn-danger rounded' onClick={(event) => { handleDeleteExpense(event) }}>Delete</button>
                </div>
            </Modal.Body>

        </Modal>

    );
}

export default DeleteExpenseModal;
