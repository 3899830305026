import React from 'react';
import { Modal } from 'react-bootstrap';
import { deletePerson } from '../api/people';
import { toast } from 'react-toastify';

function DeletePersonModal({ people, setPeople, showDeletePersonModal, setShowDeletePersonModal, selectedPerson }) {

    const handleCloseModal = () => {
        setShowDeletePersonModal(false);
    }

    const handleDeletePerson = async (event) => {
        event.preventDefault();
        try {
            await deletePerson(selectedPerson.id);
            const updatedPeople = people.filter(person => person.id !== selectedPerson.id);
            setPeople(updatedPeople);
            toast.success("Successfully removed a person!")
        } catch (error) {
            toast.error(error.message);
        }
        handleCloseModal()
    };

    return (
        <Modal show={showDeletePersonModal}
            onHide={handleCloseModal}
            backdrop="static"
            keyboard={true}
            size='md'
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='bold h5'>Delete person</Modal.Title>
            </Modal.Header>

            <Modal.Body className='pt-4'>


                <p className='color-text opacity-80 medium'>
                    Are you sure you want to delete this person? After you delete this person, this action cannot be undone.
                </p>
                <div className='modal-footer border-0 pb-2'>
                    <button className='btn btn-danger rounded' onClick={(event) => { handleDeletePerson(event) }}>Delete</button>
                </div>
            </Modal.Body>

        </Modal>

    );
}

export default DeletePersonModal;
