import React, { useState, useRef } from 'react';
import { convertSpeechToText } from '../../common/api/voice';
import { updateTask } from '../../tasks/api/tasks';
import { Mic, MicFill, SquareFill } from 'react-bootstrap-icons';

function RecordCommentFromFocus({ task, setTask }) {
    const [recording, setRecording] = useState(false);
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);

    const getMicrophonePermission = async () => {
        console.log('Requesting microphone access...');
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            return stream;
        } catch (error) {
            console.error('Error accessing microphone:', error);
            return null;
        }
    };

    const startRecording = async () => {
        if (!recording) {
            try {
                const stream = await getMicrophonePermission();
                const mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm' });

                mediaRecorder.ondataavailable = (event) => {
                    console.log('Data available:', event.data);
                    audioChunksRef.current.push(event.data);
                };

                mediaRecorder.onstop = async () => {
                    console.log('Recording stopped.');
                    const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
                    audioChunksRef.current = []; // Reset the chunks after download

                    var text = await convertSpeechToText(audioBlob);

                    var updatedDescription = task.description + "\n" + text.transcription;
                    var updatedTask = { ...task };
                    updatedTask.description = updatedDescription;

                    setTask(updatedTask);

                    try {
                        await updateTask(updatedTask);
                    } catch (error) {
                        console.log(error);
                    }

                };

                mediaRecorderRef.current = mediaRecorder;
                mediaRecorder.start();
                console.log('Recording started.');
                setRecording(true);
            } catch (error) {
                console.error('Error accessing microphone:', error);
            }
        }
    };

    const stopRecording = () => {
        if (recording && mediaRecorderRef.current) {
            console.log('Stopping recording...');
            mediaRecorderRef.current.stop();
            setRecording(false);
        }
    };

    return (
        <div>
            <MicFill
                className='text-danger mx-2 pointer'
                onClick={startRecording}
            />
            {recording &&
                <SquareFill
                    className='mx-2 pointer'
                    onClick={stopRecording}
                />
            }
        </div>
    );
}

export default RecordCommentFromFocus;
