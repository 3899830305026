import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getItemGroup, getGroupItems } from '../api/items';
import ItemCard from '../components/itemCard/ItemCard';
import AddItemModal from '../components/AddItemModal';
import GroupHeader from '../components/groupHeader/GroupHeader';
import EditItemModal from '../components/EditItemModal';
import DeleteItemModal from '../components/DeleteItemModal';
import { Plus, Trash, Trash2, TrashFill } from 'react-bootstrap-icons';

function ItemGroup() {

  const { group_id } = useParams();
  const [itemGroup, setItemGroup] = useState([]);
  const [items, setItems] = useState([]);
  const [showAddItemModal, setShowAddItemModal] = useState(false);
  const [showEditItemModal, setShowEditItemModal] = useState(false);
  const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);

  useEffect(() => {
    const fetchItemGroup = async () => {
      try {
        const fetchedItemGroup = await getItemGroup(group_id);
        setItemGroup(fetchedItemGroup);
      } catch (error) {
        console.error('Error fetching :', error);
      }
    };

    const fetchItems = async () => {
      try {
        const fetchedItems = await getGroupItems(group_id);
        setItems(fetchedItems);
      } catch (error) {
        console.error('Error fetching :', error);
      }
    };

    fetchItemGroup();
    fetchItems();
  }, []);

  const openAddItemModal = () => {
    setShowAddItemModal(true);
  };

  const openEditItemModal = (item) => {
    setShowEditItemModal(true);
    setSelectedItem(item);
  }

  const openDeleteItemModal = (item) => {
    setShowDeleteItemModal(true);
    setSelectedItem(item);
  }

  return (
    <div>

      <GroupHeader itemGroup={itemGroup} />

      <button onClick={() => { openAddItemModal() }} className='btn btn-dark right-bottom-button rounded px-3 py-2 shadow-lg'>
        <Plus className='h4 m-0' />
      </button>

      <div className='container-fluid pt-3'>
        <div className='row px-3'>

          {items.map(item => (
            <div key={item.id} className='col-md-4 mb-3'>
              <ItemCard item={item}
                openEditItemModal={openEditItemModal}
                openDeleteItemModal={openDeleteItemModal}
              />
            </div>
          ))}

        </div>
      </div>

      <AddItemModal
        items={items}
        setItems={setItems}
        showAddItemModal={showAddItemModal}
        setShowAddItemModal={setShowAddItemModal}
        group_id={group_id}
      />

      <EditItemModal
        items={items}
        setItems={setItems}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        showEditItemModal={showEditItemModal}
        setShowEditItemModal={setShowEditItemModal}
      />

      <DeleteItemModal
        items={items}
        setItems={setItems}
        showDeleteItemModal={showDeleteItemModal}
        setShowDeleteItemModal={setShowDeleteItemModal}
        selectedItem={selectedItem}
      />

    </div>
  )
}

export default ItemGroup