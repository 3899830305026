import React, { useState, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { saveClient } from '../api/clients';
import { toast } from 'react-toastify';
import Button from '../../common/components/Button';
import placeholderProfileImage from '../../common/assets/img/profile.svg';
import { Camera } from 'react-bootstrap-icons';

function AddClientModal({ group_id, showAddClientModal, setShowAddClientModal, clients, setClients }) {
    const [name, setName] = useState('');
    const [comments, setComments] = useState('');
    const [avatar, setAvatar] = useState(null);
    const [preview, setPreview] = useState(placeholderProfileImage);
    const inputRef = useRef(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setAvatar(file);
            setPreview(URL.createObjectURL(file));
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        setError('');

        const formData = new FormData();
        formData.append('name', name);
        formData.append('comments', comments);
        formData.append('client_group_id', group_id);

        if (avatar) {
            formData.append('avatar', avatar);
        }

        try {
            const newClient = await saveClient(formData);
            setClients([newClient, ...clients]);
            setShowAddClientModal(false);

            setName('');
            setComments('');
            setAvatar(null);
            setPreview(placeholderProfileImage);

            toast.success("Successfully added a new client!");

        } catch (error) {
            toast.error(error.message);
        }

        setLoading(false);
    };


    const handleCloseModal = () => {
        setShowAddClientModal(false);
    };

    return (
        <Modal
            show={showAddClientModal} onHide={handleCloseModal}
            backdrop="static" keyboard={false} size='lg'
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='bold h5'>Add client</Modal.Title>
            </Modal.Header>
            <Modal.Body className='py-4'>
                {error && <span className='text-danger small'>{error}</span>}

                <div
                    style={{ maxWidth: 75 }}
                    className="mb-3 position-relative profile-image"
                >
                    <img
                        src={preview}
                        className="img-fluid rounded hover"
                        style={{ aspectRatio: 1, objectFit: "cover" }}
                        alt="Client avatar"
                    />
                    <input
                        ref={inputRef}
                        onChange={handleFileChange}
                        type="file"
                        style={{ height: 0, width: 0 }}
                        className="invisible position-absolute"
                    />
                    <button
                        className="project-image-overlay"
                        onClick={() => inputRef?.current?.click()}
                    >
                        <Camera className="text-white" />
                    </button>
                </div>

                <label className='pb-2'>Name</label>
                <input type="text" className='form-control medium bg-gray-light py-2' placeholder='Clients name' value={name} autoComplete="new-password" onChange={(e) => setName(e.target.value)} />

                <label className='mt-4 mb-2'>Comments</label>
                <textarea type="text" className='form-control bg-gray-light py-2 medium' placeholder='Comments' value={comments} autoComplete="new-password" onChange={(e) => setComments(e.target.value)} style={{ height: 130 }} />

                <div className='pt-4 d-flex justify-content-end'>
                    <Button variant="primary" size="sm" onClick={handleSubmit} loading={loading}>
                        Save
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AddClientModal;
