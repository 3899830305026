import React, { useState, useEffect } from 'react';
import { BookmarkDash, Plus } from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form';
import { getSchedule, updateSchedule } from '../api/schedule';
import { toast } from 'react-toastify';
import styles from './Availability.module.css';

function Availability() {
    const [schedule, setSchedule] = useState(null);
    const [wasChanged, setWasChanged] = useState(false);

    const formatTime = (timeString) => {
        const [hours, minutes] = timeString.split(':');
        return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
    };

    useEffect(() => {
        const loadSchedule = async () => {
            try {
                const fetchedSchedule = await getSchedule();
                const days = JSON.parse(fetchedSchedule.days);

                const formattedDays = days.map(day => ({
                    ...day,
                    start_time: formatTime(day.start_time),
                    end_time: formatTime(day.end_time),
                }));

                setSchedule({
                    ...fetchedSchedule,
                    days: formattedDays
                });
            } catch (error) {
                console.error('Error fetching schedule:', error);
            }
        };

        loadSchedule();
    }, []);

    const handleTimeChange = (index, field, value) => {
        const updatedDays = [...schedule.days];
        updatedDays[index][field] = value;
        setSchedule({
            ...schedule,
            days: updatedDays
        });
        setWasChanged(true);
    };

    const handleCheckboxChange = (index, checked) => {
        const updatedDays = [...schedule.days];
        updatedDays[index].available = checked;
        setSchedule({
            ...schedule,
            days: updatedDays
        });
        setWasChanged(true);
    };

    const handleUpdate = async () => {
        try {
            await updateSchedule(schedule, schedule.id);
            toast.success("Successfully updated schedule!");
            setWasChanged(false);
        } catch (error) {
            toast.error("Failed to update schedule.");
            console.error('Error updating schedule:', error);
        }
    };

    if (!schedule?.days?.length) {
        return (
            <div className='d-flex justify-content-center align-items-center' style={{ height: '70vh' }}>
                <div className='spinner-border txt-primary' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <>
            <h4 className='mt-3 bold'>Schedule</h4>

            <button className='btn btn-basic border-primary border-1 medium mt-4 rounded d-flex align-items-center h-100'>
                <BookmarkDash className='me-2' />
                Working hours
            </button>

            <div className="card mt-3 shadow-sm">
                <div className="row">
                    <div className="col-lg-6 pe-5">
                        <h5 className='mb-4 mt-2'>Weekly hours</h5>

                        <div className="container-fluid">
                            <div className='row'>
                                {schedule.days.map((entry, index) => (
                                    <React.Fragment key={index}>
                                        <div className="col-md-5 py-3 px-1">
                                            <div className="d-flex align-items-center h-100">
                                                <Form.Check
                                                    type="checkbox"
                                                    checked={entry.available}
                                                    onChange={(e) => handleCheckboxChange(index, e.target.checked)}
                                                />
                                                <span className='ps-2 fw-500'>{entry.day}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-7 py-3 px-1">
                                            <div className="d-flex align-items-center h-100">
                                                <input
                                                    type="time"
                                                    className='form-control'
                                                    value={entry.start_time}
                                                    onChange={(e) => handleTimeChange(index, 'start_time', e.target.value)}
                                                />
                                                <span className='px-2'>-</span>
                                                <input
                                                    type="time"
                                                    className='form-control'
                                                    value={entry.end_time}
                                                    onChange={(e) => handleTimeChange(index, 'end_time', e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>

                        <div className={`${styles.saveButtonContainer} ${wasChanged ? `${styles.visible}` : ''}`}>
                            <button className='btn btn-primary medium ms-2 mt-3' onClick={handleUpdate}>
                                Save changes
                            </button>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <h5 className='mb-4 mt-2'>Add holidays</h5>

                        <p className='text-muted medium'>
                            Override your availability for specific dates when you have something else planned outside of work.
                        </p>

                        <button className='btn btn-basic medium border-primary border-1 mt-2'>
                            <Plus className='me-1' />
                            Add holiday
                        </button>

                    </div>
                </div>
            </div>
        </>
    );
}

export default Availability;
