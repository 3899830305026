import { apiUrl } from '../../common/api/config';


export const getProducts = async () => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/products', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}

export const getProduct = async (product_id) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/products/' + product_id, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}

export const getProductModules = async (product_id) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/products/' + product_id + '/modules', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}

export const getProductModule = async (product_id, module_id) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/products/' + product_id + '/modules/' + module_id, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}

export const getProductModuleTasks = async (product_id, module_id) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/products/' + product_id + '/modules/' + module_id + '/tasks', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}

export const saveProduct = async (product) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/products', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(product)
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}

export const saveProductModule = async (product_id, productModule) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/products/'+ product_id +'/modules', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(productModule)
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}


export const updateProductModule = async (product_id, productModule) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/products/' + product_id + '/modules/' + productModule.id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(productModule)
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}

export const updateProduct = async (product) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/products/' + product.id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(product)
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}

export const deleteProductModule = async (product_module_id) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/products/modules/'  + product_module_id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}

export const deleteProduct = async (product_id) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/products/' + product_id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}