import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { ArrowRight, ChevronRight, Copy, PencilSquare, Person, PersonFill, ThreeDotsVertical, TrashFill } from 'react-bootstrap-icons'
import { deleteEventType } from '../api/event_types';

function EventTypeCard({ eventType, openEditEventTypeModal }) {

    const navigate = useNavigate();
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownVisible(false);
        }
    };

    useEffect(() => {
        if (dropdownVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownVisible]);

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            alert('Link copied to clipboard!');
        }).catch((err) => {
            console.error('Failed to copy: ', err);
        });
    };

    const email = localStorage.getItem('email');
    const username = email.split('@')[0];

    const handleDeleteEventType = async () => {
        await deleteEventType(eventType.id);
        window.location.reload();
    }

    return (
        <div className="col-6">
            <div className='card shadow-sm d-flex justify-content-center flex-column pe-2'>

                <div className='d-flex justify-content-between'>
                    <h5 className='fw-400'>{eventType.name}</h5>
                    <div className='px-2' onClick={(e) => { e.stopPropagation(); toggleDropdown() }} ref={dropdownRef}>
                        <ThreeDotsVertical className='pointer hover-lg' />
                        {dropdownVisible && (
                            <div className='dropdown-menu border-0 shadow-sm show'>
                                <li className='dropdown-item pointer' onClick={() => {openEditEventTypeModal(eventType)}}>
                                    <PencilSquare className='me-2' /> Edit
                                </li>
                                <li className='dropdown-item pointer text-danger' onClick={handleDeleteEventType}>
                                    <TrashFill className='me-2' /> Delete
                                </li>
                            </div>
                        )}
                    </div>
                </div>

                <span className='text-muted'>{eventType.description}</span>
                <hr />
                <button
                    className='btn btn-basic rounded-lg w-fit txt-primary px-3 py-2'
                    onClick={() => handleCopy('https://app.bigbusinessengine.com/' + username + '/' + eventType.id)}
                >
                    <Copy className='me-2' /> Copy
                </button>
            </div>

        </div>
    )
}

export default EventTypeCard
