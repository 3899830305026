import React from 'react';
import icon from '../../assets/img/logo.png';
import { formatDistanceToNow } from 'date-fns';
import { Link, useNavigate } from 'react-router-dom';
import { Envelope } from 'react-bootstrap-icons';
import { markNotificationsAsRead } from '../../api/notifications';

function NotificationsDropdown({ userInfo, setUserInfo }) {

    const navigate = useNavigate()

    const handleMarkAllAsRead = async () => {
        try {
            const updatedNotifications = await markNotificationsAsRead();
            setUserInfo(prevState => ({
                ...prevState,
                notifications: updatedNotifications.map(notification => ({
                    ...notification,
                    is_read: true
                }))
            }));
        } catch (error) {
            console.error('Error updating notifications:', error);
        }
    };

    return (
        <div>
            <div className="dropdown-menu dropdown-menu-left show active" aria-labelledby="dropdownMenuButton" x-placement="bottom-end"
                style={{ position: 'absolute', width: 450 }}>
                <div className="title-wrap d-flex align-items-center">
                    <h3 className="title mb-0">Notifications</h3>
                </div>
                <ul className="custom-notifications">
                    {userInfo?.notifications?.map(notification => (
                        <li
                            className={`notification-item hover-lg ${!notification.is_read ? 'unread' : ''}`}
                            key={notification.id}
                        >
                            <a href={notification.redirect_to ?? '/notifications/'+notification.id} className="d-flex align-items-center">
                                <div className="notification-sender-icon me-3">
                                    <img src={icon} alt="Image" className="img-fluid" />
                                </div>
                                <div className='text'>
                                    <span className='fw-500'>
                                        {notification.title}
                                    </span>
                                    <p className='medium pt-1 mb-1'>
                                        {notification.body}
                                    </p>
                                    <span className='small text-muted mb-0'>
                                        {formatDistanceToNow(new Date(notification.created_at), { addSuffix: true })}
                                    </span>
                                </div>
                            </a>
                        </li>
                    ))}
                </ul>
                <div className='d-flex justify-content-between align-items-center pt-2'>
                    <span className="text-center"><Link to="/notifications" className="small txt-primary">Read all</Link></span>
                    <span className="text-center small d-flex align-items-center pointer" onClick={handleMarkAllAsRead}>
                        <Envelope className='me-1' />
                        Mark all as read
                    </span>
                </div>

            </div>
        </div>
    );
}

export default NotificationsDropdown;
