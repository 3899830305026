import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import { updateExpense } from '../api/expenses';
import { toast } from 'react-toastify';

function EditExpenseModal({ showEditExpenseModal, setShowEditExpenseModal, expenses, setExpenses, selectedExpense, setSelectedExpense }) {

    const [name, setName] = useState('');
    const [value, setValue] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        setName(selectedExpense?.name);
        setValue(selectedExpense?.value);
        setDescription(selectedExpense?.description);
    }, [selectedExpense]);

    const handleSubmit = async () => {
        const expense = {
            "id": selectedExpense.id,
            "name": name,
            "value": value,
            "description": description
        };

        try {
            const updatedExpense = await updateExpense(expense);
            setSelectedExpense(updatedExpense);
            
            const updatedExpenses = expenses.map(expense =>
                expense.id === updatedExpense.id ? updatedExpense : expense
            );
            setExpenses(updatedExpenses);

            setShowEditExpenseModal(false);

            setName('');
            setValue(null);
            setDescription('');

            toast.success("Successfully edited a expense!");

        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleCloseModal = () => {
        setShowEditExpenseModal(false);
    };

    return (
        <Modal
            show={showEditExpenseModal} onHide={handleCloseModal}
            backdrop="static" keyboard={false} size='lg'
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='bold h5'>Edit expense</Modal.Title>
            </Modal.Header>
            <Modal.Body className='py-4'>
                <label className='pb-2'>Name</label>
                <input type="text" className='form-control medium bg-gray-light py-2' placeholder='Name' value={name}  onChange={(e) => setName(e.target.value)} />

                <label className='mt-4 mb-2'>Value</label>
                <input type="text" className='form-control medium bg-gray-light py-2' placeholder='Value ($)' value={value} onChange={(e) => setValue(e.target.value)} />

                <label className='mt-4 mb-2'>Description</label>
                <textarea type="text" className='form-control bg-gray-light py-2 medium' rows={5} placeholder='Description' value={description} onChange={(e) => setDescription(e.target.value)} style={{ height: 130 }} />


                <div className='pt-4 d-flex justify-content-end'>
                    <button className='btn btn-primary fw-500 medium' onClick={handleSubmit}>Update</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default EditExpenseModal