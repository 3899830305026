import { apiUrl } from '../../common/api/config';

export const getWorkers = async () => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/workers/', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}

export const getWorkerGroups = async () => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/workers/groups', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}

export const getWorkerGroup = async (group_id) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/workers/groups/' + group_id, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}

export const getGroupWorkers = async (group_id) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/workers/groups/' + group_id + '/workers', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}

export const saveWorkerGroup = async (workerGroup) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/workers/groups', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(workerGroup)
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}

export const saveWorker = async (worker) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/workers', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token
            },
            body: worker
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}


export const updateWorker = async (worker, worker_id) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/workers/' + worker_id, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token
            },
            body: worker
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}

export const updateWorkerGroup = async (workerGroup) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/workers/groups/' + workerGroup.id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(workerGroup)
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}

export const deleteWorker = async (worker_id) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/workers/' + worker_id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}

export const deleteWorkerGroup = async (worker_group_id) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/workers/groups/' + worker_group_id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}