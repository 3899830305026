import { apiUrl } from "../../common/api/config";

export const updateProfileImage = async (file) => {
    try {
        const token = localStorage.getItem("token");

        if (file.size > 2 * 1024 * 1024) {
            window.alert("File size exceeds 2MB limit.");
            return;
        }

        const formData = new FormData();
        formData.append("avatar", file);

        const response = await fetch(apiUrl + "/user/avatar", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
            },
            body: formData,
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }
};