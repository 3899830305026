export const getFocusedTask = () => {
    var focusedTask = localStorage.getItem('focusedTask');

    if (!focusedTask) {
        let focusedTask = {};
        localStorage.setItem('focusedTask', JSON.stringify(focusedTask));
        return focusedTask;
    }

    focusedTask = JSON.parse(focusedTask);

    return focusedTask;
};

export const setFocusedTask = (task) => {
    localStorage.setItem('focusedTask', JSON.stringify(task));
    return "Success";
};
