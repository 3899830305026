import React, { useState, useEffect } from 'react';
import { getRevenueGroups } from '../api/revenues';
import AddRevenueGroupModal from '../components/AddRevenueGroupModal';
import RevenueGroupCard from '../components/revenueGroupCard/RevenueGroupCard';
import EditRevenueGroupModal from '../components/EditRevenueGroupModal';
import DeleteRevenueGroupModal from '../components/DeleteRevenueGroupModal';
import { Plus } from 'react-bootstrap-icons';
import { ReactComponent as MoneyImg } from '../../common/assets/img/vectors/money.svg'


function RevenueGroups() {

    const [revenueGroups, setRevenueGroups] = useState([]);
    const [showAddRevenueGroupModal, setShowAddRevenueGroupModal] = useState(false);
    const [selectedRevenueGroup, setSelectedRevenueGroup] = useState([]);
    const [showEditRevenueGroupModal, setShowEditRevenueGroupModal] = useState(false);
    const [showDeleteRevenueGroupModal, setShowDeleteRevenueGroupModal] = useState(false);

    useEffect(() => {
        const fetchRevenueGroups = async () => {
            try {
                const fetchedRevenueGroups = await getRevenueGroups();
                setRevenueGroups(fetchedRevenueGroups);
            } catch (error) {
                console.error('Error fetching :', error);
            }
        };

        fetchRevenueGroups();
    }, []);

    const openAddRevenueGroupModal = () => {
        setShowAddRevenueGroupModal(true);
    };

    const openEditRevenueGroupModal = (revenueGroup) => {
        setShowEditRevenueGroupModal(true);
        setSelectedRevenueGroup(revenueGroup);
    }

    const openDeleteRevenueGroupModal = (revenueGroup) => {
        setShowDeleteRevenueGroupModal(true);
        setSelectedRevenueGroup(revenueGroup);
    }

    return (

        <div>

            <button onClick={() => { openAddRevenueGroupModal() }} className='btn btn-dark right-bottom-button rounded px-3 py-2 shadow-lg'>
                <Plus className='h4 m-0' />
            </button>

            {revenueGroups.length === 0 ? (
                <div className='d-flex flex-column align-items-center justify-content-center' style={{ height: '100vh' }}>
                    <MoneyImg />
                    <p className='text-center text-muted mt-3'>You didn't create any revenue groups yet!</p>
                </div>
            ) : (
                <div className='container-fluid' style={{ paddingTop: 77 }}>
                    <div className='row px-3'>

                        {revenueGroups.map(revenueGroup => (
                            <div key={revenueGroup.id} className='col-md-4 mb-3'>
                                <RevenueGroupCard revenueGroup={revenueGroup} openEditRevenueGroupModal={openEditRevenueGroupModal} openDeleteRevenueGroupModal={openDeleteRevenueGroupModal} />
                            </div>
                        ))}

                    </div>
                </div>
            )}

            <AddRevenueGroupModal
                revenueGroups={revenueGroups}
                setRevenueGroups={setRevenueGroups}
                showAddRevenueGroupModal={showAddRevenueGroupModal}
                setShowAddRevenueGroupModal={setShowAddRevenueGroupModal}
            />

            <EditRevenueGroupModal
                revenueGroups={revenueGroups}
                setRevenueGroups={setRevenueGroups}
                selectedRevenueGroup={selectedRevenueGroup}
                setSelectedRevenueGroup={setSelectedRevenueGroup}
                showEditRevenueGroupModal={showEditRevenueGroupModal}
                setShowEditRevenueGroupModal={setShowEditRevenueGroupModal}
            />

            <DeleteRevenueGroupModal
                revenueGroups={revenueGroups}
                setRevenueGroups={setRevenueGroups}
                showDeleteRevenueGroupModal={showDeleteRevenueGroupModal}
                setShowDeleteRevenueGroupModal={setShowDeleteRevenueGroupModal}
                selectedRevenueGroup={selectedRevenueGroup}
            />

        </div >
    )
}

export default RevenueGroups;
