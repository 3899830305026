import React from 'react'

function SkeletonLoader() {
    return (
        <div className='row'>
            <div class="col-md-6 rounded-0 pb-1 mb-2">
                <div class="px-2 pt-3 pb-2">
                    <div class="bone bone-title mb-3"></div>
                </div>
                <div>
                    <div class="bone bone-image"></div>
                </div>
            </div>
            <div class="col-md-6 rounded-0 pb-1 mb-2">
                <div class="px-2 pt-3 pb-2" style={{ visibility: 'hidden' }}>
                    <div class="bone bone-title mb-3"></div>
                </div>
                <div>
                    <div class="bone bone-image"></div>
                </div>
            </div>
            <div class="col-md-6 rounded-0 pb-1 mb-2">
                <div>
                    <div class="bone bone-image"></div>
                </div>
            </div>
            <div class="col-md-6 rounded-0 pb-1 mb-2">
                <div>
                    <div class="bone bone-image"></div>
                </div>
            </div>
            <div class="col-md-6 rounded-0 pb-1 mb-2">
                <div>
                    <div class="bone bone-image"></div>
                </div>
            </div>
        </div>
    )
}

export default SkeletonLoader