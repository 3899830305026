import React from 'react';
import { Modal } from 'react-bootstrap';
import { deleteRevenueGroup } from '../api/revenues';
import { toast } from 'react-toastify';

function DeleteRevenueGroupModal({ revenueGroups, setRevenueGroups, showDeleteRevenueGroupModal, setShowDeleteRevenueGroupModal, selectedRevenueGroup }) {

    const handleCloseModal = () => {
        setShowDeleteRevenueGroupModal(false);
    }

    const handleDeleteRevenueGroup = async (event) => {
        event.preventDefault();
        try {
            const updatedRevenueGroups = revenueGroups.filter(revenueGroup => revenueGroup.id !== selectedRevenueGroup.id);
            setRevenueGroups(updatedRevenueGroups);
            toast.success("Successfully removed revenue group!")
            await deleteRevenueGroup(selectedRevenueGroup.id);
            handleCloseModal()
        } catch (error) {
            toast.error(error.message);
        }
    };

    return (
        <Modal show={showDeleteRevenueGroupModal}
            onHide={handleCloseModal}
            backdrop="static"
            keyboard={true}
            size='md'
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='bold h5'>Delete revenue group</Modal.Title>
            </Modal.Header>

            <Modal.Body className='pt-4'>

                <p className='color-text opacity-80 medium'>
                    Are you sure you want to delete this revenue group? After you delete this revenue group, this action cannot be undone.
                </p>
                <div className='modal-footer border-0 pb-2'>
                    <button className='btn btn-danger rounded' onClick={(event) => { handleDeleteRevenueGroup(event) }}>Delete</button>
                </div>
            </Modal.Body>

        </Modal>

    );
}

export default DeleteRevenueGroupModal;
