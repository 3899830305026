import React from 'react';

const StatusBadge = ({ status }) => {
    const getBadgeClass = (status) => {
        switch (status) {
            case 'todo':
                return 'badge-primary bgc-primary';
            case 'in_progress':
                return 'badge-warning bg-warning';
            case 'on_hold':
                return 'badge-danger bg-danger';
            case 'waiting_for':
                return 'badge-warning bg-warning';
            case 'done':
                return 'badge-success bgc-success';
            default:
                return 'badge-secondary bg-secondary';
        }
    };

    return (
        <span className={`badge ${getBadgeClass(status)}`}>
            {status?.replace('_', ' ')}
        </span>
    );
};

export default StatusBadge;
