import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import { updateProductModule } from '../api/products';
import { toast } from 'react-toastify';

function EditProductModuleModal({ product_id, showEditProductModuleModal, setShowEditProductModuleModal, productModules, setProductModules, selectedProductModule, setSelectedProductModule }) {

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        setName(selectedProductModule.name);
        setDescription(selectedProductModule.description);
    }, [selectedProductModule]);

    const handleSubmit = async () => {
        const productModule = {
            "id": selectedProductModule.id,
            "name": name,
            "description": description
        };

        try {
            const updatedProductModule = await updateProductModule(product_id, productModule);
            setSelectedProductModule(updatedProductModule);
            
            const updatedProductModules = productModules.map(productModule =>
                productModule.id === updatedProductModule.id ? updatedProductModule : productModule
            );
            setProductModules(updatedProductModules);

            setShowEditProductModuleModal(false);

            setName('');
            setDescription('');

            toast.success("Successfully edited a product module!");

        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleCloseModal = () => {
        setShowEditProductModuleModal(false);
    };

    return (
        <Modal
            show={showEditProductModuleModal} onHide={handleCloseModal}
            backdrop="static" keyboard={false} size='lg'
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='bold h5'>Edit product module</Modal.Title>
            </Modal.Header>
            <Modal.Body className='py-4'>
                <label className='pb-2'>Name</label>
                <input type="text" className='form-control medium bg-gray-light py-2' placeholder='Module name' value={name} autoComplete="new-password" onChange={(e) => setName(e.target.value)} />

                <label className='mt-4 mb-2'>Description</label>
                <textarea type="text" className='form-control bg-gray-light py-2 medium' placeholder='Description' value={description} autoComplete="new-password" onChange={(e) => setDescription(e.target.value)} style={{ height: 130 }} />


                <div className='pt-4 d-flex justify-content-end'>
                    <button className='btn btn-primary fw-500 medium' onClick={handleSubmit}>Update</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default EditProductModuleModal