import React from 'react';
import styles from './Cover.module.css';

function Cover({ selectedTask }) {
    const firstAttachmentUrl = selectedTask?.attachments?.[0]?.url;

    return (
        <div>
            {firstAttachmentUrl && (
                <img src={firstAttachmentUrl} className={styles.image} alt="" />
            )}
        </div>
    );
}

export default Cover;
