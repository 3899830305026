import { apiUrl } from "../../common/api/config";

export const getSchedule = async () => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/schedule', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}

export const updateSchedule = async (schedule, schedule_id) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/schedule/' + schedule_id, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(schedule)

        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}