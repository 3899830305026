import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import { updateItemGroup } from '../api/items';
import { toast } from 'react-toastify';

function EditItemGroupModal({ showEditItemGroupModal, setShowEditItemGroupModal, itemGroups, setItemGroups, selectedItemGroup, setSelectedItemGroup }) {

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        setName(selectedItemGroup.name);
        setDescription(selectedItemGroup.description);
    }, [selectedItemGroup]);

    const handleSubmit = async () => {
        const itemGroupObject = {
            "id": selectedItemGroup.id,
            "name": name,
            "description": description,
        };

        try {
            
            const updatedItemGroups = itemGroups.map(itemGroup =>
                itemGroup.id === selectedItemGroup.id ? itemGroupObject : itemGroup
            );
            setItemGroups(updatedItemGroups);

            setShowEditItemGroupModal(false);

            setName('');
            setDescription('');

            toast.success("Successfully edited a item group!");

            await updateItemGroup(itemGroupObject);

        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleCloseModal = () => {
        setShowEditItemGroupModal(false);
    };

    return (
        <Modal
            show={showEditItemGroupModal} onHide={handleCloseModal}
            backdrop="static" keyboard={false} size='lg'
            aria-labelledby="contained-modal-name-vcenter"
            centered
        >
            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='bold h5'>Edit item group</Modal.Title>
            </Modal.Header>
            <Modal.Body className='py-4'>
                <label className='pb-2'>Name</label>
                <input type="text" className='form-control medium bg-gray-light py-2' placeholder='Item group name' value={name} autoComplete="new-password" onChange={(e) => setName(e.target.value)} />

                <label className='mt-4 mb-2'>Description</label>
                <textarea type="text" className='form-control bg-gray-light py-2 medium' placeholder='Description' value={description} autoComplete="new-password" onChange={(e) => setDescription(e.target.value)} style={{ height: 130 }} />

                <div className='pt-4 d-flex justify-content-end'>
                    <button className='btn btn-primary fw-500 medium' onClick={handleSubmit}>Update</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default EditItemGroupModal