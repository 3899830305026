import MyBusinesses from "./pages/MyBusinesses";
import CreateNewBusiness from "./pages/CreateNewBusiness";

const businessRoutes = [
  {
    path: 'my-businesses',
    element: <MyBusinesses />,
  },
  {
    path: 'businesses/create',
    element: <CreateNewBusiness />,
  },
];

export default businessRoutes;