import React, { useState, useRef } from 'react';
import { Dropdown, Spinner } from 'react-bootstrap';
import { storeAttachment } from '../../../api/attachments';
import { Paperclip, X } from 'react-bootstrap-icons';

function AddAttachmentModal({ selectedTask, setSelectedTask, tasks, setTasks }) {

    const [showDropdown, setShowDropdown] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const fileInputRef = useRef(null);

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const closeDropdown = () => {
        setShowDropdown(false);
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setLoading(true);
            try {
                const updatedTask = await storeAttachment(file, selectedTask.id);
                closeDropdown();
                setSelectedTask(updatedTask);
                const updatedTasks = tasks.map(task => {
                    if (task.id === updatedTask.id) {
                        return updatedTask;
                    }
                    return task;
                });
                setTasks(updatedTasks);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        }
    };

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    return (
        <Dropdown show={showDropdown} drop="start" className='w-100'>
            <Dropdown.Toggle variant="basic" className='bg-gray border shadow-sm hover medium w-100' onClick={toggleDropdown}>
                <Paperclip className='me-2' />
                Attachment
            </Dropdown.Toggle>

            <Dropdown.Menu className="shadow-lg border-0 p-3" style={{ width: 420 }}>

                <div className='d-flex align-items-center justify-content-between'>
                    <span className="medium"><b>Attach a file from your computer</b></span>
                    <span className='text-muted pointer' onClick={closeDropdown}><X /> </span>
                </div>

                <span className='small text-lighter pb-2'>
                    You can also drag and drop files to upload them.
                </span>

                {error && <span className='text-danger small'>{error}</span>}

                <div className="pb-3 pt-4">
                    <button className="btn btn-basic medium w-100 bg-gray border shadow-sm hover" onClick={triggerFileInput} disabled={loading}>
                        {loading ? (
                            <>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                            </>
                        ) : (
                            'Upload File'
                        )}
                    </button>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                </div>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default AddAttachmentModal;
