import { apiUrl } from '../../common/api/config';

const getPeople = async () => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/people', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}
const savePerson = async (person) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/people', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(person)
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}

const updatePerson = async (person) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/people/' + person.id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(person)
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}

const deletePerson = async (person_id) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/people/' + person_id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}


export {
    getPeople, savePerson, updatePerson, deletePerson
}