import React from 'react';
import { Modal } from 'react-bootstrap';
import { deleteClientGroup } from '../api/clients';
import { toast } from 'react-toastify';

function DeleteClientGroupModal({ clientGroups, setClientGroups, showDeleteClientGroupModal, setShowDeleteClientGroupModal, selectedClientGroup }) {

    const handleCloseModal = () => {
        setShowDeleteClientGroupModal(false);
    }

    const handleDeleteClientGroup = async (event) => {
        event.preventDefault();
        try {
            const updatedClientGroups = clientGroups.filter(clientGroup => clientGroup.id !== selectedClientGroup.id);
            setClientGroups(updatedClientGroups);
            toast.success("Successfully removed client group!")
            await deleteClientGroup(selectedClientGroup.id);
            handleCloseModal()
        } catch (error) {
            toast.error(error.message);
        }
    };

    return (
        <Modal show={showDeleteClientGroupModal}
            onHide={handleCloseModal}
            backdrop="static"
            keyboard={true}
            size='md'
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='bold h5'>Delete client group</Modal.Title>
            </Modal.Header>

            <Modal.Body className='pt-4'>

                <p className='color-text opacity-80 medium'>
                    Are you sure you want to delete this client group? After you delete this client group, this action cannot be undone.
                </p>
                <div className='modal-footer border-0 pb-2'>
                    <button className='btn btn-danger rounded' onClick={(event) => { handleDeleteClientGroup(event) }}>Delete</button>
                </div>
            </Modal.Body>

        </Modal>

    );
}

export default DeleteClientGroupModal;
