import React, { useState, useEffect } from 'react'
import { getFocusedTask } from '../../common/utils/focus'
import { truncateString } from '../../common/utils/format_utils'
import RecordCommentFromFocus from './RecordCommentFromFocus'
import { saveTimeTracker, stopTimeTracker, continueTimeTracker } from '../../common/api/time_tracker'
import { ArrowClockwise, ArrowLeft, Link, Paperclip, Pause, Play, PlayFill } from 'react-bootstrap-icons'

function Focus() {

  const [time, setTime] = useState(0);
  const [running, setRunning] = useState(false);
  const [task, setTask] = useState([]);
  const [existingTimeTracker, setExistingTimeTracker] = useState([]);

  var focusedTask = getFocusedTask();

  useEffect(() => {
    setTask(focusedTask);
  }, []);


  useEffect(() => {
    let timerInterval;
    if (running) {
      timerInterval = setInterval(() => {
        setTime(prevTime => prevTime + 1);
      }, 1000);
    }

    return () => clearInterval(timerInterval);
  }, [running]);


  const handleCreateTimeTracker = async () => {

    const timeTracker = {
      task_id: task.id
    }

    const createdTimeTracker = await saveTimeTracker(timeTracker)
    setExistingTimeTracker(createdTimeTracker)

  }

  const handleStopTimeTracker = async () => {
    const timeTracker = {
      time_spent: time
    }

    try {
      await stopTimeTracker(timeTracker, existingTimeTracker.id)
    } catch (error) {
      console.log(error)
    }

  }

  const handleContinueTimeTracker = async () => {

    try {
      await continueTimeTracker(existingTimeTracker.id)
    } catch (error) {
      console.log(error)
    }

  }

  const handleReturnToTasks = () => {
    setTime(0);
    setRunning(false);
    handleStopTimeTracker();
    setExistingTimeTracker([]);
    window.ipcRenderer.send('close-focus-mode');
  }

  const startStopwatch = () => {
    setRunning(true);
    if (existingTimeTracker.length === 0) {
      handleCreateTimeTracker();
    } else {
      handleContinueTimeTracker()
    }
  };

  const pauseStopwatch = () => {
    setRunning(false);
    handleStopTimeTracker();
  };

  const resetStopwatch = () => {
    setTime(0);
    setRunning(false);
    handleStopTimeTracker();
    setExistingTimeTracker([]);
  };

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleLinkClick = (url) => {
    window.ipcRenderer.shell(url);
  };

  return (
    <div className='focus-window-wrapper rounded-lg px-2 pb-2' style={{ height: 200, '-webkit-app-region': 'drag' }}>
      <div className='bg-white rounded-lg px-4 pb-4 pt-3 h-100' style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>

        <div className='d-flex align-items-center pb-3'>
          <h4 className='mb-0 mt-1 pe-2 bold'>{formatTime(time)}</h4>
          {running ? (
            <button className='btn p-0' onClick={pauseStopwatch}><Pause className='h3 mb-0 mx-1' /></button>
          ) : (
            <button className='btn p-0' onClick={startStopwatch}><PlayFill className='mx-1 h3 m-0 txt-primary' /></button>
          )}
          <button className='btn p-0' onClick={resetStopwatch}><ArrowClockwise className='mx-1' /></button>
        </div>


        <a onClick={() => {handleLinkClick(`http://localhost:3000/tasks#${task.id}`)}} className='pointer bold txt-primary medium'>
          {task.subject}
        </a>
        <p className='small text-muted'>
          {task?.description?.length && truncateString(task.description, 100)}
        </p>

        <div className='d-flex align-items-center justify-content-between'>
          <button className='btn px-0 small' onClick={() => { handleReturnToTasks() }}>
            <ArrowLeft className='me-2' />
            Return to app
          </button>
          <div className='d-flex align-items-center'>
            <div className='px-2'>
              <RecordCommentFromFocus task={task} setTask={setTask} />
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Focus