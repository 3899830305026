import React from 'react'
import styles from './ModuleHeader.module.css'

function ModuleHeader({ module }) {
    return (
        <div className={styles.ModuleHeader}>

            <div>
                <h3>{module.name}</h3>
                <p>{module.description}</p>
            </div>

        </div>
    )
}

export default ModuleHeader