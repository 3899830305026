import React, { useState, useEffect, useRef } from 'react';
import Button from '../../../../common/components/Button';
import { toast } from 'react-toastify';
import { storeComment } from '../../../api/comments';
import profileImagePlaceholder from '../../../../common/assets/img/profile.svg';
import { getBusinessMembers } from '../../../../common/api/business';
import { useAuth } from '../../../../common/contexts/AuthContext';
import styles from './Comments.module.css';
import { Image, Link45deg, Paperclip, TypeBold, TypeItalic, XCircle } from 'react-bootstrap-icons';

function AddComment({ selectedTask, setSelectedTask, tasks, setTasks }) {
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isTextareaFocused, setIsTextareaFocused] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const wrapperRef = useRef(null);
  const commentInputRef = useRef(null);
  const fileInputRef = useRef(null);
  const { avatar } = useAuth();

  useEffect(() => {
    const fetchBusinessMembers = async () => {
      try {
        const fetchedBusinessMembers = await getBusinessMembers();
        setUsers(fetchedBusinessMembers);
      } catch (error) {
        console.error('Error fetching business members:', error);
      }
    };

    fetchBusinessMembers();
  }, []);

  const handleSaveComment = async () => {
    setLoading(true);

    try {
      const updatedTask = await storeComment(comment, selectedTask.id, selectedFiles);
      setSelectedTask(updatedTask);

      const updatedTasks = tasks.map((task) =>
        task.id === updatedTask.id ? updatedTask : task
      );
      setTasks(updatedTasks);
      toast.success('Comment saved!');
    } catch (error) {
      console.log(error);
    }

    setComment('');
    setSelectedFiles([]);
    setLoading(false);
  };

  const handleInputChange = (event) => {
    const newComment = event.target.value;
    setComment(newComment);

    const atIndex = newComment.indexOf('@');
    if (atIndex !== -1) {
      const searchTerm = newComment.slice(atIndex + 1).toLowerCase();
      const filtered = users.filter((user) =>
        user?.user?.name?.toLowerCase().includes(searchTerm)
      );
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers([]);
    }

    setIsDropdownOpen(atIndex !== -1);
  };

  const handleUserSelection = (name) => {
    const atIndex = comment.indexOf('@');
    const updatedComment = `${comment.slice(0, atIndex + 1)}${name}${comment.slice(atIndex + name.length)}`;
    setComment(updatedComment);
    setIsDropdownOpen(false);
  };

  const handleFileInputChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]); // Append new files to existing ones
  };

  const handleImageIconClick = (event) => {
    event.stopPropagation(); // Prevent event from bubbling up
    fileInputRef.current.click(); // Trigger file input click
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(updatedFiles);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        if (event.target !== fileInputRef.current) {
          setIsTextareaFocused(false);
          setIsDropdownOpen(false);
        }
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => document.removeEventListener('click', handleClickOutside);
  }, [wrapperRef]);

  return (
    <>
      <div className="d-flex align-items-center">
        <div className="pe-2">
          <img
            src={avatar ?? profileImagePlaceholder}
            className="rounded-circle"
            alt=""
            style={{ height: 28, width: 28, objectFit: 'cover' }}
            loading='lazy'
          />
        </div>
        <div className="form-control w-100" ref={wrapperRef}>
          {isTextareaFocused && (
            <div className={styles.commentToolbar}>
              <div className={styles.commentToolbarTool} onClick={handleImageIconClick}>
                <Image />
              </div>
              <div className={styles.commentToolbarToolDevider}></div>
              <div className={styles.commentToolbarTool}>
                <TypeBold className="h5 mb-0" />
              </div>
              <div className={styles.commentToolbarTool}>
                <TypeItalic className="h5 mb-0" />
              </div>
              <div className={styles.commentToolbarToolDevider}></div>
              <div className={styles.commentToolbarTool}>
                <Paperclip />
              </div>
              <div className={styles.commentToolbarTool}>
                <Link45deg />
              </div>
            </div>
          )}

          <div>
            <textarea
              className="border-0 form-control w-100 px-0 py-2 medium"
              rows={1}
              value={comment}
              placeholder="Write a comment..."
              onChange={handleInputChange}
              onFocus={() => setIsTextareaFocused(true)}
              ref={commentInputRef}
            />
            {selectedFiles.length > 0 && (
              <div className="d-flex mt-3 flex-wrap">
                {selectedFiles.map((file, index) => (
                  <div key={index} className="me-2 position-relative">
                    <img
                      src={URL.createObjectURL(file)}
                      alt="Preview"
                      className="rounded shadow-sm"
                      style={{ height: 75, width: 95, objectFit: 'cover' }}
                    />
                    <XCircle
                      onClick={() => handleRemoveFile(index)}
                      className="position-absolute top-0 start-100 translate-middle p-1 bg-light rounded-circle"
                      style={{ cursor: 'pointer', fontSize: '1.5rem', color: 'red' }}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <input
        type="file"
        ref={fileInputRef}
        className="d-none"
        multiple
        onChange={handleFileInputChange}
      />

      {isDropdownOpen && filteredUsers.length > 0 && (
        <div className="dropdown-menu show ms-5 border-0 shadow">
          {filteredUsers.map((user) => (
            <button
              key={user.id}
              className="dropdown-item py-2"
              onClick={() => handleUserSelection(user?.user?.name)}
            >
              <img
                src={user?.user?.avatar ?? profileImagePlaceholder}
                className="rounded-circle"
                alt=""
                style={{ height: 26, width: 26, objectFit: 'cover' }}
              />
              <span className="ps-2">
                {user?.user?.name}
              </span>
            </button>
          ))}
        </div>
      )}

      <div className="d-flex justify-content-end pt-2">
        <div className={`${styles.saveButtonContainer} ${comment.trim() ? `${styles.visible}` : ''}`}>
          <Button
            loading={loading}
            onClick={handleSaveComment}
            size="sm"
            variant="primary"
            className="medium"
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
}

export default AddComment;
