import { apiUrl } from './config';

export const getMyBusinesses = async () => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/my-businesses', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}

export const getBusinessMembers = async () => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/business/members', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}

export const getBusinessInfo = async () => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/business-info', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}

export const switchBusiness = async (business_id) => {

    try {

        const token = localStorage.getItem('token');

        const data = {
            "business_id": business_id
        }

        const response = await fetch(apiUrl + '/switch-business', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}

export const updateBusinessInfo = async (name, description) => {

    try {

        const token = localStorage.getItem('token');

        const data = {
            "name": name,
            "description": description
        }

        const response = await fetch(apiUrl + '/business', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}