import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { saveExpenseGroup } from '../api/expenses';
import { toast } from 'react-toastify';
import Button from '../../common/components/Button';

function AddExpenseGroupModal({ showAddExpenseGroupModal, setShowAddExpenseGroupModal, expenseGroups, setExpenseGroups }) {

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async () => {

        setLoading(true);
        setError('');

        const expenseGroup = {
            "name": name,
            "description": description
        };

        try {
            const newExpense = await saveExpenseGroup(expenseGroup);
            setExpenseGroups([newExpense, ...expenseGroups]);
            setShowAddExpenseGroupModal(false);

            setName('');

            toast.success("Successfully added a new expense group!");

        } catch (error) {
            toast.error(error.message);
        }

        setLoading(false);

    };

    const handleCloseModal = () => {
        setShowAddExpenseGroupModal(false);
    };

    return (
        <Modal
            show={showAddExpenseGroupModal} onHide={handleCloseModal}
            backdrop="static" keyboard={false} size='lg'
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='bold h5'>Add expense group</Modal.Title>
            </Modal.Header>
            <Modal.Body className='py-4'>

                {error && <span className='text-danger small'>{error}</span>} { }

                <label className='pb-2 ps-1'>Name</label>
                <input type="text" className='form-control medium bg-gray-light py-2' placeholder='Name' value={name} autoComplete="new-password" onChange={(e) => setName(e.target.value)} />

                <label className='pb-2 ps-1 mt-3'>Description</label>
                <textarea type="text" className='form-control medium bg-gray-light py-2' placeholder='Description' value={description} autoComplete="new-password" onChange={(e) => setDescription(e.target.value)} />

                <div className='pt-4 d-flex justify-content-end'>
                    <Button onClick={handleSubmit} variant="primary" size="sm" type="submit" loading={loading} className="w-100 py-3" >
                        Save
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AddExpenseGroupModal