import React, { useState, useEffect } from 'react'
import { getNotification } from '../api/notification'
import { useParams } from 'react-router-dom';

function Notification() {
    const { notification_id } = useParams();
    const [notification, setNotification] = useState([])

    useEffect(() => {
        const fetchNotificationData = async () => {
            try {
                const fetchedNotification = await getNotification(notification_id);
                setNotification(fetchedNotification);
            } catch (error) {
                console.error('Error fetching :', error);
            }
        };

        fetchNotificationData();
    }, [notification_id]);

    return (
        <div className='px-2 pt-3'>
            <h2 className='bold'>{notification.title}</h2>

            <p>
                {notification.body}
            </p>
        </div>
    )
}

export default Notification