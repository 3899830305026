import { apiUrl } from '../../common/api/config';

export const getRevenues = async () => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/revenues/', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}

export const getRevenueGroups = async () => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/revenues/groups', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}

export const getRevenueGroup = async (group_id) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/revenues/groups/' + group_id, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}

export const getGroupRevenues = async (group_id) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/revenues/groups/' + group_id + '/revenues', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}

export const saveRevenueGroup = async (revenueGroup) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/revenues/groups', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(revenueGroup)
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}

export const saveRevenue = async (revenue) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/revenues', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(revenue)
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}


export const updateRevenue = async (revenue) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/revenues/' + revenue.id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(revenue)
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}

export const updateRevenueGroup = async (revenueGroup) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/revenues/groups/' + revenueGroup.id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(revenueGroup)
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}

export const deleteRevenue = async (revenue_id) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/revenues/' + revenue_id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}

export const deleteRevenueGroup = async (revenue_group_id) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/revenues/groups/' + revenue_group_id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}