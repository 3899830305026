import React from 'react';
import { Modal } from 'react-bootstrap';
import { deleteExpenseGroup } from '../api/expenses';
import { toast } from 'react-toastify';

function DeleteExpenseGroupModal({ expenseGroups, setExpenseGroups, showDeleteExpenseGroupModal, setShowDeleteExpenseGroupModal, selectedExpenseGroup }) {

    const handleCloseModal = () => {
        setShowDeleteExpenseGroupModal(false);
    }

    const handleDeleteExpenseGroup = async (event) => {
        event.preventDefault();
        try {
            const updatedExpenseGroups = expenseGroups.filter(expenseGroup => expenseGroup.id !== selectedExpenseGroup.id);
            setExpenseGroups(updatedExpenseGroups);
            toast.success("Successfully removed expense group!")
            await deleteExpenseGroup(selectedExpenseGroup.id);
            handleCloseModal()
        } catch (error) {
            toast.error(error.message);
        }
    };

    return (
        <Modal show={showDeleteExpenseGroupModal}
            onHide={handleCloseModal}
            backdrop="static"
            keyboard={true}
            size='md'
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='bold h5'>Delete expense group</Modal.Title>
            </Modal.Header>

            <Modal.Body className='pt-4'>

                <p className='color-text opacity-80 medium'>
                    Are you sure you want to delete this expense group? After you delete this expense group, this action cannot be undone.
                </p>
                <div className='modal-footer border-0 pb-2'>
                    <button className='btn btn-danger rounded' onClick={(event) => { handleDeleteExpenseGroup(event) }}>Delete</button>
                </div>
            </Modal.Body>

        </Modal>

    );
}

export default DeleteExpenseGroupModal;
