import React, { useState, useEffect } from 'react'
import BusinessCard from '../../common/components/BusinessCard';
import { getMyBusinesses } from '../../common/api/business';
import { switchBusiness } from '../../common/api/business';
import CreateNewBusinessCard from '../components/CreateNewBusinessCard';
import { useNavigate } from 'react-router-dom';
import { Plus } from 'react-bootstrap-icons';

function MyBusinesses() {

    const [businesses, setBusinesses] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchBusinesses = async () => {

            try {
                const fetchedBusinesses = await getMyBusinesses();
                setBusinesses(fetchedBusinesses);
            } catch (error) {
                console.error('Error fetching :', error);
            }
        };

        fetchBusinesses();

    }, []);


    const handleSwitchBusiness = async (business_id) => {
        try {
            await switchBusiness(business_id);
            navigate("/home");
            window.location.reload();

        } catch (error) {
            console.error('Error fetching :', error);
        }
    }


    return (
        <div className='container pt-3'>
            <div className='d-flex justify-content-between align-items-center mt-5 px-2 mb-4'>
                <div>
                    <h4 className='bold'>Businesses</h4>
                    <p className='text-muted'>Find all your personal and shared businesses</p>
                </div>
                <button onClick={() => {navigate('/businesses/create')}} className='btn btn-primary px-4 py-2'><Plus className='me-1 h4 mb-0' /> Create</button>
            </div>

            <div className="row">
                <CreateNewBusinessCard />
                {businesses.map(business => (
                    <BusinessCard business={business} key={business.id} handleSwitchBusiness={handleSwitchBusiness} />
                ))}

            </div>
        </div>
    )
}

export default MyBusinesses