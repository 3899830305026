import React from 'react';
import { Modal } from 'react-bootstrap';
import { deleteProduct } from '../api/products';
import { toast } from 'react-toastify';

function DeleteProductModal({ products, setProducts, showDeleteProductModal, setShowDeleteProductModal, selectedProduct }) {

    const handleCloseModal = () => {
        setShowDeleteProductModal(false);
    }

    const handleDeleteProduct = async (event) => {
        event.preventDefault();
        try {
            const updatedProducts = products.filter(product => product.id !== selectedProduct.id);
            setProducts(updatedProducts);
            toast.success("Successfully removed product!")
            await deleteProduct(selectedProduct.id);
            handleCloseModal()
        } catch (error) {
            toast.error(error.message);
        }
    };

    return (
        <Modal show={showDeleteProductModal}
            onHide={handleCloseModal}
            backdrop="static"
            keyboard={true}
            size='md'
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='bold h5'>Delete product</Modal.Title>
            </Modal.Header>

            <Modal.Body className='pt-4'>

                <p className='color-text opacity-80 medium'>
                    Are you sure you want to delete this product? After you delete this product, this action cannot be undone.
                </p>
                <div className='modal-footer border-0 pb-2'>
                    <button className='btn btn-danger rounded' onClick={(event) => { handleDeleteProduct(event) }}>Delete</button>
                </div>
            </Modal.Body>

        </Modal>

    );
}

export default DeleteProductModal;
