import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import { updatePerson } from '../api/people';
import { toast } from 'react-toastify';

function EditPersonModal({ showEditPersonModal, setShowEditPersonModal, people, setPeople, selectedPerson, setSelectedPerson }) {

    const [name, setName] = useState(selectedPerson.name || '');
    const [comments, setComments] = useState(selectedPerson.comments || '');

    useEffect(() => {
        setName(selectedPerson.name || '');
        setComments(selectedPerson.comments || '');
    }, [selectedPerson]);

    const handleSubmit = async () => {
        const person = {
            "id": selectedPerson.id,
            "name": name,
            "comments": comments
        };

        try {
            const updatedPerson = await updatePerson(person);
            setSelectedPerson(updatedPerson);
            
            const updatedPeople = people.map(person =>
                person.id === updatedPerson.id ? updatedPerson : person
            );
            setPeople(updatedPeople);

            setShowEditPersonModal(false);

            setName('');
            setComments('');

            toast.success("Successfully edited a person!");

        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleCloseModal = () => {
        setShowEditPersonModal(false);
    };

    return (
        <Modal
            show={showEditPersonModal} onHide={handleCloseModal}
            backdrop="static" keyboard={false} size='lg'
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='bold h5'>View person</Modal.Title>
            </Modal.Header>
            <Modal.Body className='py-4'>
                <label className='pb-2'>Name</label>
                <input type="text" className='form-control medium bg-gray-light py-2' placeholder='Persons name' value={name} autoComplete="new-password" onChange={(e) => setName(e.target.value)} />

                <label className='mt-4 mb-2'>Comments</label>
                <textarea type="text" className='form-control bg-gray-light py-2 medium' placeholder='Comments' value={comments} autoComplete="new-password" onChange={(e) => setComments(e.target.value)} style={{ height: 130 }} />


                <div className='pt-4'>
                    <button className='btn btn-primary fw-500 medium' onClick={handleSubmit}>Update</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default EditPersonModal