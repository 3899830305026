import React, { useState, useEffect, useRef } from 'react';
import profileImagePlaceholder from '../../../common/assets/img/profile.svg';
import { toggleAssignee } from '../../api/tasks';
import { Check, CheckCircle, Plus } from 'react-bootstrap-icons';

function UpdateAssigneeDropdown({ people, selectedTask, setSelectedTask, tasks, setTasks }) {
    const [isOpen, setIsOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleAssignTask = async (person_id) => {
        try {
            const updatedTask = await toggleAssignee(person_id, selectedTask.id);
            setSelectedTask(updatedTask);

            const taskIndex = tasks.findIndex(task => task.id === updatedTask.id);

            if (taskIndex !== -1) {
                const updatedTasks = [...tasks];
                updatedTasks[taskIndex] = updatedTask;

                setTasks(updatedTasks);
            } else {
                console.error('Updated task not found in tasks array');
            }
        } catch (error) {
            console.error('Error fetching:', error);
        }
        setSearchQuery('')
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value.toLowerCase());
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Filter people based on search query
    const filteredPeople = people?.filter(person =>
        person.name.toLowerCase().includes(searchQuery)
    );

    return (
        <>
            <div className="dropdown d-flex flex-column align-items-center justify-content-center mt-3" ref={dropdownRef}>
                <button
                    className="btn btn-basic bg-gray border rounded-circle d-flex justify-content-center align-items-center"
                    onClick={toggleDropdown}
                    style={{ width: 40, height: 40 }}
                >
                    <h5 className='m-0 p-0 text-lighter d-flex justify-content-center align-items-center'><Plus /></h5>
                </button>
                <span className='pt-2 px-2 small'>Add member</span>
                {isOpen && (
                    <div id='assignee-dropdown-menu' className="dropdown-menu show p-3 border-0 shadow" aria-labelledby="dropdownMenuButton">
                        <div className='pb-3'>
                            <span className='pt-2 fw-500'>Assign to</span>
                        </div>
                        <input
                            type="text"
                            className="form-control mb-3"
                            placeholder="Search..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                        <ul className='list-unstyled'>
                            {filteredPeople?.map(person => (
                                <li
                                    className={`nav-item assignee-nav-item py-2 pe-4 pointer ${selectedTask?.assignees?.some(assignee => assignee.id === person.id)
                                        ? 'assigned'
                                        : ''
                                    }`}
                                    key={person.id}
                                    onClick={() => handleAssignTask(person.id)}
                                >
                                    <img
                                        src={person?.avatar ?? profileImagePlaceholder}
                                        className="img-fluid rounded-circle"
                                        style={{ width: 30, height: 30, objectFit: 'cover' }}
                                        alt="" loading='lazy'
                                    />
                                    <span className="ps-2">
                                        {person.name}
                                        {selectedTask?.assignees?.some(assignee => assignee.id === person.id) && (
                                            <span className="ms-2">
                                                <Check className='txt-success h5 m-0' />
                                            </span>
                                        )}
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </>
    );
}

export default UpdateAssigneeDropdown;
