import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import { updateRevenue } from '../api/revenues';
import { toast } from 'react-toastify';

function EditRevenueModal({ showEditRevenueModal, setShowEditRevenueModal, revenues, setRevenues, selectedRevenue, setSelectedRevenue }) {

    const [name, setName] = useState('');
    const [value, setValue] = useState(null);
    const [description, setDescription] = useState('');

    useEffect(() => {
        setName(selectedRevenue?.name);
        setValue(selectedRevenue?.value);
        setDescription(selectedRevenue?.description);
    }, [selectedRevenue]);

    const handleSubmit = async () => {
        const revenue = {
            "id": selectedRevenue.id,
            "name": name,
            "value": value,
            "description": description
        };

        try {
            const updatedRevenue = await updateRevenue(revenue);
            setSelectedRevenue(updatedRevenue);
            
            const updatedRevenues = revenues.map(revenue =>
                revenue.id === updatedRevenue.id ? updatedRevenue : revenue
            );
            setRevenues(updatedRevenues);

            setShowEditRevenueModal(false);

            setName('');
            setValue(null);
            setDescription('');

            toast.success("Successfully edited a revenue!");

        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleCloseModal = () => {
        setShowEditRevenueModal(false);
    };

    return (
        <Modal
            show={showEditRevenueModal} onHide={handleCloseModal}
            backdrop="static" keyboard={false} size='lg'
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='bold h5'>Edit revenue</Modal.Title>
            </Modal.Header>
            <Modal.Body className='py-4'>
                <label className='pb-2'>Name</label>
                <input type="text" className='form-control medium bg-gray-light py-2' placeholder='Name' value={name}  onChange={(e) => setName(e.target.value)} />

                <label className='mt-4 mb-2'>Value</label>
                <input type="number" className='form-control medium bg-gray-light py-2' placeholder='Value ($)' value={value} onChange={(e) => setValue(e.target.value)} />

                <label className='mt-4 mb-2'>Description</label>
                <textarea type="text" className='form-control bg-gray-light py-2 medium' rows={5} placeholder='Description' value={description} onChange={(e) => setDescription(e.target.value)} style={{ height: 130 }} />


                <div className='pt-4 d-flex justify-content-end'>
                    <button className='btn btn-primary fw-500 medium' onClick={handleSubmit}>Update</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default EditRevenueModal