import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { saveProductModule } from '../api/products';
import { toast } from 'react-toastify';
import Button from '../../common/components/Button';

function AddProductModuleModal({ product_id, showAddProductModuleModal, setShowAddProductModuleModal, productModules, setProductModules }) {

    const [name, setName] = useState('');
    const [description, setProducts] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async () => {

        setLoading(true);
        setError('');

        const productModule = {
            "name": name,
            "description": description,
            "product_id": product_id
        };

        try {
            const newProductModule = await saveProductModule(product_id, productModule);
            setProductModules([newProductModule, ...productModules]);
            setShowAddProductModuleModal(false);

            setName('');
            setProducts('');

            toast.success("Successfully added a new product module!");

        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleCloseModal = () => {
        setShowAddProductModuleModal(false);
    };

    return (
        <Modal
            show={showAddProductModuleModal} onHide={handleCloseModal}
            backdrop="static" keyboard={false} size='lg'
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='bold h5'>Add product module</Modal.Title>
            </Modal.Header>
            <Modal.Body className='py-4'>

                {error && <span className='text-danger small'>{error}</span>} { }

                <label className='pb-2'>Name</label>
                <input type="text" className='form-control medium bg-gray-light py-2' placeholder='Module name' value={name} autoComplete="new-password" onChange={(e) => setName(e.target.value)} />

                <label className='mt-4 mb-2'>Description</label>
                <textarea type="text" className='form-control bg-gray-light py-2 medium' placeholder='Description' value={description} autoComplete="new-password" onChange={(e) => setProducts(e.target.value)} style={{ height: 130 }} />


                <div className='pt-4 d-flex justify-content-end'>
                    <Button variant="primary" size="sm" onClick={handleSubmit} loading={loading} >
                        Save
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AddProductModuleModal