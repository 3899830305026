import ClientGroups from './pages/ClientGroups';
import ClientGroup from './pages/ClientGroup';

const clientRoutes = [
  {
    path: 'clients/groups',
    element: <ClientGroups />,
  },
  {
    path: 'clients/groups/:group_id',
    element: <ClientGroup />,
  },
];

export default clientRoutes;