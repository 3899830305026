import { apiUrl } from '../../common/api/config';

const getMyTasks = async () => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/my-tasks', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}

const createDraftTask = async (product_module_id) => {

    try {

        const data = {
            'product_module_id': product_module_id
        }

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/tasks', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}

const toggleAssignee = async (person_id, task_id) => {

    const data = {
        "person_id": person_id,
        "task_id": task_id
    }

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/tasks/toggle-assignee', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}

const updateTask = async (updatedTask) => {

    try {

        const token = localStorage.getItem('token');
        const data = updatedTask;

        console.log(data);

        const response = await fetch(apiUrl + '/tasks/' + String(updatedTask.id), {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}


const removeTask = async (task_id) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/tasks/' + task_id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}

const saveSubtask = async (subtask, parent_id) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/tasks/subtasks/'+parent_id, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(subtask)
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}

const removeSubtask = async (subtask_id) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/tasks/subtasks/' + subtask_id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}

export { getMyTasks, createDraftTask, updateTask, toggleAssignee, removeTask, saveSubtask, removeSubtask };