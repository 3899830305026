import React, { useState, useEffect } from 'react';
import { getProducts } from '../api/products';
import AddProductModal from '../components/AddProductModal';
import ProductCard from '../components/productCard/ProductCard';
import EditProductModal from '../components/EditProductModal';
import DeleteProductModal from '../components/DeleteProductModal';
import { Plus } from 'react-bootstrap-icons';
import { ReactComponent as EmptyBoxImg } from '../../common/assets/img/vectors/empty_box.svg'

function Products() {

    const [products, setProducts] = useState([]);
    const [showAddProductModal, setShowAddProductModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [showEditProductModal, setShowEditProductModal] = useState(false);
    const [showDeleteProductModal, setShowDeleteProductModal] = useState(false);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const fetchedProducts = await getProducts();
                setProducts(fetchedProducts);
            } catch (error) {
                console.error('Error fetching :', error);
            }
        };

        fetchProducts();
    }, []);

    const openAddProductModal = () => {
        setShowAddProductModal(true);
    };

    const openEditProductModal = (product) => {
        setShowEditProductModal(true);
        setSelectedProduct(product);
    }

    const openDeleteProductModal = (product) => {
        setShowDeleteProductModal(true);
        setSelectedProduct(product);
    }

    return (

        <div>

            <button onClick={() => { openAddProductModal() }} className='btn btn-dark right-bottom-button rounded px-3 py-2 shadow-lg'>
                <Plus className='h4 m-0' />
            </button>

            {products.length === 0 ? (
                <div className='d-flex flex-column align-items-center justify-content-center' style={{ height: '100vh' }}>
                    <EmptyBoxImg />
                    <p className='text-center text-muted mt-3'>You didn't create any products yet!</p>
                </div>
            ) : (
                <div className='container-fluid' style={{ paddingTop: 77 }}>
                    <div className='row px-3'>

                        {products.map(product => (
                            <div key={product.id} className='col-md-4 mb-3'>
                                <ProductCard product={product} openEditProductModal={openEditProductModal} openDeleteProductModal={openDeleteProductModal} />
                            </div>
                        ))}

                    </div>
                </div>
            )}


            <AddProductModal
                products={products}
                setProducts={setProducts}
                showAddProductModal={showAddProductModal}
                setShowAddProductModal={setShowAddProductModal}
            />

            <EditProductModal
                products={products}
                setProducts={setProducts}
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
                showEditProductModal={showEditProductModal}
                setShowEditProductModal={setShowEditProductModal}
            />

            <DeleteProductModal
                products={products}
                setProducts={setProducts}
                showDeleteProductModal={showDeleteProductModal}
                setShowDeleteProductModal={setShowDeleteProductModal}
                selectedProduct={selectedProduct}
            />

        </div >
    )
}

export default Products;
