import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import { updateItem } from '../api/items';
import { toast } from 'react-toastify';

function EditItemModal({ showEditItemModal, setShowEditItemModal, items, setItems, selectedItem, setSelectedItem }) {

    const [name, setName] = useState('');
    const [type, setType] = useState("physical");
    const [description, setDescription] = useState('');

    useEffect(() => {
        setName(selectedItem.name);
        setType(selectedItem.type);
        setDescription(selectedItem.description);
    }, [selectedItem]);

    const handleSubmit = async () => {
        const item = {
            "id": selectedItem.id,
            "name": name,
            "type": type,
            "description": description
        };

        try {
            const updatedItem = await updateItem(item);
            setSelectedItem(updatedItem);

            const updatedItems = items.map(item =>
                item.id === updatedItem.id ? updatedItem : item
            );
            setItems(updatedItems);

            setShowEditItemModal(false);

            setName('');
            setType('');
            setDescription('');

            toast.success("Successfully edited a item!");

        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleCloseModal = () => {
        setShowEditItemModal(false);
    };

    const handleTypeChange = (event) => {
        setType(event.target.value);
    };

    return (
        <Modal
            show={showEditItemModal} onHide={handleCloseModal}
            backdrop="static" keyboard={false} size='lg'
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='bold h5'>Edit item</Modal.Title>
            </Modal.Header>
            <Modal.Body className='py-4'>
                <label className='pb-2'>Name</label>
                <input type="text" className='form-control medium bg-gray-light py-2' placeholder='Items name' value={name} autoComplete="new-password" onChange={(e) => setName(e.target.value)} />

                <label className='mt-4 mb-2'>Type</label>
                <select value={type} className='form-control bg-gray-light py-2 medium' onChange={handleTypeChange}>
                    <option value="physical">Physical</option>
                    <option value="digital">Digital</option>
                </select>

                <label className='mt-4 mb-2'>Description</label>
                <textarea type="text" className='form-control bg-gray-light py-2 medium' placeholder='Description' value={description} autoComplete="new-password" onChange={(e) => setDescription(e.target.value)} style={{ height: 130 }} />


                <div className='pt-4 d-flex justify-content-end'>
                    <button className='btn btn-primary fw-500 medium' onClick={handleSubmit}>Update</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default EditItemModal