import React from 'react'
import StatusBadge from './StatusBadge'
import { format } from 'date-fns';
import { truncateString } from '../../common/utils/format_utils';
import profileImagePlaceholder from '../../common/assets/img/profile.svg'

function Subtasks({ tasks, setTasks, selectedTask, setSelectedTask }) {

    if (!selectedTask?.all_subtasks?.length) {
        return null;
    }

    const handleOpenSubtask = (subtask) => {
        setSelectedTask(subtask);
    }

    const formatDate = (date) => {
        return format(new Date(date), 'dd/M/yyyy');
    };

    return (
        <div className='m-auto d-block w-100' style={{ maxWidth: 1500, overflowX: 'auto' }}>
            <div className='table-responsive pt-3'>
                <table className='table table-bordered table-hover'>
                    <thead>
                        <tr>
                            <th className='medium color-text-lighter w-25'>Subject</th>
                            <th className='medium color-text-lighter w-20'>Description</th>
                            <th className='medium color-text-lighter w-10'>Due Date</th>
                            <th className='medium color-text-lighter w-15'>Waiting On</th>
                            <th className='medium color-text-lighter w-10'>Assignees</th>
                            <th className='medium color-text-lighter w-10'>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {selectedTask?.all_subtasks?.map(subtask => (
                            <tr key={subtask.id} onClick={() => { handleOpenSubtask(subtask) }} className='pointer'>
                                <td className='medium color-text-lighter w-25'>
                                    {subtask.subject}
                                </td>
                                <td className='small color-text-lighter w-20'>{truncateString(subtask?.description, 28)}</td>
                                <td className='medium color-text-lighter w-10'>
                                    {formatDate(subtask.due_date)}
                                </td>
                                <td className='medium color-text-lighter w-15'>
                                    {subtask.waiting_on}
                                </td>
                                <td className='medium color-text-lighter w-10'>
                                    <div className='d-flex flex-row'>
                                        {subtask?.assignees?.map(user => (
                                            <div className='px-1'>
                                                <img src={user?.person?.avatar ?? profileImagePlaceholder} className='rounded-circle' alt="" style={{ height: 28, width: 28, objectFit: 'cover' }} />
                                            </div>
                                        ))}
                                    </div>
                                </td>
                                <td className='medium color-text-lighter w-10'>
                                    <StatusBadge status={subtask.status} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Subtasks