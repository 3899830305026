import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getWorkerGroup, getGroupWorkers } from '../api/worker';
import WorkerCard from '../components/workerCard/WorkerCard';
import AddWorkerModal from '../components/AddWorkerModal';
import GroupHeader from '../components/groupHeader/GroupHeader';
import EditWorkerModal from '../components/EditWorkerModal';
import DeleteWorkerModal from '../components/DeleteWorkerModal';
import { Plus } from 'react-bootstrap-icons';

function WorkerGroup() {

  const { group_id } = useParams();
  const [workerGroup, setWorkerGroup] = useState([]);
  const [workers, setWorkers] = useState([]);
  const [showAddWorkerModal, setShowAddWorkerModal] = useState(false);
  const [showEditWorkerModal, setShowEditWorkerModal] = useState(false);
  const [showDeleteWorkerModal, setShowDeleteWorkerModal] = useState(false);
  const [selectedWorker, setSelectedWorker] = useState([]);

  useEffect(() => {
    const fetchWorkerGroup = async () => {
      try {
        const fetchedWorkerGroup = await getWorkerGroup(group_id);
        setWorkerGroup(fetchedWorkerGroup);
      } catch (error) {
        console.error('Error fetching :', error);
      }
    };

    const fetchWorkers = async () => {
      try {
        const fetchedWorkers = await getGroupWorkers(group_id);
        setWorkers(fetchedWorkers);
      } catch (error) {
        console.error('Error fetching :', error);
      }
    };

    fetchWorkerGroup();
    fetchWorkers();
  }, []);

  const openAddWorkerModal = () => {
    setShowAddWorkerModal(true);
  };

  const openEditWorkerModal = (worker) => {
    setShowEditWorkerModal(true);
    setSelectedWorker(worker);
  }

  const openDeleteWorkerModal = (workerGroup) => {
    setShowDeleteWorkerModal(true);
    setSelectedWorker(workerGroup);
  }

  return (
    <div>

      <GroupHeader workerGroup={workerGroup} />

      <button onClick={() => { openAddWorkerModal() }} className='btn btn-dark right-bottom-button rounded px-3 py-2 shadow-lg'>
        <Plus className='h4 m-0' />
      </button>

      <div className='container-fluid pt-3'>
        <div className='row px-3'>

          {workers.map(worker => (
            <div key={worker.id} className='col-md-4 mb-3'>
              <WorkerCard worker={worker}
                openEditWorkerModal={openEditWorkerModal}
                openDeleteWorkerModal={openDeleteWorkerModal}
              />
            </div>
          ))}

        </div>
      </div>

      <AddWorkerModal
        workers={workers}
        setWorkers={setWorkers}
        showAddWorkerModal={showAddWorkerModal}
        setShowAddWorkerModal={setShowAddWorkerModal}
        group_id={group_id}
      />

      <EditWorkerModal
        workers={workers}
        setWorkers={setWorkers}
        selectedWorker={selectedWorker}
        setSelectedWorker={setSelectedWorker}
        showEditWorkerModal={showEditWorkerModal}
        setShowEditWorkerModal={setShowEditWorkerModal}
      />

      <DeleteWorkerModal
        workers={workers}
        setWorkers={setWorkers}
        showDeleteWorkerModal={showDeleteWorkerModal}
        setShowDeleteWorkerModal={setShowDeleteWorkerModal}
        selectedWorker={selectedWorker}
      />

    </div>
  )
}

export default WorkerGroup