import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getBusinesses } from '../../api/business';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import BusinessCard from '../../../common/components/BusinessCard';
import { Plus } from 'react-bootstrap-icons';

function Businesses() {

    const [businesses, setBusinesses] = useState([]);

    useEffect(() => {
        const fetchBusinesses = async () => {

            try {
                const fetchedBusinesses = await getBusinesses();
                setBusinesses(fetchedBusinesses);
                console.log(fetchedBusinesses);
            } catch (error) {
                console.error('Error fetching :', error);
            }
        };

        fetchBusinesses();

    }, []);

    return (
        <>

            <div className='d-flex justify-content-center pt-3'>
                <Link to="/businesses/create" className='btn btn-basic bg-white shadow-sm medium'><span className='txt-primary'><Plus className='me-1' /> Add Business</span></Link>
            </div>

            <div className='container pt-5'>
                <h3 className='mb-4 ps-2'>Businesses</h3>
                <div className="row">
                    {businesses.map(business => (
                        <BusinessCard business={business} />
                    ))}

                </div>
            </div>
        </>

    )
}

export default Businesses