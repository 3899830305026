import React, { useState, useEffect } from 'react';
import { getItemGroups } from '../api/items';
import AddItemGroupModal from '../components/AddItemGroupModal';
import ItemGroupCard from '../components/itemGroupCard/ItemGroupCard';
import EditItemGroupModal from '../components/EditItemGroupModal';
import DeleteItemGroupModal from '../components/DeleteItemGroupModal';
import { Plus } from 'react-bootstrap-icons';
import { ReactComponent as EmptyBoxImg } from '../../common/assets/img/vectors/empty_box.svg'

function Items() {

    const [itemGroups, setItemGroups] = useState([]);
    const [showAddItemGroupModal, setShowAddItemGroupModal] = useState(false);
    const [selectedItemGroup, setSelectedItemGroup] = useState([]);
    const [showEditItemGroupModal, setShowEditItemGroupModal] = useState(false);
    const [showDeleteItemGroupModal, setShowDeleteItemGroupModal] = useState(false);

    useEffect(() => {
        const fetchItemGroups = async () => {
            try {
                const fetchedItemGroups = await getItemGroups();
                setItemGroups(fetchedItemGroups);
            } catch (error) {
                console.error('Error fetching :', error);
            }
        };

        fetchItemGroups();
    }, []);

    const openAddItemGroupModal = () => {
        setShowAddItemGroupModal(true);
    };

    const openEditItemGroupModal = (itemGroup) => {
        setShowEditItemGroupModal(true);
        setSelectedItemGroup(itemGroup);
    }

    const openDeleteItemGroupModal = (itemGroup) => {
        setShowDeleteItemGroupModal(true);
        setSelectedItemGroup(itemGroup);
    }

    return (

        <div>

            <button onClick={() => { openAddItemGroupModal() }} className='btn btn-dark right-bottom-button rounded px-3 py-2 shadow-lg'>
                <Plus className='h4 m-0' />
            </button>

            {itemGroups.length === 0 ? (
                <div className='d-flex flex-column align-items-center justify-content-center' style={{ height: '100vh' }}>
                    <EmptyBoxImg />
                    <p className='text-center text-muted mt-3'>You didn't add any items to your inventory yet!</p>
                </div>
            ) : (
                <div className='container-fluid' style={{ paddingTop: 77 }}>
                    <div className='row px-3'>

                        {itemGroups.map(itemGroup => (
                            <div key={itemGroup.id} className='col-md-4 mb-3'>
                                <ItemGroupCard itemGroup={itemGroup} openEditItemGroupModal={openEditItemGroupModal} openDeleteItemGroupModal={openDeleteItemGroupModal} />
                            </div>
                        ))}

                    </div>
                </div>
            )}

            <AddItemGroupModal
                itemGroups={itemGroups}
                setItemGroups={setItemGroups}
                showAddItemGroupModal={showAddItemGroupModal}
                setShowAddItemGroupModal={setShowAddItemGroupModal}
            />

            <EditItemGroupModal
                itemGroups={itemGroups}
                setItemGroups={setItemGroups}
                selectedItemGroup={selectedItemGroup}
                setSelectedItemGroup={setSelectedItemGroup}
                showEditItemGroupModal={showEditItemGroupModal}
                setShowEditItemGroupModal={setShowEditItemGroupModal}
            />

            <DeleteItemGroupModal
                itemGroups={itemGroups}
                setItemGroups={setItemGroups}
                showDeleteItemGroupModal={showDeleteItemGroupModal}
                setShowDeleteItemGroupModal={setShowDeleteItemGroupModal}
                selectedItemGroup={selectedItemGroup}
            />

        </div >
    )
}

export default Items;
