import { apiUrl } from "../../common/api/config";

export const saveBusiness = async (name, description) => {

    try {

        const token = localStorage.getItem('token');

        const data = {
            "name": name,
            "description": description
        }

        const response = await fetch(apiUrl + '/businesses', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}
