import React from 'react';
import { Modal } from 'react-bootstrap';
import { deleteRevenue } from '../api/revenues';
import { toast } from 'react-toastify';

function DeleteRevenueModal({ revenues, setRevenues, showDeleteRevenueModal, setShowDeleteRevenueModal, selectedRevenue }) {

    const handleCloseModal = () => {
        setShowDeleteRevenueModal(false);
    }

    const handleDeleteRevenue = async (event) => {
        event.preventDefault();
        try {
            await deleteRevenue(selectedRevenue.id);
            const updatedRevenues = revenues.filter(revenue => revenue.id !== selectedRevenue.id);
            setRevenues(updatedRevenues);
            toast.success("Successfully removed a revenue!")
        } catch (error) {
            toast.error(error.message);
        }
        handleCloseModal()
    };

    return (
        <Modal show={showDeleteRevenueModal}
            onHide={handleCloseModal}
            backdrop="static"
            keyboard={true}
            size='md'
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='bold h5'>Delete revenue</Modal.Title>
            </Modal.Header>

            <Modal.Body className='pt-4'>


                <p className='color-text opacity-80 medium'>
                    Are you sure you want to delete this revenue? After you delete this revenue, this action cannot be undone.
                </p>
                <div className='modal-footer border-0 pb-2'>
                    <button className='btn btn-danger rounded' onClick={(event) => { handleDeleteRevenue(event) }}>Delete</button>
                </div>
            </Modal.Body>

        </Modal>

    );
}

export default DeleteRevenueModal;
