import { apiUrl } from '../../common/api/config';

export const getClients = async () => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/clients/', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}

export const getClientGroups = async () => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/clients/groups', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}

export const getClientGroup = async (group_id) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/clients/groups/' + group_id, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}

export const getGroupClients = async (group_id) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/clients/groups/' + group_id + '/clients', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}

export const saveClientGroup = async (clientGroup) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/clients/groups', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(clientGroup)
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}

export const saveClient = async (client) => {
    try {
        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/clients', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token
            },
            body: client
        });

        const responseData = await response.json();

        if (!response.ok) {
            throw new Error(responseData.message || 'Failed to save client');
        }

        return responseData;

    } catch (error) {
        throw error;
    }
};



export const updateClient = async (client, client_id) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/clients/' + client_id, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token
            },
            body: client
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}

export const updateClientGroup = async (clientGroup) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/clients/groups/' + clientGroup.id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(clientGroup)
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}

export const deleteClient = async (client_id) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/clients/' + client_id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}

export const deleteClientGroup = async (client_group_id) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/clients/groups/' + client_group_id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}