import React from 'react'
import styles from './ProductHeader.module.css'

function ProductHeader({ product }) {
    return (
        <div className={styles.ProductHeader}>

            <div>
                <h3>{product.name}</h3>
                <p>{product.description}</p>
            </div>

        </div>
    )
}

export default ProductHeader