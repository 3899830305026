import ItemGroups from './pages/ItemGroups';
import ItemGroup from './pages/ItemGroup';

const itemRoutes = [
  {
    path: 'items/groups',
    element: <ItemGroups />,
  },
  {
    path: 'items/groups/:group_id',
    element: <ItemGroup />,
  },
];

export default itemRoutes;