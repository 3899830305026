import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { updateEventType } from '../api/event_types';

function EditEventTypeModal({ showEditEventTypeModal, setShowEditEventTypeModal, eventTypes, setEventTypes, selectedEventType, setSelectedEventType }) {

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        setName(selectedEventType.name);
        setDescription(selectedEventType.description);
    }, [selectedEventType]);

    const handleSubmit = async () => {
        const eventTypeObject = {
            "id": selectedEventType.id,
            "name": name,
            "description": description,
        };

        try {
            
            const updatedEventTypes = eventTypes.map(eventType =>
                eventType.id === selectedEventType.id ? eventTypeObject : eventType
            );
            setEventTypes(updatedEventTypes);

            setShowEditEventTypeModal(false);

            setName('');
            setDescription('');

            toast.success("Successfully edited an event type!");

            await updateEventType(eventTypeObject);

        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleCloseModal = () => {
        setShowEditEventTypeModal(false);
    };

    return (
        <Modal
            show={showEditEventTypeModal} onHide={handleCloseModal}
            backdrop="static" keyboard={false} size='lg'
            aria-labelledby="contained-modal-name-vcenter"
            centered
        >
            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='bold h5'>Edit event type</Modal.Title>
            </Modal.Header>
            <Modal.Body className='py-4'>
                <label className='pb-2'>Name</label>
                <input type="text" className='form-control medium bg-gray-light py-2' placeholder='Event type name' value={name} autoComplete="new-password" onChange={(e) => setName(e.target.value)} />

                <label className='mt-4 mb-2'>Description</label>
                <textarea type="text" className='form-control bg-gray-light py-2 medium' placeholder='Description' value={description} autoComplete="new-password" onChange={(e) => setDescription(e.target.value)} style={{ height: 130 }} />

                <div className='pt-4 d-flex justify-content-end'>
                    <button className='btn btn-primary fw-500 medium' onClick={handleSubmit}>Update</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default EditEventTypeModal