import { apiUrl } from '../../common/api/config';

export const storeComment = async (text, task_id, files) => {
    const token = localStorage.getItem('token');
  
    try {
      const formData = new FormData();
      formData.append('text', text);
  
      // Append files to formData if any
      if (files && files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          formData.append('files[]', files[i]);
        }
      }
  
      const response = await fetch(apiUrl + '/tasks/' + task_id + '/comments', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + token
        },
        body: formData
      });
  
      const responseData = await response.json();
  
      if (response.ok) {
        return responseData;
      } else {
        throw new Error(responseData.errors);
      }
  
    } catch (error) {
      throw new Error(error.message);
    }
  }
  

export const deleteComment = async (task_id, comment_id) => {

    const token = localStorage.getItem('token');

    try {

        const response = await fetch(apiUrl + '/tasks/' + task_id + '/comments/' + comment_id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }

    } catch (error) {
        return error;
    }

}