import React, { useState } from 'react'
import { saveBusiness } from '../api/business';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as FolderSVG } from '../../common/assets/img/vectors/folder.svg'

function CreateNewBusiness() {

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    const navigate = useNavigate();

    const handleCreateBusinessSubmit = async () => {
        try {
            await saveBusiness(name, description);
            navigate("/");
            toast.success("Successfully added a new business!");
        } catch (error) {
            toast.error(error.message);
        }
    };

    return (
        <div id='create-business-page-wrapper'>

            <div className='container w-100 h-100 d-flex flex-column' style={{ minHeight: '80vh', maxWidth: 850 }}>

                <div className='px-5 pt-3 w-100'>
                    <FolderSVG />

                    <h3 className="bold mt-4 mb-2">
                        Add Business
                    </h3>
                    <span className='text-muted medium'>Empower your businesses with ease. Streamline creation and management effortlessly.</span>
                    <br />
                    <label className='pb-2 mt-5'>Name</label>
                    <input type="text" className='form-control medium bg-gray-light py-3'
                        placeholder='Business name' value={name} autoComplete="new-password"
                        onChange={(e) => setName(e.target.value)} />

                    <label className='mt-4 mb-2'>Description</label>
                    <textarea type="text" className='form-control bg-gray-light py-2 medium'
                        placeholder='Business description' value={description} autoComplete="new-password"
                        onChange={(e) => setDescription(e.target.value)} style={{ height: 130 }} />

                    <div className='pt-4'>
                        <button className='btn btn-primary fw-500' onClick={handleCreateBusinessSubmit}>Add Business</button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default CreateNewBusiness