import React from 'react'
import { ShieldFillCheck } from 'react-bootstrap-icons';

function EventCreationSuccess() {

    const formatDate = (date) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };

    const today = new Date();

    return (
        <div style={{ minHeight: '90vh' }} className='container w-100 d-flex align-items-center justify-content-center bg-white'>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12s col-lg-12s m-auto">
                    <div style={{ maxWidth: 650 }} className='m-auto d-block'>
                        <div class="card-body text-center">
                            <p class="card-icon">
                                <ShieldFillCheck className='h1 text-success' />
                            </p>
                            <h2 class="bold mb-3">Event successfully booked!</h2>
                            <p class="card-text mb-1">
                                Your event was successfully saved and added into your personal calendar. You will also receive email notifications
                                to remind you of this event. Make sure to show up on time!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EventCreationSuccess