import React, { useState, useEffect, useRef } from 'react';
import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';
import profileImagePlaceholder from '../../../common/assets/img/profile.svg';
import placeholderProfileImage1 from '../../../common/assets/img/placeholder-profile-img-1.jpeg';
import placeholderProfileImage2 from '../../../common/assets/img/placeholder-profile-img-2.jpg';
import { PencilSquare, ThreeDotsVertical, Trash, TrashFill } from 'react-bootstrap-icons';

function WorkerGroupCard({ workerGroup, openEditWorkerGroupModal, openDeleteWorkerGroupModal }) {

    const navigate = useNavigate();
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownVisible(false);
        }
    };

    useEffect(() => {
        if (dropdownVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownVisible]);
    

    return (
        <Card className='pointer h-100' onClick={() => { navigate('/workers/groups/' + workerGroup.id) }}>
            <div className='d-flex justify-content-between'>
                <h5 className='mb-1'>{workerGroup.name}</h5>
                <div className='btn px-2 py-1' onClick={(e) => {e.stopPropagation(); toggleDropdown()}} ref={dropdownRef}>
                    <ThreeDotsVertical className='pointer' />
                    {dropdownVisible && (
                        <div className='dropdown-menu border-0 shadow-sm show'>
                            <li className='dropdown-item' onClick={(e) => openEditWorkerGroupModal(workerGroup)}>
                                <PencilSquare className='me-2' /> Edit
                            </li>
                            <li className='dropdown-item text-danger' onClick={(e) => openDeleteWorkerGroupModal(workerGroup)}>
                                <TrashFill className='me-2' /> Delete
                            </li>
                        </div>
                    )}
                </div>
            </div>

            <p>{workerGroup.description}</p>

            <div>
                <img src={profileImagePlaceholder} className='rounded-circle header-member-pic' alt="" />
                <img src={placeholderProfileImage1} className='rounded-circle header-member-pic' alt="" />
                <img src={placeholderProfileImage2} className='rounded-circle header-member-pic' alt="" />
                <img src={placeholderProfileImage2} className='rounded-circle header-member-pic' alt="" />
                <span className='p-2'>+2</span>
            </div>

            <div className="progress my-3">
                <div className="progress-bar progress-bar-striped progress-bar-animated bgc-success w-50" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        </Card>
    );
}

export default WorkerGroupCard;
