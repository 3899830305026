import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getProduct, getProductModules } from '../api/products';
import ProductModuleCard from '../components/productModuleCard/ProductModuleCard';
import AddProductModuleModal from '../components/AddProductModuleModal';
import ProductHeader from '../components/productHeader/ProductHeader';
import EditProductModuleModal from '../components/EditProductModuleModal';
import DeleteProductModuleModal from '../components/DeleteProductModuleModal';
import { Plus } from 'react-bootstrap-icons';

function Product() {

  const { product_id } = useParams();
  const [product, setProduct] = useState([]);
  const [productModules, setProductModules] = useState([]);
  const [showAddProductModuleModal, setShowAddProductModuleModal] = useState(false);
  const [showEditProductModuleModal, setShowEditProductModuleModal] = useState(false);
  const [showDeleteProductModuleModal, setShowDeleteProductModuleModal] = useState(false);
  const [selectedProductModule, setSelectedProductModule] = useState([]);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const fetchedProduct = await getProduct(product_id);
        setProduct(fetchedProduct);
      } catch (error) {
        console.error('Error fetching :', error);
      }
    };

    const fetchProductModules = async () => {
      try {
        const fetchedProductModules = await getProductModules(product_id);
        setProductModules(fetchedProductModules);
      } catch (error) {
        console.error('Error fetching :', error);
      }
    };

    fetchProduct();
    fetchProductModules();
  }, []);

  const openAddProductModuleModal = () => {
    setShowAddProductModuleModal(true);
  };

  const openEditProductModuleModal = (productModule) => {
    setShowEditProductModuleModal(true);
    setSelectedProductModule(productModule);
  }

  const openDeleteProductModuleModal = (product) => {
    setShowDeleteProductModuleModal(true);
    setSelectedProductModule(product);
  }

  return (
    <div>

      <ProductHeader product={product} />

      <button onClick={() => { openAddProductModuleModal() }} className='btn btn-dark right-bottom-button rounded px-3 py-2 shadow-lg'>
        <Plus className='h4 m-0' />
      </button>

      <div className='container-fluid pt-3'>
        <div className='row px-3'>

          {productModules.map(productModule => (
            <div key={productModule.id} className='col-md-4 mb-3'>
              <ProductModuleCard
                product_id={product_id}
                productModule={productModule}
                openEditProductModuleModal={openEditProductModuleModal}
                openDeleteProductModuleModal={openDeleteProductModuleModal}
              />
            </div>
          ))}

        </div>
      </div>

      <AddProductModuleModal
        productModules={productModules}
        setProductModules={setProductModules}
        showAddProductModuleModal={showAddProductModuleModal}
        setShowAddProductModuleModal={setShowAddProductModuleModal}
        product_id={product_id}
      />

      <EditProductModuleModal
        product_id={product_id}
        productModules={productModules}
        setProductModules={setProductModules}
        selectedProductModule={selectedProductModule}
        setSelectedProductModule={setSelectedProductModule}
        showEditProductModuleModal={showEditProductModuleModal}
        setShowEditProductModuleModal={setShowEditProductModuleModal}
      />

      <DeleteProductModuleModal
        productModules={productModules}
        setProductModules={setProductModules}
        showDeleteProductModuleModal={showDeleteProductModuleModal}
        setShowDeleteProductModuleModal={setShowDeleteProductModuleModal}
        selectedProductModule={selectedProductModule}
      />

    </div>
  )
}

export default Product