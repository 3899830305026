import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import { updateRevenueGroup } from '../api/revenues';
import { toast } from 'react-toastify';

function EditRevenueGroupModal({ showEditRevenueGroupModal, setShowEditRevenueGroupModal, revenueGroups, setRevenueGroups, selectedRevenueGroup, setSelectedRevenueGroup }) {

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        setName(selectedRevenueGroup.name);
        setDescription(selectedRevenueGroup.description);
    }, [selectedRevenueGroup]);

    const handleSubmit = async () => {
        const revenueGroupObject = {
            "id": selectedRevenueGroup.id,
            "name": name,
            "description": description,
        };

        const updatedRevenueGroup = await updateRevenueGroup(revenueGroupObject);

        try {
            
            const updatedRevenueGroups = revenueGroups.map(revenueGroup =>
                revenueGroup.id === selectedRevenueGroup.id ? updatedRevenueGroup : revenueGroup
            );
            setRevenueGroups(updatedRevenueGroups);

            setShowEditRevenueGroupModal(false);

            setName('');
            setDescription('');

            toast.success("Successfully edited a revenue group!");


        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleCloseModal = () => {
        setShowEditRevenueGroupModal(false);
    };

    return (
        <Modal
            show={showEditRevenueGroupModal} onHide={handleCloseModal}
            backdrop="static" keyboard={false} size='lg'
            aria-labelledby="contained-modal-name-vcenter"
            centered
        >
            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='bold h5'>Edit revenue group</Modal.Title>
            </Modal.Header>
            <Modal.Body className='py-4'>
                <label className='pb-2'>Name</label>
                <input type="text" className='form-control medium bg-gray-light py-2' placeholder='Revenue group name' value={name} autoComplete="new-password" onChange={(e) => setName(e.target.value)} />

                <label className='mt-4 mb-2'>Description</label>
                <textarea type="text" className='form-control bg-gray-light py-2 medium' rows={5} placeholder='Description' value={description} autoComplete="new-password" onChange={(e) => setDescription(e.target.value)} style={{ height: 130 }} />

                <div className='pt-4 d-flex justify-content-end'>
                    <button className='btn btn-primary fw-500 medium' onClick={handleSubmit}>Update</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default EditRevenueGroupModal