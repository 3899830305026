import React, { useState, useEffect, useRef } from 'react';
import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';
import fiverrIcon from '../../../common/assets/img/icons/fiverr.webp'
import styles from './ClientCard.module.css'
import { PencilSquare, ThreeDotsVertical, Trash, Trash2Fill, TrashFill } from 'react-bootstrap-icons';
import placeholderProfileImage from '../../../common/assets/img/profile.svg';

function ClientCard({ client, openEditClientModal, openDeleteClientModal }) {

    const navigate = useNavigate();
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    return (
        <Card className='h-100'>

            <div className='d-flex justify-content-between'>

                <div className={styles.clientAvatarWrapper}>
                    <img src={client?.person?.avatar ?? placeholderProfileImage} className={styles.clientAvatar} alt="" />
                </div>

                <div className='btn px-2 py-1' onClick={(e) => { e.stopPropagation(); toggleDropdown() }} ref={dropdownRef}>
                    <ThreeDotsVertical className='pointer' />
                    {dropdownVisible && (
                        <div className='dropdown-menu border-0 shadow-sm show'>
                            <li className='dropdown-item' onClick={(e) => openEditClientModal(client)}>
                                <PencilSquare className='me-2' /> Edit
                            </li>
                            <li className='dropdown-item text-danger' onClick={(e) => openDeleteClientModal(client)}>
                                <TrashFill className='me-2' /> Delete
                            </li>
                        </div>
                    )}
                </div>

            </div>

            <h5 className='fw-500'>{client?.person?.name}</h5>
            <span className='small text-muted mb-2'>{client?.person?.comments}</span>


            <div className={styles.tag}>
                <img src={fiverrIcon} className={styles.fiverrIcon} alt="" />
                <span className='small fw-500 ps-2'>Fiverr</span>
            </div>
        </Card>
    )
}

export default ClientCard