import React, { useState, useEffect } from 'react';
import styles from './DaysCalendar.module.css';
import { getDates } from '../../api/date';

function DaysCalendar({ selectedDay, setSelectedDay, selectedMonth, selectedYear, currentMonthName, eventType }) {
    const [dates, setDates] = useState([]);
    const [daysOfWeek, setDaysOfWeek] = useState([]);

    const defaultDaysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

    // Parse user's schedule
    let userSchedule = null;
    if (eventType?.user?.schedule?.days) {
        userSchedule = JSON.parse(eventType.user.schedule.days);
    }

    useEffect(() => {
        const fetchDates = async () => {
            try {
                const fetchedDates = await getDates(selectedMonth, selectedYear);
                setDates(fetchedDates);
                if (fetchedDates.length > 0) {
                    rearrangeDaysOfWeek(fetchedDates[0]?.dayOfWeek);
                }
            } catch (error) {
                console.error('Error fetching dates:', error);
            }
        };

        fetchDates();
    }, [selectedMonth, selectedYear]);

    const rearrangeDaysOfWeek = (firstDayOfWeek) => {
        const firstDayIndex = defaultDaysOfWeek.indexOf(firstDayOfWeek);
        const reorderedDays = [
            ...defaultDaysOfWeek.slice(firstDayIndex),
            ...defaultDaysOfWeek.slice(0, firstDayIndex)
        ];
        setDaysOfWeek(reorderedDays);
    };

    const handleDayClick = (day) => {
        if (isDayAvailable(day)) {
            setSelectedDay(day);
        }
    };

    const isDayAvailable = (day) => {
        const today = new Date();

        // Convert the day object to a valid Date object
        const dayDate = new Date(`${day.year}-${selectedMonth}-${day.date}`);

        // Check if the day has passed
        if (dayDate < today.setHours(0, 0, 0, 0)) {
            return false;
        }

        // Check if the day is available in the user's schedule
        const dayOfWeek = day.dayOfWeek;
        const daySchedule = userSchedule?.find(
            (schedule) => schedule?.day?.toLowerCase().startsWith(dayOfWeek.toLowerCase())
        );

        return daySchedule?.available;
    };

    const rows = [];
    let cells = [];

    dates.forEach((day, index) => {
        const isActive =
            day.date === selectedDay.date &&
            day.month === selectedDay.month &&
            day.year === selectedDay.year;

        const isAvailable = isDayAvailable(day);

        cells.push(
            <td className={styles.day} key={`${day.date}-${day.month}-${day.year}`}>
                <div
                    className={`${styles.dayContent} ${isActive ? styles.active : ''} ${!isAvailable ? styles.disabled : ''}`}
                    onClick={() => handleDayClick(day)}
                    style={{ pointerEvents: isAvailable ? 'auto' : 'none' }}
                >
                    {day.date}
                </div>
            </td>
        );

        if ((index + 1) % 7 === 0) {
            rows.push(<tr key={index}>{cells}</tr>);
            cells = [];
        }
    });

    if (cells.length > 0) {
        rows.push(<tr key={rows.length}>{cells}</tr>);
    }

    if (!dates.length) {
        return (
            <div className='d-flex justify-content-center align-items-center' style={{ height: 370 }}>
                <div className='spinner-border txt-primary' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <table className="table mb-0">
            <thead>
                <tr className={styles.daysOfWeek}>
                    {daysOfWeek.map((day) => (
                        <th key={day} className='fw-400'>{day}</th>
                    ))}
                </tr>
            </thead>
            <tbody>{rows}</tbody>
        </table>
    );
}

export default DaysCalendar;
