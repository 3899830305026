import React from 'react';
import { Modal } from 'react-bootstrap';
import { deleteClient } from '../api/clients';
import { toast } from 'react-toastify';

function DeleteClientModal({ clients, setClients, showDeleteClientModal, setShowDeleteClientModal, selectedClient }) {

    const handleCloseModal = () => {
        setShowDeleteClientModal(false);
    }

    const handleDeleteClient = async (event) => {
        event.preventDefault();
        try {
            await deleteClient(selectedClient.id);
            const updatedClients = clients.filter(client => client.id !== selectedClient.id);
            setClients(updatedClients);
            toast.success("Successfully removed a client!")
        } catch (error) {
            toast.error(error.message);
        }
        handleCloseModal()
    };

    return (
        <Modal show={showDeleteClientModal}
            onHide={handleCloseModal}
            backdrop="static"
            keyboard={true}
            size='md'
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='bold h5'>Delete client</Modal.Title>
            </Modal.Header>

            <Modal.Body className='pt-4'>


                <p className='color-text opacity-80 medium'>
                    Are you sure you want to delete this client? After you delete this client, this action cannot be undone.
                </p>
                <div className='modal-footer border-0 pb-2'>
                    <button className='btn btn-danger rounded' onClick={(event) => { handleDeleteClient(event) }}>Delete</button>
                </div>
            </Modal.Body>

        </Modal>

    );
}

export default DeleteClientModal;
