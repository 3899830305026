import EventCreationSuccess from './pages/EventCreationSuccess';
import ScheduleEvent from './pages/ScheduleEvent';

const scheduleEventRoutes = [
  {
    path: ':email/:id',
    element: <ScheduleEvent />,
  },
  {
    path: 'event_types/new/success',
    element: <EventCreationSuccess />,
  },
];

export default scheduleEventRoutes;