import React from 'react'

function NewNotifications({ userInfo }) {
  return (
    <div className="bg-white rounded p-3 shadow-sm">
      <h6 className="mb-3">New Notifications</h6>

      <p className='py-5 text-center'>No new notifications</p>

    </div>
  );
}

export default NewNotifications