import React, { useState, useEffect, useRef } from 'react'
import placeholderProfileImage from '../../common/assets/img/profile.svg'
import { getUserInfo } from '../../common/api/user';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { updateProfileImage } from '../api/account';
import { Camera } from 'react-bootstrap-icons';
import { formatDate } from '../../common/utils/format_utils';

function Account() {

    const [userInfo, setUserInfo] = useState([]);
    const inputRef = useRef(null);

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const fetchedUserInfo = await getUserInfo();
                setUserInfo(fetchedUserInfo);
            } catch (error) {
                console.error('Error fetching :', error);
            }
        };

        fetchUserInfo();
    }, []);


    const handleFileChange = async (event) => {
        const response = await updateProfileImage(event.target.files[0]);
        if (!response) return;
        setUserInfo((prev) => ({ ...prev, avatar: response.avatar }));
    };

    return (
        <div className='container py-5'>


            <div
                style={{ maxWidth: 85 }}
                className="mb-3 position-relative profile-image"
            >
                <img
                    src={userInfo?.avatar ?? placeholderProfileImage}
                    className="img-fluid rounded hover"
                    style={{ aspectRatio: 1, objectFit: "cover" }}
                    alt=""
                />
                <input
                    ref={inputRef}
                    onChange={handleFileChange}
                    type="file"
                    style={{ height: 0, width: 0 }}
                    className="invisible position-absolute"
                />
                <button
                    className="project-image-overlay"
                    onClick={() => inputRef?.current?.click()}
                >
                    <Camera className="text-white" />
                </button>
            </div>

            <p className="text-muted">Name: {userInfo.name}</p>
            <p className="text-muted">Email: {userInfo.email}</p>
            <p className="text-muted">
                Profile Created At: {formatDate(userInfo.created_at)}
            </p>
            <p className="text-muted">
                Email Verified:{" "}
                <span className="badge bagde-primary bg-danger">No</span>
            </p>
            <p className="text-muted m-0">
                Role:{" "}
                <span className="badge bagde-primary bgc-success">
                    {userInfo.role ? userInfo.role.name : ""}
                </span>
            </p>

        </div>
    )
}

export default Account