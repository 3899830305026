import React, { useState, useEffect, useRef } from 'react';
import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';
import fiverrIcon from '../../../common/assets/img/icons/fiverr.webp'
import styles from './ItemCard.module.css'
import { PencilSquare, ThreeDotsVertical, Trash, Trash2Fill, TrashFill } from 'react-bootstrap-icons';

function ItemCard({ item, openEditItemModal, openDeleteItemModal }) {

    const navigate = useNavigate();
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    return (
        <Card className='h-100'>

            <div className='d-flex justify-content-between'>
                <h5 className='fw-500'>{item.name}</h5>

                <div className='btn px-2 py-1' onClick={(e) => { e.stopPropagation(); toggleDropdown() }} ref={dropdownRef}>
                    <ThreeDotsVertical className='pointer' />
                    {dropdownVisible && (
                        <div className='dropdown-menu border-0 shadow-sm show'>
                            <li className='dropdown-item' onClick={(e) => openEditItemModal(item)}>
                                <PencilSquare className='me-2' /> Edit
                            </li>
                            <li className='dropdown-item text-danger' onClick={(e) => openDeleteItemModal(item)}>
                                <TrashFill className='me-2' /> Delete
                            </li>
                        </div>
                    )}
                </div>

            </div>

            <p className='text-muted'>{item.description}</p>
            <p className='bold m-0 medium'>Type: {item.type}</p>

           
        </Card>
    )
}

export default ItemCard