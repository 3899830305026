import React from 'react'
import { Link } from 'react-router-dom'
import { BarChart, BoxArrowRight, Briefcase, Building, Person, PersonCircle, UiChecksGrid } from 'react-bootstrap-icons'
import placeholderProfileImage from '../../assets/img/profile.svg';

function UserDropdown({ userInfo, setUserInfo }) {
    return (
        <div>
            <div className="dropdown-menu dropdown-menu-left show active" aria-labelledby="accountDropdown"
                style={{ position: 'absolute', width: 250 }}>

                <div className='d-flex align-items-center pt-1'>
                    <div className='px-2'>
                        <div className='position-relative'>
                            <img src={userInfo?.avatar ?? placeholderProfileImage} className='rounded-circle' style={{ width: 30, height: 30, objectFit: 'cover' }} alt="" />
                            <span className='status-badge'></span>
                        </div>
                    </div>
                    <div className='d-flex flex-column ps-2'>
                        <span className='medium fw-500'>{userInfo?.name}</span>
                        <span className='small text-muted'>Online</span>
                    </div>

                </div>

                <hr />

                <li className='nav-item px-2 rounded'>
                    <Link to="/admin/dashboard" className='nav-link'>
                        <UiChecksGrid />
                        <span className='ps-3 medium'>Admin Panel</span>
                    </Link>
                </li>
                <li className='nav-item px-2 rounded'>
                    <Link to="/my-businesses" className='nav-link'>
                        <Briefcase />
                        <span className='ps-3 medium'>My Businesses</span>
                    </Link>
                </li>
                <li className='nav-item px-2 rounded'>
                    <Link to="/account" className='nav-link'>
                        <PersonCircle />
                        <span className='ps-3 medium'>My Account</span>
                    </Link>
                </li>

                <hr className='mb-2' />

                <li className='nav-item px-2 rounded'>
                    <Link to="/logout" className='nav-link'>
                        <BoxArrowRight className='text-danger' />
                        <span className='ps-3 medium text-danger'>Logout</span>
                    </Link>
                </li>

            </div>
        </div>
    )
}

export default UserDropdown