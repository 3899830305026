import Notifications from './pages/Notifications';
import Notification from './pages/Notification';

const notificationRoutes = [
  {
    path: 'notifications',
    element: <Notifications />,
  },
  {
    path: 'notifications/:notification_id',
    element: <Notification />,
  },
];

export default notificationRoutes;