import React, { useEffect, useState } from 'react'
import { getUsers } from '../../admin_panel/api/user';
import { addBusinessMember } from '../api/team';
import { toast } from 'react-toastify';
import { Plus } from 'react-bootstrap-icons';

function AddMemberModal({ showAddMemberModal, setShowAddMemberModal, businessMembers, setBusinessMembers }) {

    const [users, setUsers] = useState([])

    useEffect(() => {
        const fetchContacts = async () => {
            try {
                const fetchedUsers = await getUsers();
                setUsers(fetchedUsers);
            } catch (error) {
                console.error('Error fetching :', error);
            }
        };

        fetchContacts();
    }, []);

    const handleCloseAddMemberModal = () => {
        setShowAddMemberModal(false);
    };

    const handleAddMember = async (user) => {
        try {
            const newMember = await addBusinessMember(user.id);
            const updatedMembers = [newMember, ...businessMembers];
            setBusinessMembers(updatedMembers);
            setShowAddMemberModal(false);
            toast.success('Successfully added a new business member!');
        } catch (error) {
            toast.error('Error while trying to add a member!');
        }
    }

    return (
        <>
            <div className={`modal fade ${showAddMemberModal ? 'show d-block' : ''}`} tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: 800, padding: '1.7rem' }}>
                    <div className="modal-content py-3 px-4 border-0 shadow-lg">
                        <div className="modal-header pb-0 border-0 d-flex align-items-center">
                            <div>
                                <h4 className="modal-title bold m-0">Add user</h4>
                            </div>
                            <span type="button" className="close ms-auto m-0 text-secondary" onClick={handleCloseAddMemberModal} style={{ fontSize: '25pt', fontWeight: '300' }}>
                                <span aria-hidden="true">&times;</span>
                            </span>
                        </div>
                        <div className='modal-body' style={{ maxHeight: 520, overflow: 'auto' }}>
                            <div className='m-auto d-block w-100' style={{ maxWidth: 1500, overflowX: 'auto' }}>
                                <div className='table-responsive'>
                                    <table className='table table-hover table-bordered'>
                                        <tbody>
                                            {users.map(user => (
                                                <tr key={user.id}>
                                                    <td className='medium'>{user.name}</td>
                                                    <td className='medium'>{user.email}</td>
                                                    <td className='text-center'>
                                                        <button className='btn btn-basic medium bg-gray shadow-sm' onClick={() => { handleAddMember(user) }}>
                                                            <Plus />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddMemberModal