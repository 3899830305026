import React, { useState, useEffect } from 'react';
import styles from './Calendar.module.css';
import CalendarIllustration from '../../../common/assets/img/illustrations/calendar.png';
import { getDates } from '../../api/date';

function Calendar({ events, selectedMonth, selectedYear, currentMonthName, setShowModal, setSelectedEvent }) {
    const [dates, setDates] = useState([]);
    const [daysOfWeek, setDaysOfWeek] = useState([]);

    const defaultDaysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

    const [selectedDay, setSelectedDay] = useState({
        date: String(new Date().getDate()),
        month: currentMonthName,
        year: String(selectedYear),
        dayOfWeek: defaultDaysOfWeek[new Date().getDay()]
    });

    console.log(selectedDay);

    useEffect(() => {
        const fetchDates = async () => {
            try {
                const fetchedDates = await getDates(selectedMonth, selectedYear);
                setDates(fetchedDates);
                if (fetchedDates.length > 0) {
                    rearrangeDaysOfWeek(fetchedDates[0]?.dayOfWeek);
                }
            } catch (error) {
                console.error('Error fetching dates:', error);
            }
        };

        fetchDates();
    }, [selectedMonth, selectedYear]);

    const rearrangeDaysOfWeek = (firstDayOfWeek) => {
        const firstDayIndex = defaultDaysOfWeek.indexOf(firstDayOfWeek);
        const reorderedDays = [
            ...defaultDaysOfWeek.slice(firstDayIndex),
            ...defaultDaysOfWeek.slice(0, firstDayIndex)
        ];
        setDaysOfWeek(reorderedDays);
    };

    const handleDayClick = (day) => {
        setSelectedDay(day);
    };

    const getEventsForSelectedDay = () => {
        return events?.filter(event => {
            const eventStartDate = new Date(event.start_time);
            const eventEndDate = new Date(event.end_time);
            
            const selectedDate = new Date(`${selectedDay.year}-${selectedDay.month}-${selectedDay.date}`);
            
            selectedDate.setHours(0, 0, 0, 0);
            eventStartDate.setHours(0, 0, 0, 0);
            eventEndDate.setHours(0, 0, 0, 0);
    
            return selectedDate >= eventStartDate && selectedDate <= eventEndDate;
        });
    };

    const getEventCountForDay = (day) => {
        return events?.filter(event => {
            const eventStartDate = new Date(event.start_time);
            const eventEndDate = new Date(event.end_time);
            
            const currentDay = new Date(`${day.year}-${day.month}-${day.date}`);
            currentDay.setHours(0, 0, 0, 0);
            eventStartDate.setHours(0, 0, 0, 0);
            eventEndDate.setHours(0, 0, 0, 0);
    
            return currentDay >= eventStartDate && currentDay <= eventEndDate;
        }).length;
    };    
    

    const eventsForSelectedDay = getEventsForSelectedDay();

    const rows = [];
    let cells = [];

    dates.forEach((day, index) => {
        const isActive =
            day.date === selectedDay.date &&
            day.month === selectedDay.month &&
            day.year === selectedDay.year;
    
        const eventCount = getEventCountForDay(day);
    
        cells.push(
            <td className={styles.day} key={`${day.date}-${day.month}-${day.year}`}>
                <div
                    className={`${styles.dayContent} ${isActive ? styles.active : ''}`}
                    onClick={() => handleDayClick(day)}
                >
                    {day.date}
                    {eventCount > 0 && (
                        <span className={styles.eventCount}>
                            {eventCount}
                        </span>
                    )}
                </div>
            </td>
        );
    
        if ((index + 1) % 7 === 0) {
            rows.push(<tr key={index}>{cells}</tr>);
            cells = [];
        }
    });
    
    if (cells.length > 0) {
        rows.push(<tr key={rows.length}>{cells}</tr>);
    }

    const formatTime = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const openEditEventModal = (event) => {
        setShowModal(true);
        setSelectedEvent(event);
    }

    if (!dates.length) {
        return (
            <div className='d-flex justify-content-center align-items-center' style={{ height: '70vh' }}>
                <div className='spinner-border txt-primary' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <div className="row">
            <div className="col-md-8">
                <table className="table table-bordered mb-0">
                    <thead>
                        <tr>
                            {daysOfWeek.map((day) => (
                                <th key={day}>{day}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                </table>
            </div>
            <div className="col-md-4">
                <div className="card shadow-sm h-100" style={{overflow: 'auto', maxHeight: 541}}>
                    <h5 style={{ fontSize: '13.5pt' }}>
                        {selectedDay.dayOfWeek}, {selectedDay.month} {selectedDay.date}
                    </h5>

                    {eventsForSelectedDay.length > 0 ? (
                        <div className="d-flex flex-column mt-3">

                            {eventsForSelectedDay.map(event => (
                                <div key={event.id} className={styles.eventCard} onClick={() => {openEditEventModal(event)}}>
                                    <div className='d-flex flex-column'>
                                        <span className='medium fw-500'>{event.title}</span>
                                        <span className='text-muted small'>{event.description}</span>
                                    </div>

                                    <div className='ps-3 d-flex flex-column'>
                                        <span className='small'>{formatTime(event.start_time)}</span>
                                        <span className='small'>{formatTime(event.end_time)}</span>
                                    </div>

                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className='d-flex justify-content-center align-items-center flex-column h-100'>

                            <img src={CalendarIllustration} style={{ height: 75 }} />

                            <p className='fw-500 medium mt-3 mb-1'>Nothing planned for the day</p>
                            <span className='text-lighter medium'>Enjoy!</span>

                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Calendar;
