import React from 'react'
import { ArrowUpRight, ChatLeft, Download } from 'react-bootstrap-icons'
import AddComment from './AddComment'
import profileImagePlaceholder from '../../../../common/assets/img/profile.svg'
import { deleteComment } from '../../../api/comments'
import { formatDistanceToNow } from 'date-fns';
import styles from './Comments.module.css'

function Comments({ selectedTask, setSelectedTask, tasks, setTasks }) {

    const handleRemoveComment = async (comment_id) => {
        const updatedComments = selectedTask.comments.filter(comment => comment.id !== comment_id);

        const updatedTask = { ...selectedTask, comments: updatedComments };

        const updatedTasks = tasks.map(task =>
            task.id === selectedTask.id ? updatedTask : task
        );

        setSelectedTask(updatedTask)
        setTasks(updatedTasks)

        await deleteComment(selectedTask.id, comment_id)
    }

    return (
        <div>
            <p className='pt-3'>
                <ChatLeft className='me-2' />
                Comments
            </p>

            <AddComment
                selectedTask={selectedTask}
                setSelectedTask={setSelectedTask}
                tasks={tasks}
                setTasks={setTasks}
            />

            {selectedTask?.comments?.map(comment => (
                <div className='d-flex py-2'>
                    <div className='pe-2'>
                        <img src={comment?.user?.avatar ?? profileImagePlaceholder} loading='lazy' className='rounded-circle' alt="" style={{ height: 28, width: 28, objectFit: 'cover' }} />
                    </div>
                    <div className='d-flex flex-column w-100'>
                        <div className='d-flex align-items-center'>
                            <span className='small fw-500 pe-1'>{comment?.user?.name}</span>
                            <span className='extra-small text-muted'>
                                {formatDistanceToNow(new Date(comment.created_at), { addSuffix: true })}
                            </span>
                        </div>
                        <span className={styles.commentText}>{comment.text}</span>

                        {comment?.attachments?.map(attachment => (
                            <div
                                key={attachment.id}
                                className={styles.attachmentItem}
                            >
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='pointer' onClick={() => window.open(attachment.url, '_blank')}>
                                            <img src={attachment.url} className={styles.image} alt="" />
                                            <p className="fw-500 mb-0 medium">{attachment.name}</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        ))}


                        <div className='d-flex'>
                            <span className='small pe-2 pointer' onClick={() => { handleRemoveComment(comment.id) }}><u>Delete</u></span>
                        </div>
                    </div>

                </div>
            ))}

        </div>
    )
}

export default Comments