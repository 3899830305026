export const truncateString = (str, maxLength) => {
  if (str === null || str === undefined) {
    return '';
  }
  if (str?.length <= maxLength) {
    return str;
  }
  return str.slice(0, maxLength) + '...';
};


export const formatTimeSpent = (seconds) => {

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  // Format with leading zeros if needed
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;

}

export const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short',
  };
  return date.toLocaleDateString(undefined, options);
}