import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { saveItem } from '../api/items';
import { toast } from 'react-toastify';
import Button from '../../common/components/Button';

function AddItemModal({ group_id, showAddItemModal, setShowAddItemModal, items, setItems }) {

    const [name, setName] = useState('');
    const [type, setType] = useState("physical");
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async () => {

        setLoading(true);
        setError('');

        const item = {
            "name": name,
            "type": type,
            "description": description,
            "item_group_id": group_id
        };

        try {
            const newItem = await saveItem(item);
            setItems([newItem, ...items]);
            setShowAddItemModal(false);

            setName('');
            setDescription('');

            toast.success("Successfully added a new item!");

        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleCloseModal = () => {
        setShowAddItemModal(false);
    };

    const handleTypeChange = (event) => {
        setType(event.target.value);
    };

    return (
        <Modal
            show={showAddItemModal} onHide={handleCloseModal}
            backdrop="static" keyboard={false} size='lg'
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='bold h5'>Add item to inventory</Modal.Title>
            </Modal.Header>
            <Modal.Body className='py-4'>

                {error && <span className='text-danger small'>{error}</span>} { }

                <label className='pb-2'>Name</label>
                <input type="text" className='form-control medium bg-gray-light py-2' placeholder='Items name' value={name} autoComplete="new-password" onChange={(e) => setName(e.target.value)} />

                <label className='mt-4 mb-2'>Type</label>
                <select value={type} className='form-control bg-gray-light py-2 medium' onChange={handleTypeChange}>
                    <option value="physical">Physical</option>
                    <option value="digital">Digital</option>
                </select>

                <label className='mt-4 mb-2'>Description</label>
                <textarea type="text" className='form-control bg-gray-light py-2 medium' placeholder='Description' value={description} autoComplete="new-password" onChange={(e) => setDescription(e.target.value)} style={{ height: 130 }} />


                <div className='pt-4 d-flex justify-content-end'>
                    <Button variant="primary" size="sm" onClick={handleSubmit} loading={loading} >
                        Save
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AddItemModal