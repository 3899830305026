import React, { useState, useEffect } from 'react'
import { getBusinessInfo, getBusinessMembers } from '../../common/api/business'
import AddMemberModal from '../components/AddMemberModal'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { removeBusinessMember } from '../api/team'
import { toast } from 'react-toastify';
import { Trash, TrashFill } from 'react-bootstrap-icons'

function Team() {

    const [business, setBusiness] = useState([]);
    const [businessMembers, setBusinessMembers] = useState([]);

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    const [showAddMemberModal, setShowAddMemberModal] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {

                const fetchedBusinessInfo = await getBusinessInfo();
                setBusiness(fetchedBusinessInfo);

                setName(fetchedBusinessInfo.name);
                setDescription(fetchedBusinessInfo.description);

            } catch (error) {
                console.error('Error fetching :', error);
            }

            try {
                const fetchedBusinessMembers = await getBusinessMembers();
                setBusinessMembers(fetchedBusinessMembers);
            } catch (error) {
                console.error('Error fetching :', error);
            }

        };

        fetchData();
    }, []);

    const handleRemoveBusinessMember = async (user) => {
        try {
            await removeBusinessMember(user.id);
            toast.success('Successfully removed a business member!');
            setBusinessMembers((prevBusinessMembers) =>
                prevBusinessMembers.filter((member) => member?.user?.id !== user.id)
            );
        } catch (error) {
            toast.error('Error while trying to remove a member!');
        }
    }

    const handleOpenMembersModal = () => {
        setShowAddMemberModal(true);
    }

    return (

        <>

            <div className='px-4'>

                <div className='d-flex justify-content-between align-items-center mt-4 w-100'>
                    <h5 className="bold mb-0">Business Members</h5>
                    <button className='btn btn-primary rounded medium' onClick={() => handleOpenMembersModal()}>Add user</button>
                </div>

                <div className='table-responsive pt-4'>
                    <table className='table table-hover table-bordered'>
                        <tbody>
                            {businessMembers.map(businessMember => (
                                <tr key={businessMember.id} >
                                    <td className='medium'>
                                        {businessMember?.user?.name}
                                    </td>
                                    <td className='medium'>
                                        {businessMember?.user?.email}
                                    </td>
                                    <td className='medium'>
                                        {businessMember?.business_role?.name}
                                    </td>
                                    <td className='text-center'>
                                        <TrashFill className='text-danger pointer' onClick={() => { handleRemoveBusinessMember(businessMember?.user) }} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <AddMemberModal
                showAddMemberModal={showAddMemberModal}
                setShowAddMemberModal={setShowAddMemberModal}
                businessMembers={businessMembers}
                setBusinessMembers={setBusinessMembers}
            />

        </>



    )
}

export default Team