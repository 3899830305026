import React, { useState } from 'react';
import logo from '../../../modules/common/assets/img/logo.png'
import { register } from '../api/register';
import { useNavigate } from 'react-router-dom';
import googleIcon from '../../../modules/common/assets/img/icons/google.png'
import { Link } from 'react-router-dom';
import { useAuth } from '../../common/contexts/AuthContext';
import { toast } from 'react-toastify';
import Button from '../../common/components/Button';

export default function Register() {

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      await register(name, email, password);
      const response = await login(email, password);
      if (response.success) {
        toast.success('Successfully registered an account!');
        navigate('/my-businesses');
      }
    } catch (error) {
      setError('An error occurred while logging in.');
      console.error(error);
    }

    setLoading(false);

  };


  return (
    <div className='main-auth-page-container'>

      <div className="px-5 py-5 w-100" style={{ maxWidth: 620 }}>

        <div>
          <div className='py-3'>
            <img src={logo} alt="" className='auth-page-logo' />
          </div>

          <div className='py-3'>
            <h3 className='bold'>Sign up</h3>
            <p>Welcome! Please enter your details.</p>
          </div>

        </div>

        {error && <span className='text-danger small'>{error}</span>} { }

        <form onSubmit={handleSubmit}>
          <div className='py-2'>
            <label className='mb-1'>Name</label>
            <input type="text" name='name' value={name} onChange={(e) => setName(e.target.value)} className='form-control py-3' style={{ backgroundColor: '#EBE9F9' }} placeholder='Enter your name' />
          </div>
          <div className='py-2'>
            <label className='mb-1'>Email</label>
            <input type="text" name='email' value={email} onChange={(e) => setEmail(e.target.value)} className='form-control py-3' style={{ backgroundColor: '#EBE9F9' }} placeholder='Enter your email' />
          </div>
          <div className='py-2'>
            <label className='mb-1'>Password</label>
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} name='password' className='form-control py-3' style={{ backgroundColor: '#EBE9F9' }} placeholder='••••••••' />
          </div>

          <div className='d-flex justify-content-between py-3'>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                Remember for 14 days
              </label>
            </div>
          </div>

          <div className='py-2 mt-3'>
            <Button variant="primary" size="lg" type="submit" loading={loading} >
              Sign Up
            </Button>
          </div>
          <div className='py-2'>
            <button type="submit" className='btn btn-basic border hover-lg w-100 py-3 fw-500 d-flex align-items-center justify-content-center'>
              <img src={googleIcon} style={{ height: 20 }} alt="google" />
              <span className='ps-2'>
                Sign in with Google
              </span>
            </button>
          </div>


          <div className='d-flex justify-content-center py-4'>
            <span className='px-1 text-secondary'>Already have an account?</span>
            <Link to="/login" className='px-1 txt-primary fw-500 text-decoration-none'>Log In</Link>
          </div>


        </form>

      </div>

    </div>
  )
}
