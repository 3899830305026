import React, { useState } from 'react';
import Button from '../../common/components/Button';
import { storeEventType } from '../api/event_types';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function NewEventType() {
    const [isSaving, setIsSaving] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [duration, setDuration] = useState(30);

    const navigate = useNavigate();

    const handleSubmit = async() => {

        setIsSaving(true)

        const eventType = {
            "name": name,
            "description": description,
            "duration": duration,
        }

        await storeEventType(eventType);

        setName('')
        setDescription('')
        setDuration(30)

        setIsSaving(false)

        toast.success('Event type successfully created!')

        navigate('/event_types')

    }

    return (
        <>
            <h4 className='mt-3 bold'>Create new event type</h4>

            <div className="card shadow-sm col-md-6 mt-4">
                <div className='mb-4'>
                    <label className='mb-2 ms-1'>Name <span className='text-danger'>*</span></label>
                    <input
                        type="text"
                        className='form-control medium py-3'
                        placeholder='E.g. Introduction call'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>

                <div className='mb-4'>
                    <label className='mb-2 ms-1'>Description</label>
                    <textarea
                        type="text"
                        className='form-control medium py-3'
                        placeholder='E.g. 30 minute call for getting to know my client'
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </div>

                <div className='mb-4'>
                    <label className='mb-2 ms-1'>Duration (mins)</label>
                    <input
                        type="number"
                        className='form-control medium py-3'
                        placeholder='Duration'
                        value={duration}
                        onChange={(e) => setDuration(e.target.value)}
                    />
                </div>

                <div className='d-flex justify-content-end'>
                    <Button size="sm" variant="primary" loading={isSaving} onClick={handleSubmit}>Continue</Button>
                </div>
            </div>
        </>
    );
}

export default NewEventType;
