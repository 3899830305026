import RevenueGroups from './pages/RevenueGroups';
import RevenueGroup from './pages/RevenueGroup';

const revenueRoutes = [
  {
    path: 'revenues/groups',
    element: <RevenueGroups />,
  },
  {
    path: 'revenues/groups/:group_id',
    element: <RevenueGroup />,
  },
];

export default revenueRoutes;