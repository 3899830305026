import Products from './pages/Products';
import Product from './pages/Product';
import ProductModule from './pages/ProductModule';

const productRoutes = [
  {
    path: 'products',
    element: <Products />,
  },
  {
    path: 'products/:product_id',
    element: <Product />,
  },
  {
    path: 'products/:product_id/modules/:module_id',
    element: <ProductModule />,
  },
];

export default productRoutes;