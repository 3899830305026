import React from 'react'
import { setFocusedTask } from '../../common/utils/focus';

function FocusButton({ task }) {

    const handleOpenFocusMode = (event, task) => {
        setFocusedTask(task);
        window.ipcRenderer.send('open-focus-mode', { task: task });
        event.stopPropagation();
    };

    return (
        <button className='btn' onClick={(event) => handleOpenFocusMode(event, task)}>
            <span className="svg-icon svg-icon-primary svg-icon-2x"><svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <defs />
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <rect x="0" y="0" width="24" height="24" />
                    <path d="M19,11 L21,11 C21.5522847,11 22,11.4477153 22,12 C22,12.5522847 21.5522847,13 21,13 L19,13 C18.4477153,13 18,12.5522847 18,12 C18,11.4477153 18.4477153,11 19,11 Z M3,11 L5,11 C5.55228475,11 6,11.4477153 6,12 C6,12.5522847 5.55228475,13 5,13 L3,13 C2.44771525,13 2,12.5522847 2,12 C2,11.4477153 2.44771525,11 3,11 Z M12,2 C12.5522847,2 13,2.44771525 13,3 L13,5 C13,5.55228475 12.5522847,6 12,6 C11.4477153,6 11,5.55228475 11,5 L11,3 C11,2.44771525 11.4477153,2 12,2 Z M12,18 C12.5522847,18 13,18.4477153 13,19 L13,21 C13,21.5522847 12.5522847,22 12,22 C11.4477153,22 11,21.5522847 11,21 L11,19 C11,18.4477153 11.4477153,18 12,18 Z" fill="var(--color-primary)" fillRule="nonzero" opacity="0.3" />
                    <circle fill="var(--color-primary)" cx="12" cy="12" r="3" />
                </g>
            </svg></span>
        </button>
    )
}

export default FocusButton