import React from 'react';
import { Modal } from 'react-bootstrap';
import { deleteItem } from '../api/items';
import { toast } from 'react-toastify';

function DeleteItemModal({ items, setItems, showDeleteItemModal, setShowDeleteItemModal, selectedItem }) {

    const handleCloseModal = () => {
        setShowDeleteItemModal(false);
    }

    const handleDeleteItem = async (event) => {
        event.preventDefault();
        try {
            await deleteItem(selectedItem.id);
            const updatedItems = items.filter(item => item.id !== selectedItem.id);
            setItems(updatedItems);
            toast.success("Successfully removed a item!")
        } catch (error) {
            toast.error(error.message);
        }
        handleCloseModal()
    };

    return (
        <Modal show={showDeleteItemModal}
            onHide={handleCloseModal}
            backdrop="static"
            keyboard={true}
            size='md'
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='bold h5'>Delete item</Modal.Title>
            </Modal.Header>

            <Modal.Body className='pt-4'>


                <p className='color-text opacity-80 medium'>
                    Are you sure you want to delete this item? After you delete this item, this action cannot be undone.
                </p>
                <div className='modal-footer border-0 pb-2'>
                    <button className='btn btn-danger rounded' onClick={(event) => { handleDeleteItem(event) }}>Delete</button>
                </div>
            </Modal.Body>

        </Modal>

    );
}

export default DeleteItemModal;
