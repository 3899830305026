import React, { useState, useEffect, useRef } from 'react';
import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';
import fiverrIcon from '../../../common/assets/img/icons/fiverr.webp'
import styles from './ProductModuleCard.module.css'
import { PencilSquare, ThreeDotsVertical, TrashFill } from 'react-bootstrap-icons';

function ProductModuleCard({ product_id, productModule, openEditProductModuleModal, openDeleteProductModuleModal }) {

    const navigate = useNavigate();
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    return (
        <Card className='pointer h-100' onClick={() => { navigate('/products/'+ product_id + '/modules/' + productModule.id) }}>

            <div className='d-flex justify-content-between'>
                <h5 className='fw-500'>{productModule.name}</h5>

                <div className='btn px-2 py-1' onClick={(e) => { e.stopPropagation(); toggleDropdown() }} ref={dropdownRef}>
                    <ThreeDotsVertical className='pointer' />
                    {dropdownVisible && (
                        <div className='dropdown-menu border-0 shadow-sm show'>
                            <li className='dropdown-item' onClick={(e) => openEditProductModuleModal(productModule)}>
                                <PencilSquare className='me-2' /> Edit
                            </li>
                            <li className='dropdown-item text-danger' onClick={(e) => openDeleteProductModuleModal(productModule)}>
                                <TrashFill className='me-2' /> Delete
                            </li>
                        </div>
                    )}
                </div>

            </div>

            <p>{productModule.description}</p>


        </Card>
    )
}

export default ProductModuleCard