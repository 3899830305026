import React from 'react';
import { Modal } from 'react-bootstrap';
import { deleteItemGroup } from '../api/items';
import { toast } from 'react-toastify';

function DeleteItemGroupModal({ itemGroups, setItemGroups, showDeleteItemGroupModal, setShowDeleteItemGroupModal, selectedItemGroup }) {

    const handleCloseModal = () => {
        setShowDeleteItemGroupModal(false);
    }

    const handleDeleteItemGroup = async (event) => {
        event.preventDefault();
        try {
            const updatedItemGroups = itemGroups.filter(itemGroup => itemGroup.id !== selectedItemGroup.id);
            setItemGroups(updatedItemGroups);
            toast.success("Successfully removed item group!")
            await deleteItemGroup(selectedItemGroup.id);
            handleCloseModal()
        } catch (error) {
            toast.error(error.message);
        }
    };

    return (
        <Modal show={showDeleteItemGroupModal}
            onHide={handleCloseModal}
            backdrop="static"
            keyboard={true}
            size='md'
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='bold h5'>Delete item group</Modal.Title>
            </Modal.Header>

            <Modal.Body className='pt-4'>

                <p className='color-text opacity-80 medium'>
                    Are you sure you want to delete this item group? After you delete this item group, this action cannot be undone.
                </p>
                <div className='modal-footer border-0 pb-2'>
                    <button className='btn btn-danger rounded' onClick={(event) => { handleDeleteItemGroup(event) }}>Delete</button>
                </div>
            </Modal.Body>

        </Modal>

    );
}

export default DeleteItemGroupModal;
