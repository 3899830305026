import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getRevenueGroup, getGroupRevenues } from '../api/revenues';
import AddRevenueModal from '../components/AddRevenueModal';
import GroupHeader from '../components/groupHeader/GroupHeader';
import EditRevenueModal from '../components/EditRevenueModal';
import DeleteRevenueModal from '../components/DeleteRevenueModal';
import { PencilSquare, Plus, TrashFill } from 'react-bootstrap-icons';
import { formatDate } from '../../common/utils/format_utils';

function RevenueGroup() {

  const { group_id } = useParams();
  const [revenueGroup, setRevenueGroup] = useState([]);
  const [revenues, setRevenues] = useState([]);
  const [showAddRevenueModal, setShowAddRevenueModal] = useState(false);
  const [showEditRevenueModal, setShowEditRevenueModal] = useState(false);
  const [showDeleteRevenueModal, setShowDeleteRevenueModal] = useState(false);
  const [selectedRevenue, setSelectedRevenue] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRevenueGroup = async () => {
      try {
        const fetchedRevenueGroup = await getRevenueGroup(group_id);
        setRevenueGroup(fetchedRevenueGroup);
      } catch (error) {
        console.error('Error fetching :', error);
      }
    };

    const fetchRevenues = async () => {
      try {
        const fetchedRevenues = await getGroupRevenues(group_id);
        setRevenues(fetchedRevenues);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching :', error);
      }
    };

    fetchRevenueGroup();
    fetchRevenues();
  }, []);

  const openAddRevenueModal = () => {
    setShowAddRevenueModal(true);
  };

  const openEditRevenueModal = (revenue) => {
    setShowEditRevenueModal(true);
    setSelectedRevenue(revenue);
  }

  const openDeleteRevenueModal = (revenueGroup) => {
    setShowDeleteRevenueModal(true);
    setSelectedRevenue(revenueGroup);
  }

  if (loading) {
    return (
      <div className='d-flex justify-content-center align-items-center' style={{ height: '70vh' }}>
        <div className='spinner-border txt-primary' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div>

      <GroupHeader revenueGroup={revenueGroup} />

      <button onClick={() => { openAddRevenueModal() }} className='btn btn-dark right-bottom-button rounded px-3 py-2 shadow-lg'>
        <Plus className='h4 m-0' />
      </button>

      <div className='container-fluid px-4'>
        <div className='m-auto d-block w-100' style={{ maxWidth: 1500, overflowX: 'auto' }}>
          <div className='table-responsive pt-3'>
            <table className='table table-bordered table-hover'>
              <thead>
                <tr>
                  <th className='medium color-text-lighter w-30'>Name</th>
                  <th className='medium color-text-lighter w-30'>Description</th>
                  <th className='medium color-text-lighter w-10'>Value ($)</th>
                  <th className='medium color-text-lighter w-20'>Date</th>
                  <th className='text-center medium color-text-lighter w-10'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {revenues.map(revenue => (
                  <tr key={revenue.id}>
                    <td className='medium fw-500 w-30'>{revenue.name}</td>
                    <td className='medium color-text-lighter w-30'>{revenue.description}</td>
                    <td className='medium color-text-lighter w-10'>{revenue.value}</td>
                    <td className='medium color-text-lighter w-20'>{formatDate(revenue.created_at)}</td>
                    <td className='medium color-text-lighter w-10'>
                      <div className="h-100 d-flex align-items-center justify-content-center">
                        <button className='btn hover-lg' style={{ zIndex: 999 }} onClick={(event) => { event.stopPropagation(); openEditRevenueModal(revenue) }}>
                          <PencilSquare />
                        </button>
                        <button className='btn text-danger hover-lg' style={{ zIndex: 999 }} onClick={(event) => { event.stopPropagation(); openDeleteRevenueModal(revenue) }}>
                          <TrashFill />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <AddRevenueModal
        revenues={revenues}
        setRevenues={setRevenues}
        showAddRevenueModal={showAddRevenueModal}
        setShowAddRevenueModal={setShowAddRevenueModal}
        group_id={group_id}
      />

      <EditRevenueModal
        revenues={revenues}
        setRevenues={setRevenues}
        selectedRevenue={selectedRevenue}
        setSelectedRevenue={setSelectedRevenue}
        showEditRevenueModal={showEditRevenueModal}
        setShowEditRevenueModal={setShowEditRevenueModal}
      />

      <DeleteRevenueModal
        revenues={revenues}
        setRevenues={setRevenues}
        showDeleteRevenueModal={showDeleteRevenueModal}
        setShowDeleteRevenueModal={setShowDeleteRevenueModal}
        selectedRevenue={selectedRevenue}
      />

    </div>
  )
}

export default RevenueGroup