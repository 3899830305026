import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import { updateClientGroup } from '../api/clients';
import { toast } from 'react-toastify';

function EditClientGroupModal({ showEditClientGroupModal, setShowEditClientGroupModal, clientGroups, setClientGroups, selectedClientGroup, setSelectedClientGroup }) {

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        setName(selectedClientGroup.name);
        setDescription(selectedClientGroup.description);
    }, [selectedClientGroup]);

    const handleSubmit = async () => {
        const clientGroupObject = {
            "id": selectedClientGroup.id,
            "name": name,
            "description": description,
        };

        try {
            
            const updatedClientGroups = clientGroups.map(clientGroup =>
                clientGroup.id === selectedClientGroup.id ? clientGroupObject : clientGroup
            );
            setClientGroups(updatedClientGroups);

            setShowEditClientGroupModal(false);

            setName('');
            setDescription('');

            toast.success("Successfully edited a client group!");

            await updateClientGroup(clientGroupObject);

        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleCloseModal = () => {
        setShowEditClientGroupModal(false);
    };

    return (
        <Modal
            show={showEditClientGroupModal} onHide={handleCloseModal}
            backdrop="static" keyboard={false} size='lg'
            aria-labelledby="contained-modal-name-vcenter"
            centered
        >
            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='bold h5'>Edit client group</Modal.Title>
            </Modal.Header>
            <Modal.Body className='py-4'>
                <label className='pb-2'>Name</label>
                <input type="text" className='form-control medium bg-gray-light py-2' placeholder='Client group name' value={name} autoComplete="new-password" onChange={(e) => setName(e.target.value)} />

                <label className='mt-4 mb-2'>Description</label>
                <textarea type="text" className='form-control bg-gray-light py-2 medium' placeholder='Description' value={description} autoComplete="new-password" onChange={(e) => setDescription(e.target.value)} style={{ height: 130 }} />

                <div className='pt-4 d-flex justify-content-end'>
                    <button className='btn btn-primary fw-500 medium' onClick={handleSubmit}>Update</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default EditClientGroupModal