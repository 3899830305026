import React, { useState, useEffect } from 'react';
import { getWorkerGroups } from '../api/worker';
import AddWorkerGroupModal from '../components/AddWorkerGroupModal';
import WorkerGroupCard from '../components/workerGroupCard/WorkerGroupCard';
import EditWorkerGroupModal from '../components/EditWorkerGroupModal';
import DeleteWorkerGroupModal from '../components/DeleteWorkerGroupModal';
import { Plus } from 'react-bootstrap-icons';

function Workers() {

    const [workerGroups, setWorkerGroups] = useState([]);
    const [showAddWorkerGroupModal, setShowAddWorkerGroupModal] = useState(false);
    const [selectedWorkerGroup, setSelectedWorkerGroup] = useState([]);
    const [showEditWorkerGroupModal, setShowEditWorkerGroupModal] = useState(false);
    const [showDeleteWorkerGroupModal, setShowDeleteWorkerGroupModal] = useState(false);

    useEffect(() => {
        const fetchWorkerGroups = async () => {
            try {
                const fetchedWorkerGroups = await getWorkerGroups();
                setWorkerGroups(fetchedWorkerGroups);
            } catch (error) {
                console.error('Error fetching :', error);
            }
        };

        fetchWorkerGroups();
    }, []);

    const openAddWorkerGroupModal = () => {
        setShowAddWorkerGroupModal(true);
    };

    const openEditWorkerGroupModal = (workerGroup) => {
        setShowEditWorkerGroupModal(true);
        setSelectedWorkerGroup(workerGroup);
    }

    const openDeleteWorkerGroupModal = (workerGroup) => {
        setShowDeleteWorkerGroupModal(true);
        setSelectedWorkerGroup(workerGroup);
    }

    return (

        <div>

            <button onClick={() => { openAddWorkerGroupModal() }} className='btn btn-dark right-bottom-button rounded px-3 py-2 shadow-lg'>
                <Plus className='h4 m-0' />
            </button>

            <div className='container-fluid' style={{ paddingTop: 77 }}>
                <div className='row px-3'>

                    {workerGroups.map(workerGroup => (
                        <div key={workerGroup.id} className='col-md-4 mb-3'>
                            <WorkerGroupCard workerGroup={workerGroup} openEditWorkerGroupModal={openEditWorkerGroupModal} openDeleteWorkerGroupModal={openDeleteWorkerGroupModal} />
                        </div>
                    ))}

                </div>
            </div>


            <AddWorkerGroupModal
                workerGroups={workerGroups}
                setWorkerGroups={setWorkerGroups}
                showAddWorkerGroupModal={showAddWorkerGroupModal}
                setShowAddWorkerGroupModal={setShowAddWorkerGroupModal}
            />

            <EditWorkerGroupModal
                workerGroups={workerGroups}
                setWorkerGroups={setWorkerGroups}
                selectedWorkerGroup={selectedWorkerGroup}
                setSelectedWorkerGroup={setSelectedWorkerGroup}
                showEditWorkerGroupModal={showEditWorkerGroupModal}
                setShowEditWorkerGroupModal={setShowEditWorkerGroupModal}
            />

            <DeleteWorkerGroupModal
                workerGroups={workerGroups}
                setWorkerGroups={setWorkerGroups}
                showDeleteWorkerGroupModal={showDeleteWorkerGroupModal}
                setShowDeleteWorkerGroupModal={setShowDeleteWorkerGroupModal}
                selectedWorkerGroup={selectedWorkerGroup}
            />

        </div >
    )
}

export default Workers;
