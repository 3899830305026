import React from 'react'
import styles from './GroupHeader.module.css'

function GroupHeader({ revenueGroup }) {
    return (
        <div className={styles.GroupHeader}>

            <div>
                <h3 className='bold'>{revenueGroup?.name}</h3>
                <p>{revenueGroup?.description}</p>
            </div>

            <div className='d-flex'>
                <div className='ms-3 card shadow-sm d-flex align-items-center justify-content-center'>
                    <span className='text-muted medium pb-2'>Last month</span>
                    <h5 className='txt-primary mb-0'>{Number(revenueGroup?.total_value_last_month)?.toFixed(2)} $</h5>
                </div>
                <div className='ms-3 card shadow-sm d-flex align-items-center justify-content-center'>
                    <span className='text-muted medium pb-2'>Last 7 days</span>
                    <h5 className='txt-primary mb-0'>{Number(revenueGroup?.total_value_last_7_days)?.toFixed(2)} $</h5>
                </div>
                <div className='ms-3 card shadow-sm d-flex align-items-center justify-content-center'>
                    <span className='text-muted medium pb-2'>Today</span>
                    <h5 className='txt-primary mb-0'>{Number(revenueGroup?.total_value_today)?.toFixed(2)} $</h5>
                </div>
            </div>

        </div>
    )
}

export default GroupHeader